import React, {useState, useEffect} from "react"

function CustomHoverTooltip(props) {
    const visible = props.visible
    const [popup, setPopup] = useState({x: 100, y: 100})
    useEffect(() => {
        const movePopup = (e) => {
            setPopup({x: e.clientX + 16, y: e.clientY +16});
        };
        window.addEventListener("mousemove", movePopup);

        return () => {
            window.removeEventListener("mousemove", movePopup);
        };
    }, []);
    return (
        <div
            className="popup"
            style={{
                opacity:`${visible?1:0}`,
                transform: `translate(${popup.x}px, ${popup.y}px)`
            }}>
            {props.children}
        </div>
    )
}

export default CustomHoverTooltip;

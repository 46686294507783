import {base} from "../../../url";
import axios from "axios";


async function getPaper(filter, page, limit) {
    console.log(filter,page,limit)
    const requestOptions = {
        method: 'GET',
        credentials: "include",
        redirect: 'follow',
    };
    if (filter==="all"){
        return fetch(`${base}/news/?page=${encodeURIComponent(page)}&limit=${encodeURIComponent(limit)}`, requestOptions).then((reponse) => reponse.json());
    }
    return fetch(`${base}/news/?page=${encodeURIComponent(page)}&limit=${encodeURIComponent(limit)}&category=${filter}`, requestOptions).then((reponse) => reponse.json());

}


export {getPaper};


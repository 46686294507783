import React, {useEffect, useRef, useCallback} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Draggable} from 'gsap/Draggable';
import CustomCursor from "../Duck/DuckDesignSystem/Source/Support/CustomCursor";
import {useGSAP} from "@gsap/react";



gsap.registerPlugin(ScrollTrigger, Draggable);

const GsapCarousel = ({images}) => {
    const cardsRef = useRef(null);
    const galleryRef = useRef(null);
    const wrapperRef = useRef(null);
    const dragProxyRef = useRef(null);
    const playheadRef = useRef({offset: 0});
    const buildSeamlessLoop = (items, spacing, animateFunc) => {
        let rawSequence = gsap.timeline({paused: true});
        let seamlessLoop = gsap.timeline({

            paused: true,
            repeat: -1,
            onRepeat() {
                this._time === this._dur && (this._tTime += this._dur - 0.01);
            },
            onReverseComplete() {
                this.totalTime(this.rawTime() + this.duration() * 100);
            }
        });

        const cycleDuration = spacing * items.length;
        let dur: number = 0;

        items.concat(items).concat(items).forEach((item, i) => {
            const anim = animateFunc(items[i % items.length]);
            rawSequence.add(anim, i * spacing);
            if (!dur) dur = anim.duration();
        });

        seamlessLoop.fromTo(rawSequence, {
            time: cycleDuration + dur / 2
        }, {
            time: "+=" + cycleDuration,
            duration: cycleDuration*1.8,
            ease: "none",

        });
        return seamlessLoop;
    };
    useGSAP(() => {
        if (!cardsRef.current || !galleryRef.current || !dragProxyRef.current) return;

        const cards = Array.from(cardsRef.current.children);
        const spacing = 0.135;
        const snapTime = gsap.utils.snap(spacing);
        const playhead = playheadRef.current;

        const animateFunc = (element) => {
            const tl = gsap.timeline();
            tl.fromTo(element, {scale: 1, opacity: 1}, {
                scale: 1,
                opacity: 1,
                zIndex: 100,
                duration: 0.5,
                yoyo: true,
                repeat: 1,
                ease: "power1.in",
                immediateRender: true
            })
                .fromTo(element, {xPercent: 400}, {
                    xPercent: -400,
                    duration: 1,
                    ease: "none",
                    immediateRender: false,
                    scrollTrigger: {
                        trigger:wrapperRef.current,
                    }
                }, 0);
            return tl;
        };

        const seamlessLoop = buildSeamlessLoop(cards, spacing, animateFunc);
        const wrapTime = gsap.utils.wrap(0, seamlessLoop.duration());

        const scrub = gsap.to(playhead, {

            offset: 0,
            onUpdate() {
                seamlessLoop.time(wrapTime(playhead.offset));
            },
            duration: 3.5,
            ease: "power2",
            paused: true,
        });

        let iteration = 0;

        const trigger = ScrollTrigger.create({
            start: 0,
            onUpdate(self) {
                const scroll = self.scroll();
                if (scroll > self.end - 1) {
                    wrap(1, 2);
                } else if (scroll < 1 && self.direction < 0) {
                    wrap(-1, self.end - 2);
                } else {
                    scrub.vars.offset = (iteration + self.progress) * seamlessLoop.duration();
                    scrub.invalidate().restart();
                }
            },
            end: "+=3000",
            markers:  {startColor: "transparent", endColor: "transparent", fontSize: "1px", fontWeight: "bold", indent: 20},
            scroller: wrapperRef.current,
            trigger: wrapperRef.current,
            pin:galleryRef.current
        });

        const progressToScroll = (progress) => gsap.utils.clamp(1, trigger.end - 1, gsap.utils.wrap(0, 1, progress) * trigger.end);

        const wrap = (iterationDelta, scrollTo) => {
            iteration += iterationDelta;
            trigger.scroll(scrollTo);
            trigger.update();
        };

        const scrollToOffset = (offset) => {
            const snappedTime = snapTime(offset);
            const progress = (snappedTime - seamlessLoop.duration() * iteration) / seamlessLoop.duration();
            const scroll = progressToScroll(progress);
            if (progress >= 1 || progress < 0) {
                return wrap(Math.floor(progress), scroll);
            }
            trigger.scroll(scroll);
        };

        const scrollHandler = () => scrollToOffset(scrub.vars.offset);
        ScrollTrigger.addEventListener("scrollEnd", scrollHandler);


        Draggable.create(dragProxyRef.current, {
            type: "x",
            trigger: cardsRef.current,
            onPress() {
                this.startOffset = scrub.vars.offset;
            },
            onDrag() {
                scrub.vars.offset = this.startOffset + (this.startX - this.x) * 0.001;
                scrub.invalidate().restart();
            },
            onDragEnd() {
                scrollToOffset(scrub.vars.offset);
            }
        });

        // Cleanup function to remove event listeners
        return () => {
            ScrollTrigger.removeEventListener("scrollEnd", scrollHandler);
            // Remove button event listeners if necessary
        };

    });
    return (
        <div className={"scroll-wrapper"}  ref={wrapperRef}>
            {/*<CustomCursor carouselRef={wrapperRef} />*/}
            <div className="gallery" ref={galleryRef}>
                <ul className="cards" ref={cardsRef}>
                    {images.map((image, index) => (
                        <li key={index}>
                            <img src={image.url} alt=""/>
                        </li>
                    ))}
                    {/*{images.map((image, index) => (*/}
                    {/*    <li key={index}>*/}
                    {/*        <img src={image.url} alt=""/>*/}
                    {/*    </li>*/}
                    {/*))}*/}
                </ul>

                <div className="drag-proxy" ref={dragProxyRef}></div>
            </div>
        </div>


    );
};
export default GsapCarousel;

import React, {useEffect, useState} from "react";
import DuckSelect from "../Input/DuckSelect";
import {Layout42} from "../../Support/Layout";

const LayoutSection = () => {
    const [value, setValue] = useState("x")
    const sizes = [
        {size: "x"},
        {size: "s"},
        {size: "m"},
        {size: "l"},
    ]
    const [columnString, setColumnString] = useState([]);
    const [col, setCol] = useState(4);
    const gapString = <div className={`columns gap-${value}`}>
        <div className={"col"}>Element</div>
        <div className={"col"}>Element</div>
    </div>

    useEffect(() => {
        const generateColumns = () => {
            const columns = [];
            for (let i = 0; i < col; i++) {
                columns.push(<div key={i} data={"xxx"} className="col">Element</div>);
            }
            return <div className={"columns"}>{columns}</div>;
        };

        setColumnString(generateColumns());
    }, [col]);

    return <div className="mb-xxl">
        <div className="section" id="gridSection">
            <div className="title-xxl my-l">Grid</div>
            <h4 className="h-m">Columns powered by Flexbox</h4>
            <div className="text-l">A simple way to build <strong>responsive columns </strong></div>

            <div className="text-l mb-m">
                Use our powerful mobile-first flexbox grid to build layouts of all shapes and sizes thanks to a twelve
                column system, six default responsive tiers, Sass variables and mixins, and dozens of predefined
                classes.
            </div>
            <div className="section">
                <Layout42 size={"s"} code={columnString}>
                    <div className="p-xl columns vertical gap-m">
                        <div className=" col columns gap-m ">

                            {(() => {
                                const options = [];
                                for (let i = 0; i < col; i++) {
                                    options.push(<div key={i}
                                                      className="col border dash   bg-info  hi-text-center rounded-s">
                                        <div className="p-l">
                                            <code>col</code>
                                        </div>
                                    </div>)
                                }
                                return options
                            })()}
                        </div>
                    </div>
                    <div className="field has-label-inline">
                        <div className="label">Colums</div>
                        <DuckSelect data={(() => {
                            const options = [];
                            for (let i = 2; i <= 6; i++) {
                                options.push({"col": i})
                            }
                            return options
                        })()} placeholder={"select size"}
                                    child={({item, index,}) => (
                                        <div title={item.col} key={index} className={"p-s"} onClick={() => {
                                            setCol(item.col)
                                        }}>
                                            {item.col}
                                        </div>
                                    )}/>
                    </div>
                </Layout42>
            </div>
            <h4 className="h-m">Columns Spacing</h4>
            <div className="text-l">A simple way to build <strong>responsive columns </strong></div>
            <div className="section   ">
                <Layout42 size={"s"} code={gapString}>
                    <div className="columns vertical gap-m   ">
                        <div className="jt-between is-flex ">
                            <div className="code">
                                {`gap-${value}`} <br/>
                            </div>
                        </div>
                        <div className={`col columns gap-${value}`}>

                            {(() => {
                                const options = [];
                                for (let i = 0; i < 4; i++) {
                                    options.push(<div key={i}
                                                      className="col   py-xxl   border dash  hi-text-center rounded-s">
                                        <code>col</code>
                                    </div>)
                                }
                                return options
                            })()}
                        </div>
                    </div>
                    <div className="columns vertical">
                        <div className="field has-label-inline">
                            <div className="label">Size</div>
                            <DuckSelect data={sizes} placeholder={"select size"}
                                        child={({item, index,}) => (
                                            <div title={item.size} key={index} className={"p-s"} onClick={() => {
                                                setValue(item.size)
                                            }}>
                                                {item.size}
                                            </div>
                                        )}/>
                        </div>
                    </div>
                </Layout42>

            </div>


        </div>
        <div className="section ">
            <div className="title-xxl  my-l">Spacing</div>
            <Margin/>
            <Padding/>
        </div>


    </div>
}
export default LayoutSection

const Margin = () => {
    const [size, setSize] = useState("xs")
    const [value, setValue] = useState("")
    const mString = <div className={`m${value}-${size}`}>Element</div>
    const Variables = [
        {size: "No Margin", value: ""},
        {size: "xs", value: "xs"},
        {size: "s", value: "s"},
        {size: "m", value: "m"},
        {size: "l", value: "l"},
        {size: "xl", value: "xl"},
        {size: "xxl", value: "xxl"}]
    const Status = [
        {name: "", value: "all"},
        {name: "x", value: "left and right"},
        {name: "y", value: "top and bottom"},
        {name: "l", value: "left"},
        {name: "r", value: "right"},
        {name: "t", value: "top"},
        {name: "b", value: "bottom"}]
    return <div className="section  " id="spaceSection">
        <div className="section">
            <h4 className="h-m">Margin</h4>
            <div className="text-l">A simple way to build <strong>M-value-size</strong></div>

            <div className="text-m">
                Xây dựng 1 giao diện cột với Duck rất dễ dàng,thêm thẻ <code>columns</code>
                , thêm các thẻ <span className={"code"}>col</span> như các fw khác
                <br/> Mặc định, các cột sẽ cách nhau <strong>0.55rem</strong>
            </div>
            <div className="section">
                <Layout42 size={"m"} code={mString}>
                    <div className={"columns vertical gap-m"}>
                        <div className="   rounded-xs" style={{backgroundColor: "#ffecd8"}}>
                                <div
                                    className={`is-fill bg-white border text-center  rounded-xs p-s text-xs `}
                                    style={{width: "auto", height: "auto"}}>
                                    Element
                                </div>
                                <div
                                    className={`size-y-l-x2  bg-primary-light  text-center border rounded-xs p-s text-xs ${value === "" ? `m-${size}` : `m${value}-${size}`}`}>

                                </div>
                                <div
                                    className={`is-fill text-center border  bg-white rounded-xs p-s text-xs `}
                                    style={{width: "auto", height: "auto"}}>
                                    Element
                                </div>


                        </div>
                    </div>
                    <div className="">
                        <div className="field has-label-inline">
                            <div className="label">Size</div>
                            <DuckSelect data={Variables} placeholder={"select size"}
                                        child={({item, index,}) => (
                                            <div key={index} title={item.size} className={"p-s"} onClick={() => {
                                                setSize(item.value)
                                            }}>
                                                {item.size}
                                            </div>
                                        )}/>
                        </div>
                        <div className="field has-label-inline">
                            <div className="label">Direction</div>
                            <DuckSelect data={Status} placeholder={"select size"}
                                        child={({item, index,}) => (
                                            <div title={item.value} key={index} className={"p-s"} onClick={() => {
                                                setValue(item.name)
                                            }}>
                                                {item.value}
                                            </div>
                                        )}/>
                        </div>
                    </div>
                </Layout42>
            </div>

        </div>
    </div>
}
const Padding = () => {
    const [size, setSize] = useState("xs")
    const [value, setValue] = useState("")
    const pString = <div className={`p${value}-${size}`}>
        Element
    </div>
    const Variables = [
        {size: "No Padding", value: ""},
        {size: "xs", value: "xs"},
        {size: "s", value: "s"},
        {size: "m", value: "m"},
        {size: "l", value: "l"},
        {size: "xl", value: "xl"},
        {size: "xxl", value: "xxl"}]
    const Status = [
        {name: "", value: "all"},
        {name: "x", value: "left and right"},
        {name: "y", value: "top and bottom"},
        {name: "l", value: "left"},
        {name: "r", value: "right"},
        {name: "t", value: "top"},
        {name: "b", value: "bottom"}]
    return <div className="section" id="spaceSection">
        <div className="section">
            <h4 className="h-m">Padding</h4>
            <div className="text-l">A simple way to build <strong>P-value-size </strong></div>
            <div className="section">
                <Layout42 size={"m"} code={pString}>
                    <div className="col-5 border dash bg-primary-light rounded-xs">
                        <div className={`size-y-l-x2   ${value === "" ? `p-${size}` : `p${value}-${size}`}`}>
                            <div
                                className={` bg-white  is-fill-y text-center border rounded-xs p-l text-xs `}>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="field has-label-inline">
                            <div className="label">Size</div>
                            <DuckSelect data={Variables} placeholder={"select size"}
                                        child={({item, index,}) => (
                                            <div key={index} title={item.size} className={"p-s"} onClick={() => {
                                                setSize(item.value)
                                            }}>
                                                {item.size}
                                            </div>
                                        )}/>
                        </div>
                        <div className="field has-label-inline">
                            <div className="label">Direction</div>
                            <DuckSelect data={Status} placeholder={"select size"}
                                        child={({item, index,}) => (
                                            <div key={index} title={item.value} className={"p-s"} onClick={() => {
                                                setValue(item.name)
                                            }}>
                                                {item.value}
                                            </div>
                                        )}/>
                        </div>
                    </div>
                </Layout42>
            </div>


        </div>
    </div>
}


import React, {useEffect} from 'react';
import {SlideElement} from "../Duck/DuckDesignSystem/animate/AnimateElement";
import {Link} from "react-router-dom";
import Card from "../Duck/DuckReact/Card";

function NotFound(props) {
    useEffect(()=>{
        document.title = "Không tìm thấy trang";
    })
    return (
        <div className=" hero has-background is-flex jt-center align-center is-three-quarter   ">
            <div className=" container-xl 	">
                <div className="columns l-vertical border">
                    <div className="  p-l align-center jt-center text-mute">
                        <div className="text-m ">
                            Back to <Link className="text-primary" to="/">Home</Link>
                        </div>
                        <Card width={"40vh"}></Card>


                    </div>
                    <div className="p-xxl is-block  is-grow">

                        <div className="is-grow is-flex vertical">
                            <SlideElement>
                                <div className=" hi-5xl text-bold mb-xl text-black ">Oops!</div>
                                <div className=" text-black">
                                    <div className=" text-xs  font-text ">This element does not exist</div>
                                    <div className=" text-xs      font-text "> or has not been updated yet.</div>
                                </div>
                            </SlideElement>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
}

export default NotFound;
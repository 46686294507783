import React from "react";
import {SlideElement} from '../../Duck/DuckDesignSystem/animate/AnimateElement'
import Work from "../../menu-item/Work";
import CardsUiCpn from "./CardsUICpn";
import {Link} from "react-router-dom";
import {SelectedProject} from "../Item";
import SelectedElement from "../Item/SelectedElement";
import {ProductCard} from "../../menu-item/Product/DesignElement";
import {Icon} from "../../Duck/DuckDesignSystem/Source/DuckIcon";
import Kimnhan, {Fogo, GlobalSafe, HineStudio, More, VuongSoft} from "./partner/kimnhan";

const CV = () => {
    const currentYear = new Date().getFullYear();
    return <div id="letter">

        <div className="container-xl is-relative ">
            <div id={"triggerElement"}
                 className={`font-text is-sticky mt-xxl `}
                 style={{top: "20vh"}}>
                <SlideElement>
                    <div className="hi-4xl mb-xl text-bold text-italic">
                        I'M Hiep,
                    </div>
                </SlideElement>
                <div className="columns layout-1-1 l-vertical  gap-l ">
                    <div className="col">
                        <div className="text-m">
                            <SlideElement>
                                I am not {currentYear - 2000}, I’m just 21 with {currentYear - 2021} years of Product
                                Design expertise. I make
                                websites and mobile apps that wow you in Ecommerce, Lifestyle, Art and Social
                                Network domains.
                            </SlideElement>
                        </div>
                    </div>
                    <div className="col"></div>

                </div>
                <div className="columns gap-l layout-2-1 s-vertical Main section is-relative is-overflow-y"
                >
                    <SlideElement className="col">
                        <div className=" hi-xl text-italic  text-justify" style={{lineHeight: "150%"}}>
                            As someone with a <span className="text-underline ">  passion for design </span> and a
                            creative
                            mindset, I
                            am able to utilize a variety of 2D
                            and 3D design tools to bring <span className="text-underline ">  ideas to life </span> .
                            These tools
                            not
                            only allow me to visualize my designs
                            in a <span className="text-underline ">  dynamic </span> and <span
                            className="text-underline ">  comprehensive </span> way, but also to experiment with
                            different shapes,
                            colors, and
                            textures to create truly unique and diverse products.
                            <br/>
                            From <span className="text-underline "> sketches </span> and <span
                            className="text-underline ">drafts </span>to fully realized
                            prototypes, I take pride in my ability to use design software to bring my ideas to fruition.
                            Whether
                            I am working on a personal project or collaborating with a team, I am <span
                            className="text-underline ">committed </span> to using my skills
                            and expertise to create products that are both aesthetically pleasing and practical.
                        </div>
                    </SlideElement>
                    {/*<div className="col  is-relative  "   >*/}
                    {/*    <div className="columns is-sticky" style={{top:"12vh"}}>*/}
                    {/*        <img className=" rounded-l" src={whoiam} alt=""/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
                <div className="section is-relative">
                    <div className="is-sticky" style={{top: "5vh"}}>
                        <div className="columns align-center ">
                            <div className="col mt-xxl section" id={"workflow"}>
                                <div className="text-l ">
                                    <SlideElement>
                                        <span className="text-s text-bold"># </span>
                                        <span className="text-l">Simple </span>
                                    </SlideElement>
                                </div>
                                <div
                                    className="col is-block hi-l text-bold text-sliver is-relative"
                                    style={{zIndex: 0}}
                                >
                                    <SlideElement>
                                        <div className="text-black">
                                            Workflow
                                        </div>
                                    </SlideElement>

                                </div>

                            </div>

                        </div>
                        <CardsUiCpn></CardsUiCpn>
                    </div>


                </div>

                <div className="section">
                    <div className="col mt-xxl section">
                        <div className="text-l ">
                            <SlideElement>
                                <span className="text-s text-bold"># </span>
                                <span className="text-l">   Work </span>
                            </SlideElement>
                        </div>
                        <div
                            className="col is-block hi-l text-bold text-sliver is-relative"
                            style={{zIndex: 0}}
                        >
                            <SlideElement>
                                <div className="text-black">
                                    Experience
                                </div>
                            </SlideElement>

                        </div>

                    </div>

                    <SlideElement direction>
                        <Work></Work>
                    </SlideElement>
                    <div className="columns layout-1-1 s-vertical">
                        <div className="col text-xs text-darkgray pt-l">
                            Shift + scroll timeline schedule to view all elements.
                            <br/>Hover an element to see description.
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className="section">
                    <div className="col mt-xxl section">
                        <div className="text-l ">
                            <SlideElement>
                                <span className="text-s text-bold"># </span>
                                <span className="text-l">   Selected </span>
                            </SlideElement>
                        </div>
                        <div
                            className="col is-block hi-l text-bold text-sliver is-relative"
                            style={{zIndex: 0}}
                        >
                            <SlideElement>
                                <div className="text-black">
                                    Personal Project
                                </div>
                            </SlideElement>

                        </div>

                    </div>

                    <SlideElement direction>
                        <div className="columns l-vertical layout-3-2-2">
                            <ProductCard product={SelectedProject.products[0]}></ProductCard>
                            <ProductCard product={SelectedProject.products[1]}></ProductCard>
                            <ProductCard product={SelectedProject.products[2]}></ProductCard>

                        </div>

                    </SlideElement>

                    <div className="columns layout-1-1 s-vertical align-center">
                        <div className="col text-xs text-darkgray pt-l">
                            Last Update
                            <br/>Dec, 2023
                        </div>
                        <div className="is-flex jt-end col ">
                            <Link to="/work" className="btn btn-outline">Explore All Work
                                <div className="icon icon-arrow-right"></div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="col mt-xxl section">
                        <div className="text-l ">
                            <SlideElement>
                                <span className="text-s text-bold"># </span>
                                <span className="text-l">   Selected </span>
                            </SlideElement>
                        </div>
                        <div
                            className="col is-block hi-l text-bold text-sliver is-relative"
                            style={{zIndex: 0}}
                        >
                            <SlideElement>
                                <div className="text-black">
                                    Element
                                </div>
                            </SlideElement>

                        </div>

                    </div>

                    <SlideElement direction>
                        <SelectedElement></SelectedElement>
                    </SlideElement>

                    <div className="columns layout-1-1 s-vertical">

                        <div className="col text-xs text-darkgray pt-l">
                            Read a story power by
                            <br/>Element
                        </div>
                        <div className="is-flex jt-end col align-end ">
                            <Link to="/elements" className="btn btn-outline">Read a story
                                <div className="icon icon-arrow-right"></div>
                            </Link>
                        </div>

                    </div>
                </div>
                <div className="section">
                    <div className="col mt-xxl section">
                        <div className="text-l ">
                            <SlideElement>
                                <span className="text-s text-bold"># </span>
                                <span className="text-l">   Trusted by </span>
                            </SlideElement>
                        </div>
                        <div
                            className="col is-block hi-l text-bold text-sliver is-relative"
                            style={{zIndex: 0}}
                        >
                            <SlideElement>
                                <div className="text-black">
                                    Partner
                                </div>
                            </SlideElement>

                        </div>

                    </div>

                    <div className=" columns vertical  is-block   ">
                        <SlideElement>
                            <div className="grid-3 grid-l-3 grid-xs-1 rounded-m wrapper  ">
                                <div className="col is-center vertical py-xxl"><Kimnhan/>
                                </div>
                                <div className="col is-center vertical py-xxl"><Fogo/>

                                </div>
                                <div className="col is-center vertical py-xxl"><HineStudio/>

                                </div>
                                <div className="col is-center vertical py-xxl"><GlobalSafe/>

                                </div>
                                <div className="col is-center vertical py-xxl"><VuongSoft/>

                                </div>
                                <div className="col is-center vertical py-xxl"><More/></div>


                            </div>
                        </SlideElement>


                    </div>
                    <div className="columns layout-1-1  pt-l">
                        <div className="col text-xs text-darkgray ">
                            If You have an ideal
                            <br/> or have no idea ?
                        </div>
                        <div className="is-flex jt-end col ">
                            <Link to="/Contact" className="btn btn-outline">Contact Now
                                <div className="icon icon-arrow-right"></div>
                            </Link>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>


}
export default CV
const ProductSe = ({product}) => {
    return <div className=" col  product-card card columns layout-1-2 rounded-l border">
        <div className="   is-relative product-hover">
            <div className=" columns vertical gap-l  p-l">
                {/*<div className="col  columns gap-s">*/}
                {/*    {product.tag.map((item, index) => {*/}
                {/*        return <div key={index} className="chips">#{item.tag}</div>*/}
                {/*    })}*/}
                {/*</div>*/}
                <div className="col  ">
                    <img src={product.logo} alt="" className={"size-x-xxl"}/>
                </div>
            </div>
        </div>
        <Link to={product.refTo} className="card-content product-content p-m">
            <div className={"px-m gapy-xs"}>
                <div className="text-xs">{product.exp}</div>
                <h2>{product.title}</h2>
            </div>
            <div className="is-flex bg-blur  py-s pl-l pr-s jt-between align-center">
                <div className=" gap-xs">
                    <span className="text-s">is</span>
                    <a className="title-s link-hover">{product.category} </a>
                </div>
                <div className="btn-icon">
                    <Icon className={"text-xl"} icon={"arrow-right "}/>
                </div>
            </div>

        </Link>
    </div>
}
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useIsFromBottomViewport, useIsInViewport } from "../../../slide/checker";
import styled, { keyframes } from "styled-components";

function AnimateElement(props) {
  const ref = useRef(null);
  const [visible, setVisible] = useState(null);
  const isInViewport = useIsFromBottomViewport(ref);
  const afterClass = props.after || "upComingElement";
  const beforeClass = props.before || "opa ";
  const delay = props.delay || 50;
  useEffect(() => {
    if (isInViewport === true) {
      setVisible(true);
    }
  }, [isInViewport]);
  return (
    <div
      ref={ref}
      className={visible ? afterClass : beforeClass}
      style={visible ? {} : { opacity: 1 }}
    >
      {props.children}
    </div>
  );
}
function AppearElement(props){
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);
  const isInViewport = useIsInViewport(ref);

  useMemo(() => {
    if (isInViewport === true) {
      setVisible(true);
    }
  }, [isInViewport]);
  return <div ref={ref} className={visible?"upComingElement":"downComingElement"}>
    {props.children}
  </div>
}
function SlideElement(props) {
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);
  const isInViewport = useIsInViewport(ref);
  const direction = props.direction || false;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useMemo(() => {
    if (isInViewport === true) {
      setVisible(true);
    }
  }, [isInViewport]);
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    }
  }, [ref.current]);
  return (
    <div ref={ref} className={props.className}>
      <div
        style={{
          overflow: "hidden",
          height: `${height} px`,
          width: `${width} px`,
        }}
      >
        <div
          className={
            visible ? (direction ? "fromLeftToRight" : "fromBotToTop") : ""
          }
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}

function ExpandElement(props) {
  return (
    <div
      className={`${props.className} ${
        props.animation ? "collapse" : "expand"
      }`}
    >
      {props.children}
    </div>
  );
}

export { AnimateElement, SlideElement, ExpandElement ,AppearElement};

import React, {useState} from 'react';
import {InputSwitch} from "../../../../DuckReact/Input";
import {Icon} from "../../DuckIcon";
import DuckSelect, {Segment} from "../Input/DuckSelect";
import {Layout42, Layout42Main} from "../../Support/Layout";

const sizes = [
    {size: "Default", value: ""},
    {size: "Small", value: "s"}]
const variants = [{
    variant: "Default",
    value: "",
    description: " Nút này được sử dụng khi bạn muốn tạo ra một điểm nhấn chính trên giao diện và nó nổi bật so với các phần khác.",
    usage: "Chọn màu sáng hoặc màu nền phù hợp với chủ đề tổng thể của giao diện.\n" +
        "Có thể sử dụng với văn bản màu tương phản để làm cho nút trở nên rõ ràng."
},
    {
        variant: "outline",
        value: "outline",
        description: "Nút này thường được sử dụng để tạo ra một giao diện tinh tế và đơn giản.",
        usage: "Đặt màu nền là đen hoặc màu tương tự để tạo nên sự nổi bật.\n" +
            "Sử dụng văn bản hoặc biểu tượng màu sáng để tạo ra tương phản và thu hút sự chú ý."
    },
    {
        variant: "Fill",
        value: "fill",
        description: "Nút này thường được sử dụng để tạo ra một góc độ hiện đại và nổi bật hơn so với nền.",
        usage: "Tạo border nổi bật xung quanh nút, thường là màu tương phản với màu nền.\n" +
            "Để nút trở nên rõ ràng, có thể thêm một số hiệu ứng hoặc hover state khi người dùng di chuột lên."
    },
    {
        variant: "Ghost",
        value: "ghost",
        description: " Nút này thường được sử dụng để tạo ra một giao diện nhẹ nhàng và trang nhã.",
        usage: "Không sử dụng màu nền, chỉ sử dụng border hoặc các đường viền nhẹ nhàng để phân biệt nút với nền.\n" +
            "Sử dụng văn bản hoặc biểu tượng có màu tương phản để tạo điểm nhấn."
    }]
const Color = [{
    variant: "Default",
    value: "",
    description: "Nút này thường được sử dụng để tạo ra một giao diện đơn giản và thanh lịch."
},
    {
        variant: "Primary",
        value: "primary",
        description: "Nút này thường làm nổi bật các hành động chính hoặc nút quan trọng."
    },
    {
        variant: "secondary",
        value: "secondary",
        description: " Nút này thường được sử dụng để chỉ ra các hành động có thể gây ra rủi ro hoặc xóa dữ liệu quan trọng."
    }
]
const states = [{state: "default"}, {state: "hover"}, {state: "press"}, {state: "loading"}, {state: "disable"}]

function ButtonSection(props) {

    return (<div className="mb-xxl">
        <div className="section">
            <div className="title-xxl mb-l">Button</div>
            <Layout42Main>
                <div className="text-m">
                    Use the primary button for the most important action in the view, for example on a sign-in
                    screen, landing screen, confirmation screen, error screen, or on a screen that has an explicit
                    primary action. A primary button always has a filled style. Note that there can only be one
                    primary action per view.
                </div>
                <img className="rounded-s"
                     src="https://experience.sap.com/fiori-design-ios/wp-content/uploads/sites/23/2017/07/Common-Button-Types-9.1.png"
                     alt=""/>
            </Layout42Main>


        </div>
        <ButtonVariants/>
        <ButtonColor/>
        <div className="note">
            <div className="note-content">
                <div className="note-title">
                    Chú ý
                </div>
                <div className="note-info">
                    Lưu ý rằng việc sử dụng những loại nút này phụ thuộc vào bối cảnh thiết kế cụ thể của bạn và
                    phong cách tổng thể của ứng dụng hoặc trang web. Đảm bảo rằng sự chọn lựa màu sắc và kiểu dáng
                    nút phản ánh đồng nhất với thương hiệu hoặc giao diện người dùng của bạn.
                </div>
            </div>
        </div>
        <ButtonState/>
        <SwitchSizes/>
        <ButtonGroup/>
        <SegmentButton/>
    </div>);
}

export default ButtonSection;
const ButtonVariants = () => {
    return <div className="section ">
        <div className="title-xxl mb-l">Variant</div>
        <div className="section">
            {variants.map((item, index) => {
                return <div className={"section"}>
                    <div className=" title-l mb-m">
                        Button {item.value}
                    </div>
                    <Layout42Main>
                        <div className=" bg-light  rounded-m is-center">
                            <div className="p-xxl">
                                <div
                                    className={`btn btn-has-icon ${item.variant === "" ? "" : `btn-${item.value}`}`}>
                                    Button
                                </div>
                            </div>
                        </div>

                        <div className=" text-m">
                            {item.description}
                        </div>
                    </Layout42Main>

                </div>
            })}


        </div>
    </div>
}
const ButtonColor = () => {
    return <div className="section ">
        <div className="title-xxl mb-l">Colors</div>
        {Color.map((item, index) => {
            return <div className={"section"}>
                <div className=" title-l mb-m">
                    Button {item.value}
                </div>
                <Layout42Main>
                    <div className=" bg-light  rounded-m is-center">
                        <div className="p-xxl">
                            <div
                                className={`btn btn-has-icon ${item.variant === "" ? "" : `btn-${item.value}`}`}>
                                Button
                            </div>
                        </div>
                    </div>

                    <div className=" text-m">
                        {item.description}
                    </div>
                </Layout42Main>

            </div>
        })}
    </div>
}

const SwitchSizes = () => {
    return <div className="section ">
        <div className="title-xxl mb-l">Switch</div>
        <div className="section">
            <div className=" title-l mb-l">
                Switch React
            </div>
            <Layout42Main>
                <div className="p-xxl is-center  bg-light rounded-m">
                    <InputSwitch size={""}></InputSwitch>
                </div>
                <>
                    <div className="mb-xl">
                        <div className=" text-m">
                            Switch thường được sử dụng để cho phép người dùng bật hoặc tắt một tính năng hoặc chức năng
                            nào đó.
                        </div>
                    </div>

                    <div className="title-l mb-s">
                        Cách sử dụng
                    </div>
                    <div className="mb-xl">
                        <div className="title-m mt-m">
                            Trạng Thái Bật (On):
                        </div>
                        <div className="text-m">
                            Đặt màu nền là một màu nhạt hoặc một màu tương phản khác để tạo sự tương phản với trạng thái
                            bật.
                            Sử dụng một biểu tượng hoặc văn bản màu tương phản để chỉ rõ trạng thái tắt.
                        </div>
                    </div>
                    <div className="mb-xl">
                        <div className="title-m">
                            Trạng Thái Tắt (Off):
                        </div>
                        <div className="text-m">
                            Đặt màu nền là một màu nhạt hoặc một màu tương phản khác để tạo sự tương phản với trạng thái
                            bật.
                            Sử dụng một biểu tượng hoặc văn bản màu tương phản để chỉ rõ trạng thái tắt.
                        </div>
                    </div>
                </>

            </Layout42Main>
            <div className="note mt-xl">
                <div className="note-content">
                    <div className="note-title">Lưu ý</div>
                    <div className="note-info">
                        Cân nhắc sử dụng màu sắc một cách có ý nghĩa để người dùng có thể hiểu trạng thái của switch mà
                        không cần đọc văn bản.
                        Thực hiện hiệu ứng hoặc chuyển động nhẹ khi switch thay đổi trạng thái để tạo ra trải nghiệm
                        mượt mà và tương tác.
                        Trong trường hợp của switch, mục tiêu chính là làm cho trạng thái của nó trở nên rõ ràng và dễ
                        hiểu ngay từ cái nhìn đầu tiên, giúp người dùng dễ dàng nhận biết trạng thái bật và tắt.

                    </div>
                </div>
            </div>
            <Layout42 size={"s"} code={" <div>\n" +
                "   <label className={\"field has-label-inline is-required\"}>\n" +
                "       <div className={\"label\"}>Bảo mật</div>\n" +
                "       <InputSwitch size={\"\"}></InputSwitch>\n" +
                "   </label>\n" +
                "</div>"}>
                <div>
                    <label className={"field has-label-inline is-required"}>
                        <div className={"label"}>Default</div>
                        <InputSwitch size={""}></InputSwitch>
                    </label>
                    <label className={"field has-label-inline is-required"}>
                        <div className={"label"}>Checked</div>
                        <InputSwitch checked size={""}></InputSwitch>
                    </label>
                    <label className={"field has-label-inline is-required disable"}>
                        <div className={"label"}>Disable</div>
                        <InputSwitch checked={false} size={""}></InputSwitch>
                    </label>
                </div>

            </Layout42>


        </div>


    </div>
}
const ButtonState = () => {
    const [value, setValue] = useState("")
    const [size, setSize] = useState("")
    const [color, setColor] = useState("")
    const btnString = <div>{states.map((state, index) => <div
        className={`btn ${size === "" ? "" : `btn-${size}`} ${color === "" ? "" : `btn-${color}`} ${value === "" ? "" : `btn-${value}`}${state.state === "default" ? "" : state.state}`}></div>)}</div>
    return <div className="section">
        <div className="title-xxl mb-l">State</div>
        <div className="section">
            <Layout42 size={"m"} code={btnString}>
                <div className={"columns "}>
                    {states.map((state, index) => (
                        <div
                            className={`col text-capitalize my-l btn  ${size === "" ? "" : `btn-${size}`} ${color === "" ? "" : `btn-${color}`} ${value} ${value === "" ? "" : `btn-${value}`} ${state.state === "default" ? "" : state.state}`}>{state.state}
                        </div>
                    ))}
                </div>
                <div>
                    <div className="field has-label-inline">
                        <div className="label">Size</div>
                        <DuckSelect data={sizes} placeholder={"Variant"} child={({item, index,}) => (
                            <div title={item.size} key={index} className={"p-s"} onClick={() => {
                                setSize(item.value)
                            }}>
                                {item.size}
                            </div>
                        )}/>
                    </div>
                    <div className="field has-label-inline">
                        <div className="label">Màu sắc</div>
                        <DuckSelect data={Color} placeholder={"Variant"} child={({item, index,}) => (
                            <div title={item.variant} key={index} className={"p-s"} onClick={() => {
                                setColor(item.value)
                            }}>
                                {item.variant}
                            </div>
                        )}/>
                    </div>
                    <div className="field has-label-inline">
                        <div className="label">Style</div>
                        <DuckSelect data={variants} placeholder={"Variant"} child={({item, index,}) => (
                            <div title={item.variant} key={index} className={"p-s"} onClick={() => {
                                setValue(item.value)
                            }}>
                                {item.variant}
                            </div>
                        )}/>
                    </div>
                </div>
            </Layout42>
        </div>


    </div>
}
const ButtonGroup = () => {
    const btnGroupString = <div className="btn-group">
        <div className="btn btn-s">Button Left</div>
        <div className="btn btn-outline btn-s">Button</div>
        <div className="btn btn-outline btn-s">Button</div>
        <div className="btn btn-outline btn-s">
            <div className="text-center icon icon-down text-m"></div>
        </div>
    </div>
    return <div className="section ">
        <div className="title-xxl mb-l">Button Group</div>
        <div className="section">
            <div className=" title-l">
                Sử dụng
            </div>
            <Layout42Main>
                <div className=" is-center bg-light rounded-l p-xl mt-m ">
                    <div className="columns gap-l">
                        <div className="btn-group">
                            <div className="btn btn-s">Button Left</div>
                            <div className="btn btn-outline btn-s">Button</div>
                            <div className="btn btn-outline btn-s">Button</div>
                            <div className="btn btn-outline btn-s">
                                <Icon className={"text-m"} icon={"down "}/>
                            </div>
                        </div>
                    </div>

                </div>
                <div className=" text-m">
                    Use the primary button for the most important action in the view, for example on a sign-in
                    screen, landing screen, confirmation screen, error screen, or on a screen that has an explicit
                    primary action. A primary button always has a filled style. Note that there can only be one
                    primary action per view.
                </div>
            </Layout42Main>
            <Layout42 size={"s"} code={btnGroupString}>
                <div>
                    <div className={"is-flex jt-between  pb-s"}>
                        <div className="title-l">Group</div>
                        <div className="btn-group">
                            <div className="btn btn-s">Button Left</div>
                            <div className="btn btn-outline btn-s">Button</div>
                            <div className="btn btn-outline btn-s">Button</div>
                            <div className="btn btn-outline btn-s">
                                <Icon className={"text-m"} icon={"down "}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout42>
        </div>
    </div>
}
const SegmentButton = () => {
    const [direction, setDirection] = useState(0)
    const segmentString = ` <Segment handleClick={() => {}} 
    segment={[
                {action: "device-mobile", value: "mobile"}, 
                {action: "device-tablet",value: "tablet"}, 
                {action: "device-pc-tower", value: "web"}]}
    child={({item, index}) => (
        <div key={index} className={\`btn  gapx-xs {1 === index ? "active" : ""}\`}>
            <Icon className={"text-l"} icon={item.action}/>
            <div className="text-capitalize text-xs">{item.value}</div>
        </div>)}/>`
    return <div className="section">
        <div className=" title-l">
            Segment
        </div>
        <Layout42Main>
            <div className=" is-flex jt-center   bg-light rounded-m  ">
                <div className="p-xl">
                    <Segment handleClick={() => {
                    }} segment={[{action: "device-mobile", value: "mobile"}, {
                        action: "device-tablet",
                        value: "tablet"
                    }, {action: "device-pc-tower", value: "web"}]}
                             child={({item, index}) => (
                                 <div key={index} className={`btn  gapx-xs ${1 === index ? "active" : ""}`}><Icon
                                     className={"text-l"} icon={item.action}/>
                                     <div className="text-capitalize text-xs">{item.value}</div>
                                 </div>)}
                    />
                </div>
            </div>
            <>
                <div className="title-l mb-s">
                    Cách sử dụng
                </div>
                <div className="mb-xl">
                    <div className="title-m mt-m">
                        Chuyển Đổi Chế Độ hoặc Dữ Liệu:
                    </div>
                    <div className="text-m">
                        Các phần segment thường được sử dụng để chuyển đổi giữa các chế độ khác nhau của ứng dụng hoặc
                        hiển thị các tập dữ liệu khác nhau.
                    </div>
                </div>
                <div className="mb-xl">
                    <div className="title-m mt-m">
                        Lọc và Sắp Xếp
                    </div>
                    <div className="text-m">
                        Cung cấp khả năng lọc và sắp xếp thông tin theo các tiêu chí khác nhau, giúp người dùng tìm kiếm
                        thông tin một cách dễ dàng.
                    </div>
                </div>
                <div className="mb-xl">
                    <div className="title-m mt-m">
                        Điều Hướng Trong Ứng Dụng
                    </div>
                    <div className="text-m">
                        Sử dụng các phần segment để điều hướng giữa các phần khác nhau của ứng dụng mà không cần chuyển
                        đến các trang riêng biệt.
                    </div>
                </div>
                <div className="mb-xl">
                    <div className="title-m mt-m">
                        Lựa Chọn Chế Độ Xem
                    </div>
                    <div className="text-m">
                        Cho phép người dùng chọn giữa các chế độ xem khác nhau, chẳng hạn như chế độ lưới và chế độ danh
                        sách.
                    </div>
                </div>
                <div className="mb-xl">
                    <div className="title-m mt-m">
                        Chọn Lựa Dữ Liệu hoặc Danh Mục
                    </div>
                    <div className="text-m">
                        Sử dụng để người dùng chọn giữa các danh mục hoặc tùy chọn dữ liệu cụ thể mà họ muốn xem.
                    </div>
                </div>
                <div className="mb-xl">
                    <div className="title-m mt-m">
                        Hiển Thị Lựa Chọn hoặc Tùy Chọn
                    </div>
                    <div className="text-m">
                        Thường được sử dụng để hiển thị các lựa chọn hoặc tùy chọn ngắn gọn mà người dùng có thể chọn
                        một cách nhanh chóng.
                    </div>
                </div>
                <div className="mb-xl">
                    <div className="title-m mt-m">
                        Trong một số trường hợp, các phần segment có thể được sử dụng để kích thích hoặc vô hiệu hóa các
                        chức năng đa nhiệm trên giao diện người dùng.
                    </div>
                    <div className="text-m">
                        Trong một số trường hợp, các phần segment có thể được sử dụng để kích thích hoặc vô hiệu hóa các
                        chức năng đa nhiệm trên giao diện người dùng.
                    </div>
                </div>
            </>
        </Layout42Main>

        <Layout42 size={"s"} code={segmentString}>
            <Segment handleClick={() => {
            }} segment={[{action: "device-mobile", value: "mobile"}, {
                action: "device-tablet",
                value: "tablet"
            }, {action: "device-pc-tower", value: "web"}]}
                     child={({item, index}) => (
                         <div key={index} className={`btn  gapx-xs ${direction === index ? "active" : ""}`}
                              onClick={() => {
                                  setDirection(index)
                              }}><Icon className={"text-l"} icon={item.action}/>
                             <div className="text-capitalize text-xs">{item.value}</div>
                         </div>)}
            />
        </Layout42>
    </div>
}
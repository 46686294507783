import React, {useEffect, useRef, useState} from 'react';
import {Icon} from "../../DuckIcon";


function Segment(props) {
    const sub = useRef(null)
    const [visible, setVisible] = useState(null);
    let elmWidth = 0
    let offSet = 0
    const onHover = ref => {
        if (ref)
            setVisible(ref)
        else setVisible(null)
    }
    useEffect(() => {
        const elm = sub.current
        if (visible != null) {
            elmWidth = visible.current.clientWidth
            offSet += visible.current.offsetLeft
            elm.style.transform = `translateX(${offSet}px)`
            elm.style.width = `${elmWidth}px`
        } else {
            elm.style.opacity = 0;
        }
    })

    const osStyle = {
        opacity: visible ? 1 : 0,
        width: elmWidth,
        transform: `translateX(${offSet}px)`,
        transitionDuration: '200ms'
    }
    return <div className="segment">
        <div ref={sub} className="segment-sub" style={visible ? osStyle : {}}/>
        {props.segment.map((item, index) => {
            return <SegmentItem key={index} onHover={onHover}>
                {props.child({item, index, ...props})}
            </SegmentItem>
        })}
    </div>
}

const SegmentItem = (props) => {
    const segment = useRef(null)
    return <div ref={segment}  style={props.style}
                onMouseEnter={() => props.onHover(segment)}
                onMouseLeave={() =>
                    props.onHover(null)
                }>
        {props.children}

    </div>
}
export {Segment}

function DuckSelect(props) {
    const [isOpen, setIsOpen] = useState(false)
    const [defaultTitle, setDefaultTitle] = useState('')
    const handleOpen = () => {
        setIsOpen(!isOpen)
    }
    const handleSelect = (select) => {
        setIsOpen(false)
    }
    return (
        <div className={"select"}>
            <div onClick={() => handleOpen()} className="select-input">
                <div
                    className={`${props.placeholder && defaultTitle === '' ? 'select-title placeholder' : 'select-title'}`}>
                    {defaultTitle === '' ? props.placeholder : defaultTitle}</div>
                <Icon className={"text-l text-disable"} icon={isOpen ? "up-direction" : "down"}/>
            </div>
            {isOpen ?
                <div className={"select-list active"}>
                    {props.data.map((item, index) => {
                        return <div key={index} className={"select-item"} onClick={() => {
                            handleSelect(index)
                            setDefaultTitle(props.child({item, index, ...props}).props.title)

                        }}>
                            {props.child({item, index, ...props})}
                        </div>
                    })}
                </div>
                :
                <div className={"select-list "}></div>}
        </div>
    );
}

export default DuckSelect;
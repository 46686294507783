import React from 'react';
import imgBP from '../../../../../static/breakpoint.png'
import {Layout42Main} from "../../Support/Layout";

const ViewportSection = () => {
    const Variables = [{name: "-l", value: "20px"}, {name: "-xl", value: "24px"}, {name: "-xxl", value: "32px"}]
    return (
        <div className="section">
            <div className="title-xxl mb-l">Breakpoint</div>
            <div className=" title-l">
                Devices
            </div>
            <Layout42Main>
                <div className="text-m">
                    Xây dựng 1 giao diện cột với Duck rất dễ dàng,thêm thẻ <code>columns</code>
                    , thêm các thẻ <code>col</code> như các Design system khác
                    <br/> Mặc định, các cột sẽ cách nhau <strong>0.55rem</strong>
                </div>
                <img className="rounded-xs " src={imgBP} alt=""/>

            </Layout42Main>


            <div className=" border table-section   ">
                <table className="  bg-white  ">
                    <thead>
                    <tr className={"p-s"}>
                        <th>Size</th>
                        <th>{`<`} 500px</th>
                        <th>{`<`} 980px</th>
                        <th>{`<`} 1000px</th>
                        <th>{`<`} 1200px</th>
                        <th>{`<`} 1400px</th>
                        <th>{`<`} 1600px</th>
                        <th>> 1600px</th>
                    </tr>

                    </thead>
                    <tbody>

                    <tr className={"p-m text-center"}>
                        <td>
                            <div className="code">container-xs</div>
                        </td>
                        <td>420px</td>
                        <td>420px</td>
                        <td>420px</td>
                        <td>420px</td>
                        <td>420px</td>
                        <td>420px</td>
                        <td>420px</td>

                    </tr>
                    <tr className={"p-m text-center"}>
                        <td>
                            <div className="code">container-s</div>
                        </td>
                        <td className="text-primary">100%</td>
                        <td>720px</td>
                        <td>720px</td>
                        <td>720px</td>
                        <td>720px</td>
                        <td>720px</td>
                        <td>720px</td>
                    </tr>
                    <tr className={"p-m text-center"}>
                        <td>
                            <div className="code">container-m</div>
                        </td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td>920px</td>
                        <td>920px</td>
                        <td>920px</td>
                        <td>920px</td>
                        <td>920px</td>
                    </tr>
                    <tr className={"p-m text-center"}>
                        <td>
                            <div className="code">container-l</div>
                        </td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td>1080px</td>
                        <td>1080px</td>
                        <td>1080px</td>
                        <td>1080px</td>
                    </tr>
                    <tr className={"p-m text-center"}>
                        <td>
                            <div className="code">container-xl</div>
                        </td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td>1280px</td>
                        <td>1280px</td>
                        <td>1280px</td>
                    </tr>
                    <tr className={"p-m text-center"}>
                        <td>
                            <div className="code">container-xxl</div>
                        </td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td>1440px</td>
                        <td>1440px</td>
                    </tr>
                    <tr className={"p-m text-center"}>
                        <td>
                            <div className="code">container-full</div>
                        </td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                        <td className="text-primary">100%</td>
                    </tr>

                    </tbody>
                </table>

            </div>
        </div>
    );
};

export default ViewportSection;

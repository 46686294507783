import React, {useState} from 'react';
import {Icon} from "../../Duck/DuckDesignSystem/Source/DuckIcon";
import {NewsModel} from "../../Model/NewsModel";
import {Link, useNavigate} from "react-router-dom";
import {ContextMenu} from "../../Duck/DuckEditor/Context/TableContext";
import {deleteNews} from "../../Api/Auth/Services/News/NewsApi";
import {useToast} from "../../Duck/DuckReact/Toast/useToast";

function NewsDataTable({paper,loading,setFilter}) {
    const [menu, setMenu] = useState(false)
    const clickMenu = (uuid) => {
        console.log(uuid)
        // setMenu(uuid)
        if (uuid !== menu) {
            setMenu(uuid)
        } else {
            setMenu(false)
        }
    }
    const toast = useToast()
    const navigate = useNavigate();
    const handleUpdateClick = (uuid) => {
        // Thực hiện điều hướng khi nút được click
        navigate(`/news/update/${menu}`);
    };
    const newsMenu = () => {
        return [
            {
                actionName: "Update News",
                icon: "icon-message-error-1",
                action: function () {
                    handleUpdateClick()
                }
            },
            {
                actionName: "Delete",
                icon: "icon-trash-can",
                action: function () {
                    HandleClickDeleteNews(menu)
                }
            }
        ]
    }
    const HandleClickDeleteNews = (uuid) => {
        console.log("delete " + uuid)
        deleteNews(uuid).then(async function (message) {
            if (message.status === 200) {
                console.log(message.status)
                // await setLoading(false)
                await toast.success("Đã xóa")
            } else {
                toast.error("Thất bại!")
                // setLoading(false)
            }
        }).catch(async err => {
            // await setLoading(false)
            toast.error(err.message)
        })
        setMenu(false)
    }
    return (
        <div className="   table-section   ">
            <table className=" table-strip-border bg-white  ">
                <thead>
                <tr>
                    <th className={"px-s "}> Stt</th>
                    <th className={"px-s text-left "}>Tiêu đề</th>
                    <th className={"px-s text-left"}>Thư mục</th>
                    <th className={"p-s"}>
                        <Icon className={"text-l "} icon={"up-down"}></Icon>
                    </th>
                    <th className={"p-s"}>
                        <Icon className={"text-l "} icon={"like "}/>
                    </th>
                    <th className={"p-s"}>
                        <Icon className={"text-l "} icon={"document-edit "}/>
                    </th>

                    <th className={"p-m"}>
                        <Icon className={"text-l "} icon={"eye "}/>
                    </th>


                </tr>
                </thead>
                <tbody>
                {loading ? loading : paper["news"].length === 0 ? (
                        <div className="empty-state ">Không có tin nào.</div>
                    )
                    : paper["news"].map((item, index) => {
                        const news = new NewsModel(item)
                        return (
                            <tr key={index}>
                                <td className={"p-s text-center"}>{index + 1}</td>
                                <td className={"p-s "}>
                                    <div className="is-block size-x-l-x2">
                                        <Link className="title-m is-2-line"
                                              to={"/doc/".concat(news.CustomUrl)} target="_blank">
                                            {news.Title}
                                        </Link>
                                        <div className="text-s text-disable is-2-line">
                                            {news.Content}
                                        </div>
                                    </div>

                                </td>
                                <td onClick={() => setFilter(news.CategoryStr)}
                                    className={"p-s cursor-pointer text-primary text-italic"}>
                                    {news.CategoryStr}
                                </td>
                                <td className={"p-s text-center"}>{news.Viewer}</td>
                                <td className={"p-s text-center"}>{news.Like}</td>
                                <td className={"p-s text-center"}>{news.IsActive ?
                                    <input checked type="checkbox"/> :
                                    <input type="checkbox"/>} </td>
                                <td className={"p-s text-center"}>{news.IsUpdate ?
                                    <input checked type="checkbox"/> :
                                    <input type="checkbox"/>}</td>


                                <td className={"p-s is-relative"}>
                                    <div className="is-flex gapx-s jt-end ">

                                        <button onClick={() => clickMenu(news.uuid)}
                                                className="btn btn-ghost btn-s">
                                            <Icon className={"text-l"} icon={"circle"}/>
                                        </button>

                                    </div>
                                    {menu === news.uuid ?
                                        <ContextMenu menu={newsMenu()}
                                                     position={{top: 56, right: 0}}></ContextMenu> : null}
                                </td>

                            </tr>
                        )
                    })
                }

                </tbody>
            </table>

        </div>
    );
}

export default NewsDataTable;
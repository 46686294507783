import "./App.scss";
import "./Skeleton.css";
import Navbar from "./navbar/Navbar.js";
import Footer from "./navbar/Footer.js";
import Letter from "./slide/card/letter";
import NotFound from "./slide/NotFound";
import {BrowserRouter, Outlet, redirect, Route, Routes} from "react-router-dom";
import Duck from "./Duck/DuckDesignSystem/Source/Duck";
import ForestPage from "./ThreeD/ForestPage";
import DuckIcon from "./Duck/DuckDesignSystem/Source/DuckIcon";
import Tool from "./Duck/DuckDesignSystem/Source/Tool";
import Login from "./Admin/Login";
import AllElement from "./Element/AllElement";
import {ThreeD} from "./menu-item/threeD";
import Other from "./menu-item/other";
import React, {useEffect, useState} from "react";
import {Analytics} from '@vercel/analytics/react';
import {Provider} from "react-redux";
import Admin, {ImageDE, NewNewsDE, NewsDashboard, UpdateNewsDE, ViewNewsDE, ViewNewsDETEST} from "./Admin/Admin";
import store, {persistor} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import {getApp} from "./constrants";
import {SpeedInsights} from "@vercel/speed-insights/react";
import ViewNews from "./Admin/DashBoard/News/ViewNews";


function MainApp() {
    useEffect(() => {
        document.title = "Portfolio của Hi nè";
    })

    return (
        <div className="  ">
            <Letter/>
        </div>
    );
}
export function RootDomain() {
    return redirect("https://portfo.hine.studio");
}
export function DuckDomain() {
    useEffect(() => {
        document.title = "Duck Design by HineStudio";
    })
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<Duck/>}/>
                <Route index path="/duck-icon" element={<DuckIcon/>}/>
            </Routes>
        </BrowserRouter>
    )
}
export function PortfoDomain() {
    useEffect(() => {
        document.title = "Portfolio of Hine";
    })
    return (
        <BrowserRouter>
            <Navbar></Navbar>
            <Routes>
                <Route path="/" element={<MainApp/>}/>
                {/*<Route path="/uiux" element={<Uiux/>}/>*/}
                <Route path="/work" element={<ThreeD/>}/>
                <Route path="/work" element={<ThreeD/>}/>
                <Route path={"/cv"} element={<AllElement/>}/>
                {/*<Route path="/elements/duck-editor" element={<DuckEditor/>}/>*/}
                {/*<Route path="/elements/duck-plugin" element={<DuckPlugin/>}/>*/}
                <Route index path="/3d/forest" element={<ForestPage/>}/>
                <Route path="/Contact" element={<Other/>}/>
                {/*<Route path="/Load" element={<LoadingScreen/>}/>*/}
                <Route path="/Tool" element={<Tool/>}/>

                <Route path="/doc/:customUrl" element={<ViewNewsDE/>}/>
                <Route path="/*" exact={true} element={<NotFound/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    )
}
export function AdminDomain() {
    useEffect(() => {
        document.title = "HineStudio manager";
    })
    return (
        <BrowserRouter>
            <Routes>

                <Route path="/" element={<PrivateRoute></PrivateRoute>} >
                    <Route path="/" element={<Admin/>}>
                        <Route path="/addImage" element={<ImageDE/>} />
                        <Route path="/news" element={<NewsDashboard/>}>
                        </Route>
                        <Route path="/doc/:customUrl" element={<ViewNewsDE/>}/>

                        <Route path="/news/new" element={<NewNewsDE/>} >
                            <Route path="/news/new/:categories" element={<ViewNewsDE/>}/>
                        </Route>
                        <Route path="news/update/:customUrl" element={<UpdateNewsDE/>} />
                    </Route>

                    {/*<Route path="/admin/preview" element={<Tool/>} />*/}
                    <Route path="/login" element={<Login/>} />
                </Route>

            </Routes>
        </BrowserRouter>

    )
}
function App() {
    const loadingTime = 2000
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, loadingTime)
    }, [])
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    };
    const CurrentApp = getApp()
    return (
        <div className="App">
            <div className="Duck">
                <CurrentApp/>
            </div>
            <Analytics/>
            <SpeedInsights />
            {/*<BottomMenu/>*/}
        </div>
    );
}


const PrivateRoute = () => {
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Outlet/>
        </PersistGate>
    </Provider>
}


export default App;

import {v4 as uuidv4} from "uuid";
import {createSlice} from "@reduxjs/toolkit";
import {initializeData} from "./editorServices";
import {userLogin} from "../Auth/Action";

const xID = uuidv4()
// store.js

const initialState = {
    activeId: xID,
    listContext: [{
        "id": uuidv4(),
        "defaultState": {
            "header": "h-xxl",
            "color": "text-mute",
            "textBold": false,
            "textItalic": false,
            "textUnderline": false,
            "textUppercase": false,
            "textAlign": "text-left",
            "list": "",
            "textIndent": "",
            "attachment": "",
            "linkEmbed": "",
            "image": false,
            "icon": "",
            "quote": ""
        },
        "content": "Create Your Note"
    },
        {
            "id": uuidv4(),
            "defaultState": {
                "header": "p",
                "color": "text-mute",
                "textBold": false,
                "textItalic": false,
                "textUnderline": false,
                "textUppercase": false,
                "textAlign": "text-left",
                "list": "",
                "textIndent": "",
                "attachment": "",
                "linkEmbed": "",
                "image": {
                    "url": "https://i.imgur.com/p53cX1f.png",
                    "ratio": "16-9"
                },
                "icon": "",
                "quote": ""
            },
            "content": "x"
        },
        {
            "id": xID,
            "defaultState": {
                "header": "p",
                "color": "text-black",
                "textBold": false,
                "textItalic": false,
                "textUnderline": false,
                "textUppercase": false,
                "textAlign": "text-left",
                "list": "",
                "textIndent": "",
                "attachment": "",
                "linkEmbed": "",
                "image": false,
                "icon": "",
                "quote": ""
            },
            "content": "💖💖💖 Hine"
        }]
};

// const loadStateFromLocalStorage = () => {
//     try {
//         const serializedState = localStorage.getItem('editor');
//         if (serializedState === null) {
//             return undefined;
//         }
//         return JSON.parse(serializedState);
//     } catch (error) {
//         console.error('Error loading state from localStorage:', error);
//         return undefined;
//     }
// };

const editorSlice = createSlice({
    name: 'editor',
    // initialState: loadStateFromLocalStorage() || initialState,
    initialState: initialState,
    reducers: {
        updateActive: (state, action) => {
            state.activeId = action.payload;
        },
        addComponent: (state, action) => {
            const {component} = action.payload
            const index = state.listContext.findIndex(context => context.id === state.activeId);
            if (index !== -1) {
                const newContext = {
                    "id": uuidv4(),
                    "defaultState": {
                        image:false,
                        table:false,
                        isComponent:true
                    },
                    "component":component
                };

                state.listContext.splice(index + 1, 0, newContext);
            }
        },
        addContext: (state, action) => {
            const {id, data} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);

            if (index !== -1) {
                const newContext = {
                    "id": uuidv4(),
                    "defaultState": {
                        "header": data.header || "p",
                        "color": data.color || "text-black",
                        "textBold": data.textBold || false,
                        "textItalic": data.textItalic || false,
                        "textUnderline": data.textUnderline || false,
                        "textUppercase": data.textUppercase || false,
                        "textAlign": data.textAlign || "text-left",
                        "list": data.list || false,
                        "textIndent": data.textIndent || false,
                        "attachment": data.attachment || "",
                        "linkEmbed": data.linkEmbed || "",
                        "image": data.imag || false,
                        "icon": data.icon || "",
                        "quote": data.quote || false
                    },
                    "content": data.content || ""
                };

                state.listContext.splice(index + 1, 0, newContext);
            }
        },

        deleteContext: (state, action) => {
            state.listContext = state.listContext.filter(context => context.id !== action.payload);
        },
        updateContent: (state, action) => {
            const {id, updateContent} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);
            if (index !== -1) {
                state.listContext[index].content = updateContent;
            }
        },
        textBold: (state, action) => {
            const {id} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);
            if (index !== -1) {
                state.listContext[index].defaultState.textBold = !state.listContext[index].defaultState.textBold;
            }
        },
        textHeader: (state, action) => {
            const {id, headerType} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);
            if (index !== -1) {
                state.listContext[index].defaultState.header = headerType;
            }
        },
        textItalic: (state, action) => {
            const {id, isItalic} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);
            if (index !== -1) {
                state.listContext[index].defaultState.textItalic = !state.listContext[index].defaultState.textItalic;
            }
        },

        toggleIndent: (state, action) => {
            const {id, textIndent} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);
            if (index !== -1) {
                state.listContext[index].defaultState.textIndent = !state.listContext[index].defaultState.textIndent;
            }
        },
        toggleAlign: (state, action) => {
            const {id, textAlign} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);

            if (index !== -1) {
                state.listContext[index].defaultState.textAlign = textAlign;
            }
        },

        addAttachments: (state, action) => {
            const {id, attachment} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);
            if (index !== -1) {
                state.listContext[index].defaultState.attachment = attachment;
            }
        },

        embedLink: (state, action) => {
            const {id, linkEmbed} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);
            if (index !== -1) {
                state.listContext[index].defaultState.linkEmbed = linkEmbed;
            }
        },

        addImage: (state, action) => {
            const {id, url} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);

            if (index !== -1) {
                const newContext = {
                    "id": uuidv4(),
                    "defaultState": {
                        "image": {
                            "url": url,
                            "ratio": "16-9"
                        },
                    },
                    "content": "Image Description"
                };
                state.listContext.splice(index + 1, 0, newContext);
            }
        },
        updateImage: (state, action) => {
            const {id, image} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);
            if (index !== -1) {
                state.listContext[index].defaultState.image = image;
            }
        },
        updateImageRatio: (state, action) => {
            const {id, ratio} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);
            if (index !== -1) {
                state.listContext[index].defaultState.image.ratio = ratio;
            }
        },
        addIcon: (state, action) => {
            const {id, icon} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);
            if (index !== -1) {
                state.listContext[index].defaultState.icon = icon;
            }
        },

        toggleQuote: (state, action) => {
            const {id} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);
            if (index !== -1) {
                state.listContext[index].defaultState.quote = !state.listContext[index].defaultState.quote;
            }
        },
        addTable: (state, action) => {
            const {id} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);

            if (index !== -1) {
                const newTable = {
                    "id": uuidv4(),
                    "defaultState": {
                        "table": true,
                        "image": false
                    },
                    "content": [
                        ["s", "x", "q"], ["d", "f", "r"],
                    ]
                };

                // Thêm newTable vào danh sách context sau context có id
                state.listContext.splice(index + 1, 0, newTable);
            }
        },

        isList: (state, action) => {
            const {id, list} = action.payload;
            const index = state.listContext.findIndex(context => context.id === id);
            if (index !== -1) {
                state.listContext[index].defaultState.list = list;
            }
        },
        initializeData: (state, action) => {
            const {data} = action.payload;
            state.activeId = data.id
            state.listContext = data.listContext
        }
    },
    extraReducers: (builder) => {
        builder.addCase(initializeData.fulfilled, (state, action) => {
            state.activeId = action.payload.activeId;
            state.listContext = [...action.payload.listContext];
        }).addCase(initializeData.rejected, (state, action) => {
            console.log("initializeData.fulfilled action:", action.error.message);
        });
    }

});

export const {
    addComponent,
    updateActive,
    addContext,
    deleteContext,
    updateContent,
    textBold,
    textHeader,
    textItalic,
    embedLink,
    toggleIndent,
    addTable,
    addAttachments,
    addImage,
    updateImageRatio,
    addIcon,
    toggleAlign,
    toggleQuote,
    updateImage,
    isList
} = editorSlice.actions;

export default editorSlice.reducer


export function truncate(str, length) {
    if (length == null) {
        length = 100;
    }
    let ending = '...';
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};
export function getTimeAgo(isoString) {
    const currentDate = new Date();
    const pastDate = new Date(isoString);

    const timeDifference = currentDate - pastDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
        return years === 1 ? "1 năm trước" : `${years} năm trước`;
    } else if (months > 0) {
        return months === 1 ? "1 tháng trước" : `${months} tháng trước`;
    } else if (days > 0) {
        return days === 1 ? "1 ngày trước" : `${days} ngày trước`;
    } else if (hours > 0) {
        return hours === 1 ? "1 giờ trước" : `${hours} giờ trước`;
    } else if (minutes > 0) {
        return minutes === 1 ? "1 phút trước" : `${minutes} phút trước`;
    } else {
        return seconds === 1 ? "1 giây trước" : `${seconds} giây trước`;
    }
}

import React, {useState} from 'react';
import {Icon} from "../../../../Duck/DuckDesignSystem/Source/DuckIcon";

function HomeMetadata({onMetadataChange}) {
    const [metaData, setMetaData] = useState({
        source: "Hine Studio",
        location: "Nghe An - Vietnam",
        score: 9.4,
        vote: 24,
        recordDay: "2024-01-12T00:00:00Z"
    });
    const updateSource = (newSource) => {
        const updatedMetaData = {
            ...metaData,
            source: newSource,
        };
        setMetaData(updatedMetaData);
        onMetadataChange(updatedMetaData); // Gọi callback để gửi dữ liệu lên component cha
    };
    const updateLocation= (newLocation) => {
        const updatedMetaData = {
            ...metaData,
            location: newLocation,
        };
        setMetaData(updatedMetaData);
        onMetadataChange(updatedMetaData); // Gọi callback để gửi dữ liệu lên component cha
    };
    const updateScore= (newsScore) => {
        const updatedMetaData = {
            ...metaData,
            score: newsScore,
        };
        setMetaData(updatedMetaData);
        onMetadataChange(updatedMetaData); // Gọi callback để gửi dữ liệu lên component cha
    };
    const updateVote= (newsScore) => {
        const updatedMetaData = {
            ...metaData,
            vote: newsScore,
        };
        setMetaData(updatedMetaData);
        onMetadataChange(updatedMetaData); // Gọi callback để gửi dữ liệu lên component cha
    };
    const updateDay= (newsScore) => {
        const updatedMetaData = {
            ...metaData,
            recordDay: newsScore,
        };
        setMetaData(updatedMetaData);
        onMetadataChange(updatedMetaData); // Gọi callback để gửi dữ liệu lên component cha
    };
    return (
        <div className={" "}>
            <h3 className={""}>METADATA</h3>

            <div className="field ">
                <label>Source</label>
                <input
                    value={metaData.source}
                    onChange={(e) => updateSource(e.target.value)}
                />
            </div>
            <div className="field ">
                <label>Location</label>
                <input
                    value={metaData.location}
                    onChange={(e) => updateLocation(e.target.value)}
                />
            </div>
            <div className="field ">
                <label>Score</label>
                <input
                    value={metaData.score}
                    onChange={(e) => updateScore(e.target.value)}
                />
            </div>
            <div className="field ">
                <label>Vote</label>
                <input
                    value={metaData.vote}
                    onChange={(e) => updateVote(e.target.value)}
                />
            </div>
            <div className="field ">
                <label>Record Day</label>
                <input type={"date"}
                    value={metaData.recordDay}
                    onChange={(e) => updateDay(e.target.value)}
                />
            </div>
        </div>

    );
}

export default HomeMetadata;
import React, {useEffect, useState} from "react";
import {WithRouter} from "../withRouter";
import {getAllCategory} from "../../Api/Auth/Services/News/CategoryApi";
import CategorySection from "./News/AdminSection/CategorySection";
import toast from "../../Duck/DuckReact/Toast/Toast";
import {NewsSection} from "./News/AdminSection/NewsSection";
import LoadingScreen from "../../LoadingScreen";

const NewsManager = () => {
    const [tab, setTab] = useState(1)
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getAllCategory().then(data => {
            setCategories(data)
            setLoading(false);
            console.log(categories)
        }).catch(res => {
            console.log(res)
            toast.error(res)
        })
    }, [])
    function changeTab(value) {
        if (value === tab) {
        } else
            setTab(value)
        console.log(value)
    }

    const tabs = [
        {
            tabId: 1,
            tabName: "Bài viết"
        },
        {
            tabId: 1,
            tabName: "Thư mục"
        }
    ]

    return (<div className={"columns  vertical gap-x is-grow"}>

            <div className=" ">
                <div className="container-xxl">
                    <div className="py-xl  columns  align-end">
                        <div className="col   is-title ">
                            <div className="is-flex align-center ">
                                <h1>Quản lý tin tức</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xxl    is-grow">
                {loading===true?<LoadingScreen/>: <div className="columns  layout-1-3   unwrap l-vertical is-grow ">
                    <div className="col  is-flex is-overflow  border is-grow  ">
                            <CategorySection categories={categories} loading={loading}/>
                    </div>
                    <div className="col border is-grow  is-overflow">
                            <NewsSection categories={categories} loading={loading}/>
                    </div>
                </div>}
            </div>


        </div>


    )
}
export default WithRouter(NewsManager)



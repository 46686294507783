import React, {useRef, useState} from 'react';
import {InputField} from "../../../../DuckReact/Input";
import HiLightCode from "../../Support/hiLightCode";
import {Icon} from "../../DuckIcon";
import {Layout42, Layout42Main} from "../../Support/Layout";
import DuckSelect, {Segment} from "./DuckSelect";
import {CheckboxString, UploadInputString, VariantInputString} from "./InputString";

const sizes = [{size: "Default", value: ""}, {size: "Small", value: "s"}, {
    size: "Large",
    value: "l"
}]
const type = [
    {label: "color", type: "color"},
    {label: "date", type: "date"},
    {label: "datetime-local", type: "datetime-local"},
    {label: "date", type: "date"},
    {label: "range", type: "range"},
    {label: "time", type: "time"},
    {label: "url", type: "url"},
    {label: "week", type: "week"},
]
const content = [
    {label: "Email", required: true, placeHolder: "Enter your email", type: "email", tooltip: "is email"},
    {label: "Password", required: true, placeHolder: "Enter your password", type: "password", tooltip: "is email"}]
const InputSection = () => {
    return (
        <div className="section">
            <InputVariant/>
        </div>
    );
};

export default InputSection;

function InputVariant(props) {
    const [label, setLabel] = useState(true)
    const [direction, setDirection] = useState(0)
    const onChecked = () => {
        setLabel(!label)
    };
    const inputRef = useRef(null);
    // console.log(inputRef.current.value)
    const [value, setValue] = useState("");
    const [inputSize, setSize] = useState("");
    const [uploadSize, setUploadSize] = useState("");
    const [checkValid, setCheckValid] = useState(false)
    const ValidateEmail = (email) => {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return !!email.match(validRegex)
    }
    const getValue = (string) => {
        setValue(string);
        setCheckValid(ValidateEmail(value))
        console.log(checkValid)
    };
    const inputString = `<InputField required
            inline={${direction?"true":"false"}} 
            size={"${inputSize}"} 
            handle={() => {}} 
            type={${"email"}} 
            inputRef={${"asdsd"}} 
            onChange={() => { }} 
            placeholder={${"Enter your email"}} 
            label={${"email"}} 
            tooltip={${"wrong email"}}/>`
    return (
        <div className=" is-block  font-text">

            <div className=" is-block  font-text">
                <div className="section">
                    <h3 className="h-l">Input Variant</h3>
                    <Layout42 size={"s"} code={inputString}>
                        <div>
                            {content.map((item, index) => {
                                return <div key={index} className="col-4">

                                    <InputField required
                                                inline={!direction}
                                                size={inputSize}
                                                handle={() => {
                                                }}
                                                type={item.type}
                                                inputRef={inputRef}
                                                onChange={() => {
                                                }}
                                                placeholder={item.placeHolder}
                                                label={item.label}
                                                tooltip={item.tooltip}
                                    />

                                </div>
                            })}
                        </div>
                        <div>
                            <div className="field has-label-inline">
                                <div className="label">Label</div>
                                <Segment handleClick={() => {
                                }} segment={[{action: "Cùng dòng"}, {action: "Xuống dòng"}]}
                                         child={({item, index}) => (
                                             <div key={index} className={`btn ${direction === index ? "active" : ""}`}
                                                  onClick={() => {
                                                      setDirection(index)
                                                  }}>{item.action}</div>)}
                                >
                                </Segment>
                            </div>
                            <div className="field has-label-inline">
                                <div className="label">Size</div>
                                <DuckSelect data={sizes} placeholder={"select size"}
                                            child={({item, index,}) => (
                                                <div title={item.size} key={index} className={"p-s"} onClick={() => {
                                                    setSize(item.value)
                                                }}>
                                                    {item.size}
                                                </div>
                                            )}/>
                            </div>


                        </div>
                    </Layout42>



                </div>
                <div className="section">
                    <h3 className="h-l">Input Helper</h3>
                    <Layout42Main>
                        <div className="p-m  bg-light rounded-l">
                            <InputField required
                                        onChange={getValue}
                                        type={"email"}
                                        inputRef={inputRef}
                                        inline
                                        placeholder={"Placeholder..."}
                                        label={"Email"}
                                        tooltip={"Your Email"}
                                        help={checkValid ? "" : "Please Enter Your email"}
                            />
                        </div>
                        <div className="text-m">
                            Một tooltip được thêm vào một input field <span className="code">
                                help={`{"Enter Your email"}`}
                            </span> có thể cung cấp thông tin bổ sung, giúp người dùng
                            hiểu rõ hơn về cách sử dụng hoặc ý nghĩa của trường input đó.    </div>

                    </Layout42Main>

                </div>
                <div className="section">
                    <h3 className="h-l">Text Area</h3>
                    <Layout42Main>
                        <div className="p-m  bg-light rounded-l">
                            <div className="gapy-l is-fill-x">
                                <div className="field has-info has-label-inline is-required">
                                    <div className="label">Label
                                    </div>

                                    <textarea className="is-fill-y" title="input" placeholder="Type something..."/>
                                </div>

                            </div>
                        </div>
                        <div className="text-m">
                            Nó là một công cụ mạnh mẽ cho việc nhập liệu dài hơn so với các trường input thông
                            thường, như khi người dùng cần nhập một đoạn văn bản, bình luận, hoặc nội dung mô tả.
                        </div>

                    </Layout42Main>


                </div>

                <div className="section  ">
                    <h3 className="h-l">Các trường Input khác</h3>
                    <Layout42 size={"l"} code={VariantInputString}>
                        <div className=" columns vertical">
                            {type.map((item, index) => {
                                return <div key={index} className={"field has-label-inline"}>
                                    <div className="label">{item.label}</div>
                                    <input type={item.type}/>
                                </div>
                            })}

                        </div>
                    </Layout42>


                </div>
                <div className="section  ">
                    <h3 className="h-l">Upload</h3>
                    <Layout42 size={"m"} code={UploadInputString}>

                        <div>
                            <FileInput smallSize={uploadSize === "s"} bigSize={uploadSize === "l"}/>
                        </div>
                        <div className="field has-label-inline">
                            <div className="label">Size</div>
                            <DuckSelect data={sizes} placeholder={"select size"} child={({item, index,}) => (
                                <div title={item.size} key={index} className={"p-s"} onClick={() => {
                                    setUploadSize(item.value)
                                }}>
                                    {item.size}
                                </div>
                            )}/>

                        </div>
                        <div className="jt-between is-flex ">
                            <div className="code">
                                {`size-${value}`} <br/>
                            </div>
                            <div className="is-flex gapx-xs jt-end">
                                <div onClick={() => setValue("s")}
                                     className={`btn btn-primary   ${value === 's' ? "" : "btn-outline"}`}>xs
                                </div>
                                <div onClick={() => setValue("")}
                                     className={`btn btn-primary   ${value === "" ? "" : "btn-outline"}`}>m
                                </div>
                                <div onClick={() => setValue("l")}
                                     className={`btn  btn-primary  ${value === 'l' ? "" : "btn-outline"}`}>l
                                </div>
                            </div>

                        </div>
                    </Layout42>

                </div>
                <div className="section">
                    <h3 className="h-l">Checkbox Variant</h3>
                    <div className="pt-xl  gap-l ">
                        <div className="text-m">
                            Checkbox thường được sử dụng để yêu cầu người dùng đồng ý với điều khoản và điều kiện.
                        </div>

                    </div>
                    <Layout42 size={"s"} code={CheckboxString}>
                        <div className={""}>
                            <label className="form-check">
                                <input type="checkbox" id="squaredcheck1" className="checkbox1" name="check"
                                />
                                <label htmlFor="squaredcheck1"><span>Checkbox</span></label>
                            </label>

                            <label className="form-check">
                                <input type="checkbox" id="squaredcheck2" className="checkbox1" name="check"
                                       checked/>
                                <label className={"checked"} htmlFor="squaredcheck2"><span>Checkbox</span></label>
                            </label>
                            <label className="form-check">
                                <input type="checkbox" id="squaredcheck2" className="checkbox1" name="check"
                                       checked={false}/>
                                <label className={"unchecked"} htmlFor="squaredcheck2"><span>Checkbox</span></label>
                            </label>
                        </div>
                    </Layout42>
                </div>


            </div>
        </div>
    )
}

const FileInput = ({bigSize, smallSize}) => {
    const [filePreview, setFilePreview] = useState();
    const [file, setFile] = useState();
    const [uploaded, setUpload] = useState(false)
    const [loading, setLoading] = useState(false)
    // handle drag events
    const inputRef = useRef(null);

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            setFilePreview(URL.createObjectURL(e.target.files[0]));
            setFile(e.target.files[0]);
        }
    };


// triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };
    const onXClick = () => {
        setLoading(false)
        setUpload(false)
        setFilePreview(undefined)
    }

    return <div className="field has-label-inline">
        <div className="label">Upload File</div>

        <div className="  form-file-upload ">
            <input ref={inputRef} accept="image/*" type="file" className="input-file-upload"
                   multiple={false}
                   onChange={handleChange}/>
            {filePreview === undefined ? <>
                <label htmlFor="input-file-upload"
                       className={`${bigSize ? "label-file-upload-l" : ""} label-file-upload ${smallSize ? "label-file-upload-s" : ""}`}>
                    <div className={`${smallSize ? "is-flex align-center" : ""} gap-xs`}>
                        <Icon className="text-xl text-sliver" icon="add"></Icon>
                        <p>Upload Image</p>
                        <p className="upload-button text-primary "
                           onClick={onButtonClick}>Browser</p>
                    </div>
                </label>
                <div className="is-flex jt-between p-s">
                    <p className={"text-xs text-disable"}>Supported image/*</p>
                    <p className={"text-xs text-disable"}>Maximum size: 12Mb</p>
                </div>

            </> : <div className={`file-upload ${loading ? 'on-upload' : ""}`}>
                {
                    uploaded === false ? <div className="loading">
                    </div> : ""
                }
                <img onClick={onButtonClick} className="border is-fill-y"
                     src={filePreview}/>
                <div onClick={onXClick}
                     className="size-xl  is-center rounded  p-xs  control-file-upload">
                    <Icon className="text-xxl text-light is-absolute"
                          icon="multiply"></Icon>
                </div>
            </div>}
        </div>
    </div>
}

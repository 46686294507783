import React, {useEffect, useRef, useState} from 'react';
import {ContextMenu} from "./TableContext";
import {Icon} from "../../DuckDesignSystem/Source/DuckIcon";
import {useDispatch, useSelector} from "react-redux";
import ReactDOM from "react-dom";
import {updateContent, updateImageRatio} from "../../../redux/Editor/editorSlice";


function ImageContext({context}) {
    const [menu, setMenu] = useState(false)
    const [isHover, setHover] = useState(false)
    const [ratio, setRatio] = useState("")
    const contentEditableRef = useRef(null);
    const dispatch = useDispatch();
    const imageContext = useSelector(state => state.editor.listContext[state.editor.listContext.findIndex(ct => ct.id === context.id)])
    const handleInputChange = (e) => {
        const element = ReactDOM.findDOMNode(contentEditableRef.current);
        dispatch(updateContent({id: context.id,updateContent: element.innerHTML}))
    };
    useEffect(() => {
        // Đặt vị trí con trỏ vào cuối sau mỗi cập nhật
        if (contentEditableRef.current)
            setEndOfContentEditable(contentEditableRef.current);
    }, [imageContext.content]);
    const setEndOfContentEditable = (contentEditableElement) => {
        var range, selection;
        if (document.createRange) {
            // IE 9+ và các trình duyệt khác
            range = document.createRange();
            range.selectNodeContents(contentEditableElement);
            range.collapse(false);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        } else if (document.selection) {
            // IE 8 và thấp hơn
            range = document.body.createTextRange();
            range.moveToElementText(contentEditableElement);
            range.collapse(false);
            range.select();
        }
    };
    const clickMenu = () => {
        setMenu(!menu)
    }
    const imageMenu = () => {
        return [{
            actionName: "Ratio 16-9",
            icon: "icon-image",
            action: function () {
                dispatch(updateImageRatio({id: context.id,ratio:"16-9"}))
            }
        },
            {
                actionName: "Ratio 9-16",
                icon: "icon-image",
                action: function () {
                    dispatch(updateImageRatio({id: context.id,ratio:"9-16"}))
                }
            },
            {
                actionName: "Ratio 21-9",
                icon: "icon-image",
                action: function () {
                    dispatch(updateImageRatio({id: context.id,ratio:"21-9"}))
                }
            }]
    }
    return (
        <div onMouseOver={() => setHover(true)} onMouseOut={() => {
            setHover(false)
        }}
             className={" bg-light is-relative p-s rounded-s"}>
            <div data-widget="image-widget" contentEditable={false}
                 className={`is-absolute `}
                 style={{top: "4px", left: "-24px", zIndex: 2}}>
                <div onClick={() => clickMenu()}
                     style={{display: `${isHover ? "flex" : "none"}`}}
                     className={`px-xs rounded-xs border  text-l icon icon-context-menu bg-white text-mute `}>
                </div>
                <div  className="is-absolute is-flex align-center p-xs rounded-xs border  text-xs  bg-white text-mute "  style={{bottom: "-6px", left: "34px", zIndex: 2,display: `${isHover ? "flex" : "none"}`}}>
                    <div className="">{imageContext.defaultState.image.url}</div>
                </div>
                {menu ?
                    <ContextMenu menu={imageMenu()}
                                 position={{top: 4, right: -250}}></ContextMenu> : null}
            </div>
            <img className={`rounded-s ratio-${imageContext.defaultState.image.ratio}`} src={imageContext.defaultState.image.url} alt=""/>
            <i ref={contentEditableRef} onInput={e=>handleInputChange(e)} contentEditable={true}  dangerouslySetInnerHTML={{ __html: imageContext.content}} className="is-fill-x "></i>
        </div>
    );
}


export default ImageContext;
import {base} from "../../../url";

const getAllCategory = () => {
    var requestOptions = {
        method: 'GET',
        credentials: "include",
        redirect: 'follow',
    };
    return fetch(`${base}/category/`, requestOptions).then((reponse) => reponse.json());
}
const getNewOfCategory = (categoryName, page) => {
    return fetch(`${base}/category/${categoryName}?page=${encodeURIComponent(page)}`).then((reponse) => reponse.json());
}
const createCategory = (categoryName) => {
    var formdata = new FormData();
    formdata.append("category", categoryName);
    var requestOptions = {
        method: 'POST',
        body: formdata,
        credentials: "include",
        redirect: 'follow'
    };

    fetch(`${base}/category/`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}
const deleteCategory = (categoryName) => {
    var formdata = new FormData();
    formdata.append("category", "cate");

    var requestOptions = {
        method: 'DELETE',
        body: formdata,
        credentials: "include",
        redirect: 'follow'
    };
    fetch(`${base}/api/category/`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

}
export {getNewOfCategory, createCategory, getAllCategory,deleteCategory};

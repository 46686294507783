/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 forest.glb --transform 
Files: forest.glb [8.89MB] > forest-transformed.glb [660.86KB] (93%)
*/
import * as THREE from "three";
import React, {useRef, useState} from 'react'
import {useGLTF, Plane, Billboard} from '@react-three/drei'
import {useFrame} from "@react-three/fiber";
import {easing} from "maath";
import modelPath from './Model/forest.glb'
export function Model(props) {
    const {nodes, materials} = useGLTF(modelPath)
    const ref = useRef(null)
    const camp1 = useRef(null)
    const camp2 = useRef(null)
    const camp3 = useRef(null)
    const camp4 = useRef(null)

    /* eslint-disable-next-line padded-blocks */

    useFrame((state, delta) => {

            ref.current.rotation.set(0, -Math.PI / 2, 0)
            const x = (-Math.sin(state.pointer.x / 4) * 9)
            const y = -(0.25 + 1.25 * state.pointer.y) 
            const z = (Math.cos(state.pointer.x / 4) * 9 - 6)
            easing.damp3(ref.current.position, [0, 0, 0], 1, 0.04)
            easing.damp3(ref.current.rotation, [(Math.PI / 2), (Math.PI / 2)- state.pointer.y, (1 / 5 )], 1, 0.03)
            easing.damp3(state.camera.position, props.pos, 1, 0.03)
            state.camera.lookAt(camp1.current.position.x, camp1.current.position.y, camp1.current.position.z)
            const t = state.clock.getElapsedTime()
            // ref.current.rotation.set(Math.cos(t / 4) / 8, Math.sin(t / 3) / 4, 0.15 + Math.sin(t / 2) / 8)
            // ref.current.position.y = (0.5 + Math.cos(t / 2)) / 7
    })
    return (
        <group ref={ref} {...props} dispose={null}>
            {/*for Controls*/}
            {/*<Billboard  position={[-4, 2, 0]}>*/}
            {/*</Billboard>*/}

            <mesh ref={camp1}
                  geometry={nodes.camp.geometry} material={materials.camp} position={[0.979, 0.432, 0.894]}/>

            <mesh ref={camp2}  geometry={nodes.camp002.geometry}
                  material={materials.camp} position={[1.229, 0.428, 4.633]}/>


            <mesh ref={camp3}  geometry={nodes.camp003.geometry}
                  material={materials.camp} position={[5.148, 0.432, 4.188]}/>


            <mesh ref={camp4}  geometry={nodes.camp001.geometry}
                  material={materials.camp} position={[4.728, 0.363, 2.15]}/>


            <group position={[2.407, 0.257, 0.035]}>
                <mesh geometry={nodes.Cube002.geometry} material={materials.car}/>
                <mesh geometry={nodes.Cube002_1.geometry} material={materials.banhxe}/>
                <mesh geometry={nodes.Cube002_2.geometry} material={materials.glass}/>
            </group>
            <mesh geometry={nodes.Cylinder002.geometry} material={materials.banhxe} position={[2.155, 0.152, -0.538]}/>
            <mesh geometry={nodes.Cylinder003.geometry} material={materials.banhxe} position={[2.844, 0.147, -0.268]}/>
            <mesh geometry={nodes.rook019.geometry} material={materials.rock} position={[1.742, 0.037, 0.048]}/>
            <mesh geometry={nodes.rook020.geometry} material={materials.rock} position={[2.953, -0.03, -0.054]}/>
            <mesh geometry={nodes.rook021.geometry} material={materials.rock} position={[2.372, -0.018, 0.21]}/>
            <group position={[0.51, 0.132, 2.843]}>
                <mesh geometry={nodes.Cylinder009.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder009_1.geometry} material={materials.rock}/>
            </group>
            <mesh geometry={nodes.Head002.geometry} material={materials.rock} position={[1.04, 0.092, 2.562]}/>
            <mesh geometry={nodes.NurbsPath001.geometry} material={materials['skull.002']}
                  position={[1.146, 0.018, 2.546]}/>
            <mesh geometry={nodes.NurbsPath002.geometry} material={materials.rock} position={[1.188, -0.023, 2.556]}/>
            <mesh geometry={nodes.NurbsPath003.geometry} material={materials.rock} position={[1.219, -0.024, 2.534]}/>
            <mesh geometry={nodes.NurbsPath004.geometry} material={materials.rock} position={[1.242, -0.004, 2.487]}/>
            <mesh geometry={nodes.NurbsPath005.geometry} material={materials.rock} position={[1.316, -0.02, 2.541]}/>
            <mesh geometry={nodes.NurbsPath006.geometry} material={materials.rock} position={[1.291, -0.021, 2.565]}/>
            <mesh geometry={nodes.NurbsPath007.geometry} material={materials.rock} position={[1.264, -0.021, 2.59]}/>
            <mesh geometry={nodes.NurbsPath008.geometry} material={materials.rock} position={[1.235, -0.019, 2.615]}/>
            <mesh geometry={nodes.Cube001.geometry} material={materials.rock} position={[1.235, 0.07, 2.567]}/>
            <group position={[1.411, 0.079, 0.711]}>
                <mesh geometry={nodes.Cylinder.geometry} material={materials['floor.001']}/>
                <mesh geometry={nodes.Cylinder_1.geometry} material={materials.rock}/>
                <mesh geometry={nodes.Cylinder_2.geometry} material={materials.co}/>
                <mesh geometry={nodes.Cylinder_3.geometry} material={materials.wood}/>
            </group>
            <mesh geometry={nodes.floor001.geometry} material={materials.floor} position={[-3.984, -0.025, 2.08]}/>
            <mesh geometry={nodes.floor002.geometry} material={materials.floor} position={[8.525, -0.028, 0.337]}/>

            <group position={[0.625, 0.023, 2.976]}>
                <mesh geometry={nodes.Cylinder140.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder140_1.geometry} material={materials.rock}/>
            </group>
            <mesh geometry={nodes.Head001.geometry} material={materials.rock} position={[-0.219, 0.05, 6.159]}/>
            <mesh geometry={nodes.Head003.geometry} material={materials.rock} position={[-0.026, 0.05, 6.061]}/>

            <group position={[-3.887, 0.475, -1.329]}>
                <mesh geometry={nodes.Cylinder013.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder013_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder013_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder013_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-5.316, 0.87, -3.503]}>
                <mesh geometry={nodes.Cylinder143.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder143_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder143_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder143_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-2.735, 0.706, -3.417]}>
                <mesh geometry={nodes.Cylinder144.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder144_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder144_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder144_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-3.657, 0.459, -3.574]}>
                <mesh geometry={nodes.Cylinder145.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder145_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder145_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder145_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-2.046, 0.413, 3.872]}>
                <mesh geometry={nodes.Cylinder146.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder146_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder146_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder146_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-0.09, 0.723, 6.88]}>
                <mesh geometry={nodes.Cylinder147.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder147_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder147_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder147_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-2.353, 0.452, -3.861]}>
                <mesh geometry={nodes.Cylinder148.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder148_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder148_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder148_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-1.165, 0.84, 1.138]}>
                <mesh geometry={nodes.Cylinder149.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder149_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder149_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder149_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-0.058, 0.372, 0.138]}>
                <mesh geometry={nodes.Cylinder150.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder150_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder150_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder150_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[11.123, 0.426, 1.819]}>
                <mesh geometry={nodes.Cylinder151.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder151_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder151_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder151_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[5.646, 0.468, 1.253]}>
                <mesh geometry={nodes.Cylinder152.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder152_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder152_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder152_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[1.053, 0.892, -0.24]}>
                <mesh geometry={nodes.Cylinder153.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder153_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder153_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder153_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-0.225, 0.382, 8.19]}>
                <mesh geometry={nodes.Cylinder154.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder154_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder154_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder154_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-1.293, 0.329, 0.452]}>
                <mesh geometry={nodes.Cylinder155.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder155_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder155_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder155_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[7.104, 1.038, 6.968]}>
                <mesh geometry={nodes.Cylinder156.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder156_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder156_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder156_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-2.103, 1.026, 2.228]}>
                <mesh geometry={nodes.Cylinder157.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder157_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder157_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder157_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[0.208, 0.557, 4.455]}>
                <mesh geometry={nodes.Cylinder158.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder158_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder158_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder158_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[6.913, 0.926, -2.595]}>
                <mesh geometry={nodes.Cylinder159.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder159_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder159_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder159_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[6.749, 0.64, 2.282]}>
                <mesh geometry={nodes.Cylinder160.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder160_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder160_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder160_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-2.392, 0.842, 5.172]}>
                <mesh geometry={nodes.Cylinder161.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder161_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder161_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder161_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[8.714, 1.054, 0.653]}>
                <mesh geometry={nodes.Cylinder162.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder162_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder162_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder162_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[6.189, 0.757, 0.417]}>
                <mesh geometry={nodes.Cylinder163.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder163_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder163_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder163_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[10.381, 0.694, 8.969]}>
                <mesh geometry={nodes.Cylinder164.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder164_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder164_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder164_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[12.92, 0.454, 2.485]}>
                <mesh geometry={nodes.Cylinder165.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder165_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder165_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder165_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-5.624, 0.821, 7.728]}>
                <mesh geometry={nodes.Cylinder166.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder166_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder166_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder166_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-10.092, 0.409, -2.977]}>
                <mesh geometry={nodes.Cylinder167.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder167_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder167_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder167_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-8.571, 0.469, -3.429]}>
                <mesh geometry={nodes.Cylinder168.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder168_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder168_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder168_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-4.397, 1.025, -0.487]}>
                <mesh geometry={nodes.Cylinder169.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder169_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder169_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder169_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-0.181, 0.57, -4.652]}>
                <mesh geometry={nodes.Cylinder170.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder170_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder170_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder170_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-1.983, 0.943, 3.543]}>
                <mesh geometry={nodes.Cylinder171.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder171_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder171_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder171_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-0.622, 0.841, 4.038]}>
                <mesh geometry={nodes.Cylinder172.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder172_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder172_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder172_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-3.4, 1.002, 2.014]}>
                <mesh geometry={nodes.Cylinder173.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder173_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder173_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder173_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-4.303, 0.801, 7.497]}>
                <mesh geometry={nodes.Cylinder174.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder174_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder174_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder174_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-0.872, 0.777, 4.565]}>
                <mesh geometry={nodes.Cylinder175.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder175_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder175_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder175_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-3.121, 0.671, 7.71]}>
                <mesh geometry={nodes.Cylinder176.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder176_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder176_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder176_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[1.399, 0.48, -1.928]}>
                <mesh geometry={nodes.Cylinder177.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder177_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder177_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder177_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-1.704, 0.967, -3.568]}>
                <mesh geometry={nodes.Cylinder178.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder178_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder178_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder178_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-1.166, 0.651, -4.79]}>
                <mesh geometry={nodes.Cylinder179.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder179_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder179_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder179_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[7.285, 0.376, 6.788]}>
                <mesh geometry={nodes.Cylinder180.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder180_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder180_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder180_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[5.049, 0.343, 0.384]}>
                <mesh geometry={nodes.Cylinder181.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder181_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder181_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder181_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[0.929, 0.524, 6.788]}>
                <mesh geometry={nodes.Cylinder182.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder182_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder182_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder182_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[0.704, 0.431, -3.645]}>
                <mesh geometry={nodes.Cylinder183.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder183_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder183_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder183_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[0.64, 0.63, -1.198]}>
                <mesh geometry={nodes.Cylinder184.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder184_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder184_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder184_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[9.017, 0.612, -5.407]}>
                <mesh geometry={nodes.Cylinder185.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder185_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder185_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder185_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[10.007, 1.055, 3.31]}>
                <mesh geometry={nodes.Cylinder186.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder186_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder186_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder186_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-5.19, 0.456, 7.986]}>
                <mesh geometry={nodes.Cylinder187.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder187_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder187_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder187_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[9.697, 0.691, 4.779]}>
                <mesh geometry={nodes.Cylinder188.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder188_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder188_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder188_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[11.191, 1.02, 6.744]}>
                <mesh geometry={nodes.Cylinder189.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder189_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder189_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder189_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-10.561, 0.771, -0.714]}>
                <mesh geometry={nodes.Cylinder190.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder190_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder190_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder190_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-2.867, 0.55, -0.608]}>
                <mesh geometry={nodes.Cylinder191.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder191_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder191_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder191_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-8.35, 0.455, -4.976]}>
                <mesh geometry={nodes.Cylinder192.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder192_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder192_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder192_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-7.124, 0.999, -3.099]}>
                <mesh geometry={nodes.Cylinder193.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder193_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder193_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder193_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-3.965, 0.986, -4.821]}>
                <mesh geometry={nodes.Cylinder194.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder194_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder194_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder194_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-0.838, 0.636, -1.888]}>
                <mesh geometry={nodes.Cylinder195.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder195_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder195_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder195_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-3.496, 0.547, 6.79]}>
                <mesh geometry={nodes.Cylinder196.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder196_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder196_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder196_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[2.098, 0.798, -2.236]}>
                <mesh geometry={nodes.Cylinder197.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder197_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder197_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder197_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[1.637, 0.361, 7.205]}>
                <mesh geometry={nodes.Cylinder198.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder198_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder198_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder198_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-1.344, 0.8, 7.869]}>
                <mesh geometry={nodes.Cylinder199.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder199_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder199_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder199_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[8.003, 0.584, 2.981]}>
                <mesh geometry={nodes.Cylinder200.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder200_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder200_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder200_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-2.102, 0.821, -0.184]}>
                <mesh geometry={nodes.Cylinder201.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder201_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder201_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder201_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[0.164, 1.001, 2.148]}>
                <mesh geometry={nodes.Cylinder202.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder202_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder202_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder202_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[6.168, 0.782, 6.166]}>
                <mesh geometry={nodes.Cylinder203.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder203_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder203_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder203_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[9.773, 0.707, 2.185]}>
                <mesh geometry={nodes.Cylinder204.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder204_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder204_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder204_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[0.846, 0.487, -0.619]}>
                <mesh geometry={nodes.Cylinder205.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder205_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder205_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder205_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[4.365, 0.904, -5.343]}>
                <mesh geometry={nodes.Cylinder206.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder206_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder206_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder206_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-4.739, 0.939, 4.903]}>
                <mesh geometry={nodes.Cylinder207.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder207_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder207_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder207_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[7.646, 0.965, -2.78]}>
                <mesh geometry={nodes.Cylinder208.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder208_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder208_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder208_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[7.404, 0.845, 0.608]}>
                <mesh geometry={nodes.Cylinder209.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder209_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder209_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder209_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[8.399, 0.871, 7.713]}>
                <mesh geometry={nodes.Cylinder210.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder210_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder210_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder210_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[7.631, 0.751, 4.031]}>
                <mesh geometry={nodes.Cylinder211.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder211_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder211_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder211_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[11.337, 0.539, -3.187]}>
                <mesh geometry={nodes.Cylinder212.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder212_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder212_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder212_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[6.801, 0.541, -1.45]}>
                <mesh geometry={nodes.Cylinder213.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder213_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder213_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder213_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-8.121, 0.706, 3.169]}>
                <mesh geometry={nodes.Cylinder214.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder214_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder214_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder214_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-5.781, 1.05, 6.554]}>
                <mesh geometry={nodes.Cylinder215.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder215_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder215_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder215_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-1.177, 0.842, -5.127]}>
                <mesh geometry={nodes.Cylinder216.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder216_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder216_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder216_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-5.964, 0.53, 5.08]}>
                <mesh geometry={nodes.Cylinder217.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder217_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder217_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder217_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-0.478, 0.892, 1.085]}>
                <mesh geometry={nodes.Cylinder218.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder218_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder218_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder218_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-2.424, 1.013, 6.795]}>
                <mesh geometry={nodes.Cylinder219.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder219_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder219_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder219_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-1.508, 0.794, -1.353]}>
                <mesh geometry={nodes.Cylinder220.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder220_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder220_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder220_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-1.569, 0.692, -6.334]}>
                <mesh geometry={nodes.Cylinder221.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder221_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder221_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder221_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-2.91, 0.473, 6.882]}>
                <mesh geometry={nodes.Cylinder222.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder222_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder222_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder222_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-1.119, 0.467, -5.826]}>
                <mesh geometry={nodes.Cylinder223.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder223_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder223_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder223_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-0.989, 0.916, 7.099]}>
                <mesh geometry={nodes.Cylinder224.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder224_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder224_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder224_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[11.371, 1.022, 2.995]}>
                <mesh geometry={nodes.Cylinder225.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder225_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder225_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder225_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[1.56, 0.967, -2.976]}>
                <mesh geometry={nodes.Cylinder226.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder226_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder226_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder226_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[6.409, 0.496, 1.521]}>
                <mesh geometry={nodes.Cylinder227.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder227_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder227_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder227_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-0.014, 0.733, -0.342]}>
                <mesh geometry={nodes.Cylinder228.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder228_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder228_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder228_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[8.515, 0.862, 4.603]}>
                <mesh geometry={nodes.Cylinder229.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder229_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder229_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder229_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[7.078, 0.567, 6.084]}>
                <mesh geometry={nodes.Cylinder230.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder230_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder230_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder230_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[8.416, 0.556, 5.752]}>
                <mesh geometry={nodes.Cylinder231.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder231_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder231_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder231_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[3.892, 0.59, -4.528]}>
                <mesh geometry={nodes.Cylinder232.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder232_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder232_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder232_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[9.444, 0.762, -0.493]}>
                <mesh geometry={nodes.Cylinder233.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder233_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder233_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder233_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[4.133, 0.897, -3.562]}>
                <mesh geometry={nodes.Cylinder234.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder234_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder234_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder234_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[10.468, 0.438, 2.398]}>
                <mesh geometry={nodes.Cylinder235.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder235_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder235_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder235_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[8.683, 0.35, 7.333]}>
                <mesh geometry={nodes.Cylinder236.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder236_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder236_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder236_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[12.623, 0.771, 3.763]}>
                <mesh geometry={nodes.Cylinder237.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder237_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder237_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder237_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <group position={[-7.065, 1.025, 2.666]}>
                <mesh geometry={nodes.Cylinder242.geometry} material={materials['tree-leaf']}/>
                <mesh geometry={nodes.Cylinder242_1.geometry} material={materials.wood}/>
                <mesh geometry={nodes.Cylinder242_2.geometry} material={materials['tree-leaf.001']}/>
                <mesh geometry={nodes.Cylinder242_3.geometry} material={materials['tree-leaf.002']}/>
            </group>
            <mesh geometry={nodes.rook.geometry} material={materials.rock} position={[2.035, 0.065, 1.503]}/>
            <mesh geometry={nodes.rook010.geometry} material={materials.rock} position={[1.803, 0.083, 1.41]}/>
            <mesh geometry={nodes.rook009.geometry} material={materials.rock} position={[1.693, 0.09, 1.237]}/>
            <mesh geometry={nodes.rook008.geometry} material={materials.rock} position={[1.704, 0.087, 0.998]}/>
            <mesh geometry={nodes.rook007.geometry} material={materials.rock} position={[1.844, 0.081, 0.842]}/>
            <mesh geometry={nodes.rook006.geometry} material={materials.rock} position={[1.991, 0.072, 0.791]}/>
            <mesh geometry={nodes.rook005.geometry} material={materials.rock} position={[2.121, 0.063, 0.805]}/>
            <mesh geometry={nodes.rook004.geometry} material={materials.rock} position={[2.252, 0.053, 0.882]}/>
            <mesh geometry={nodes.rook003.geometry} material={materials.rock} position={[2.341, 0.048, 1.008]}/>
            <mesh geometry={nodes.rook002.geometry} material={materials.rock} position={[2.374, 0.045, 1.206]}/>
            <mesh geometry={nodes.rook001.geometry} material={materials.rock} position={[2.275, 0.052, 1.37]}/>
            <mesh geometry={nodes.rook014.geometry} material={materials.rock} position={[2.368, 0.041, 0.904]}/>
            <mesh geometry={nodes.rook012.geometry} material={materials.rock} position={[2.194, -0.033, 1.486]}/>
            <mesh geometry={nodes.rook013.geometry} material={materials.rock} position={[2.403, -0.036, 1.328]}/>
            <mesh geometry={nodes.rook011.geometry} material={materials.rock} position={[2.255, 0.007, 1.474]}/>
            <mesh geometry={nodes.wood001.geometry} material={materials.wood} position={[2.093, 0.123, 1.047]}/>
            <mesh geometry={nodes.wood003.geometry} material={materials.wood} position={[1.898, 0.143, 1.06]}/>
            <mesh geometry={nodes.wood002.geometry} material={materials.wood} position={[2.013, 0.047, 1.181]}/>
            <mesh geometry={nodes.wood.geometry} material={materials.wood} position={[2.163, 0.073, 1.192]}/>
            <mesh geometry={nodes.rook015.geometry} material={materials.rock} position={[1.511, 0.013, 3.728]}/>
            <mesh geometry={nodes.rook016.geometry} material={materials.rock} position={[-0.094, 0.174, 2.746]}/>
            <mesh geometry={nodes.rook018.geometry} material={materials.rock} position={[1.09, 0.067, 0.166]}/>
            <mesh geometry={nodes.rook022.geometry} material={materials.rock} position={[1.575, -0.051, 1.11]}/>
            <mesh geometry={nodes.rook023.geometry} material={materials.rock} position={[1.277, 0.17, 3.368]}/>
            <mesh geometry={nodes.rook024.geometry} material={materials.rock} position={[4.617, 0.101, 1.124]}/>
            <mesh geometry={nodes.rook025.geometry} material={materials.rock} position={[4.62, 0.207, 0.622]}/>
            <mesh geometry={nodes.rook026.geometry} material={materials.rock} position={[0.909, 0.131, 3.686]}/>
            <mesh geometry={nodes.rook017.geometry} material={materials.rock} position={[4.137, 0.065, 3.991]}/>
            <mesh geometry={nodes.rook027.geometry} material={materials.rock} position={[3.904, 0.083, 3.898]}/>
            <mesh geometry={nodes.rook028.geometry} material={materials.rock} position={[3.794, 0.09, 3.725]}/>
            <mesh geometry={nodes.rook029.geometry} material={materials.rock} position={[3.806, 0.087, 3.485]}/>
            <mesh geometry={nodes.rook030.geometry} material={materials.rock} position={[3.945, 0.081, 3.33]}/>
            <mesh geometry={nodes.rook031.geometry} material={materials.rock} position={[4.093, 0.072, 3.279]}/>
            <mesh geometry={nodes.rook032.geometry} material={materials.rock} position={[4.223, 0.063, 3.293]}/>
            <mesh geometry={nodes.rook033.geometry} material={materials.rock} position={[4.354, 0.053, 3.37]}/>
            <mesh geometry={nodes.rook034.geometry} material={materials.rock} position={[4.443, 0.048, 3.496]}/>
            <mesh geometry={nodes.rook035.geometry} material={materials.rock} position={[4.476, 0.045, 3.694]}/>
            <mesh geometry={nodes.rook036.geometry} material={materials.rock} position={[4.376, 0.052, 3.858]}/>
            <mesh geometry={nodes.rook037.geometry} material={materials.rock} position={[4.47, 0.041, 3.392]}/>
            <mesh geometry={nodes.rook038.geometry} material={materials.rock} position={[4.296, -0.033, 3.974]}/>
            <mesh geometry={nodes.rook039.geometry} material={materials.rock} position={[4.505, -0.036, 3.816]}/>
            <mesh geometry={nodes.rook040.geometry} material={materials.rock} position={[4.357, 0.007, 3.962]}/>
            <mesh geometry={nodes.wood005.geometry} material={materials.wood} position={[4.195, 0.123, 3.535]}/>
            <mesh geometry={nodes.wood007.geometry} material={materials.wood} position={[4, 0.143, 3.548]}/>
            <mesh geometry={nodes.wood008.geometry} material={materials.wood} position={[4.115, 0.047, 3.669]}/>
            <mesh geometry={nodes.wood009.geometry} material={materials.wood} position={[4.265, 0.073, 3.679]}/>
            <mesh geometry={nodes.rook041.geometry} material={materials.rock} position={[3.677, -0.051, 3.598]}/>
        </group>
    )
}

useGLTF.preload('/forest.glb')

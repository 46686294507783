import React, {forwardRef, useEffect, useRef} from 'react';
import ToolBar from "./Toolbar";
import Editable from "./Editatble";

const Editor = ({width, height}) => {
    const editorHeight = height || 420
    const editorWidth = width || 640

    return (

        <div className="editor is-relative  is-overflow bg-white "
             style={{width: editorWidth, minHeight: editorHeight}}>
            <ToolBar></ToolBar>
            <Editable ></Editable>
        </div>


    );
}

export default Editor;




import React, {useState} from "react";
import {SlideElement} from "../../Duck/DuckDesignSystem/animate/AnimateElement";
import faticon from "../../static/ui/faticon.png";
import styled, {keyframes} from "styled-components";
import { ThreeDDescription, UiUxDescription} from "../../slide/Item";
import Work from "../Work";
import Other from "../other";
import {Link} from "react-router-dom";
import {Icon} from "../../Duck/DuckDesignSystem/Source/DuckIcon";
import GsapCarousel from "../GsapCarousel";

const expand = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 400px;
  }
`;
const collapse = keyframes`
  from {
    max-height: 400px;
  }
  to {
    max-height: 0;
  }
`;
const EL = styled.div`
  overflow: hidden;
  animation: ${(props) => (props.active ? expand : collapse)} 0.4s linear;
  max-height: ${(props) => (props.activeState ? "400px" : "0px")};
`;
const DesignElement = () => {
    const [activeState, setActiveState] = useState(1);

    const Element = ({refTo}) => {
        const [active, setActive] = useState(1);
        const [hoverState, setHoverState] = useState(false);
        const handleClick = async () => {
            if (active === 1) {
                setActive(0);
                await new Promise((r) => setTimeout(r, 400));
                setActiveState(activeState === refTo ? 0 : refTo);
            } else {
                setActiveState(activeState === refTo ? 0 : refTo);
                await new Promise((r) => setTimeout(r, 400));
                setActive(1);
            }
        };
        const handleHover = (x) => {
            setHoverState(x);
        };
        return (
            <div
                id="theElement"
                onClick={handleClick}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
                className={`card rounded-m ${
                    refTo % 2 ? "bg-white" : null
                } cursor-pointer p-s`}
            >
                <div className={"card-content"}>
                    <div className="columns l-vertical">
                        <div className="col-6 is-flex gap-xs">
              <span
                  className="icon icon-arrow-right"
                  style={{display: `${hoverState ? "block" : "none"} `}}
              ></span>
                            <span className="text-s">is</span>
                            <span className="h-xs">Personal Project</span>
                        </div>
                        <div className="col-3"></div>
                        <div className="col-3 is-flex  align-center">
                            <div className="text-s">Building website</div>
                        </div>
                    </div>

                    {activeState === refTo ? (
                        <EL
                            activeState={activeState}
                            active={active}
                            className="columns l-vertical pt-l"
                        >
                            <span className=" size-l "></span>

                            <div className="col-2">
                                The href attribute requires a valid value to be accessible.
                                Provide a valid, navigable address as the href value. If you
                                cannot provide a valid href, but still need the element to
                                resemble a link, use a button and change it with appropriate
                                styles.
                            </div>
                            <div className="col-2"></div>
                            <div className="col">
                                <img src={faticon} alt="" className="ratio-4-3 rounded-s"/>
                            </div>
                        </EL>
                    ) : null}
                    <div className="divider"></div>
                </div>
            </div>
        );
    };
    return (
        <>
            <div className="hero is-half is-flex  jt-center" style={{}}>
                <div className=" text-center pb-xxl">
                    <div className="hi-5xl text-bold hi-uppercase ">D.esign</div>
                    <div className="text-l hi-text-semi">
                        I loves to translate user story into practice
                    </div>
                </div>
            </div>
            <div className=" is-block container-xl ">
                <div className="text-l ">
                    <SlideElement direction>
                        <span className="text-s text-bold"># </span>
                        <span className="text-l">Work experience</span>
                    </SlideElement>
                </div>
                <div className="columns ">
                    <h1 className="h-xxl ">Work experience</h1>
                </div>
                <div className="Main is-relative">
                    <Work></Work>
                </div>
                <div className="columns layout-1-1 s-vertical">
                    <div className="col text-xs text-darkgray pt-l">
                        Shift + scroll timeline schedule to view all elements.
                        <br/>Hover an element to see description.
                    </div>
                    <div className="col"></div>
                </div>

            </div>
            <div className=" is-block container-xl ">


                <div className="columns layout-1-1 s-vertical">
                    <div className="col text-xs text-darkgray pt-l">
                        Shift + scroll timeline schedule to view all elements.
                        <br/>Hover an element to see description.
                    </div>
                    <div className="col"></div>
                </div>

            </div>
            <div className="section container-xl ">
                <UIUXSection/>
                <ThreeDVisual/>
                <Other/>
            </div>
        </>
    );
};

export {DesignElement};

const WorkSection = ({WorkDescription}) => {
    return <>
        <div className=" hero  font-text is-flex align-end  mt-xxl">
            <div className=" columns is-fill-x l-vertical layout-1-1 s-vertical-revert mt-xxl">
                <div className="col mt-xxl ">
                    <div className="text-l ">
                        <SlideElement>
                            <span id={WorkDescription.id} className="text-s text-bold"># </span>
                            <span className="text-l">{WorkDescription.title}</span>
                        </SlideElement>
                    </div>
                    <div
                        className="col is-block hi-l text-bold text-sliver is-relative"
                        style={{zIndex: 0}}
                    >
                        <SlideElement>
                            <div className="text-black">
                                {WorkDescription.description}
                            </div>
                        </SlideElement>

                    </div>
                </div>
                <div className="col">

                </div>
            </div>
        </div>

        <div className="   grid-3 grid-l-2 grid-xs-1 gap-s l-vertical mt-l">
            {WorkDescription.products.map((item, index) => {
                return <ProductCard product={item} key={index}></ProductCard>
            })}
        </div>
        <div className="columns layout-1-1 s-vertical">
            <div className="col text-xs text-darkgray pt-l">
                {WorkDescription.content}
            </div>
            <div className="col"></div>
        </div>

    </>
}
const ProductCard = ({product}) => {
    return <div  className=" col  product-card card vertical rounded-l border">
        <div className=" card-img  is-relative product-hover">
            <div className=" columns vertical gap-l  p-l">
                <div className="col  columns gap-s">
                    {product.tag.map((item, index) => {
                        return <div key={index} className="chips">#{item.tag}</div>
                    })}
                </div>
                <div className="col ">
                    <img src={product.logo} alt="" className={" size-y-s-x2"}/>
                </div>
            </div>
        </div>
        <Link to={product.refTo} target="_blank" className="card-content product-content p-m">
            <div className={"px-m gapy-xs"}>
                <div className="text-xs">{product.exp}</div>
                <h2>{product.title}</h2>
            </div>
            <div className="is-flex bg-blur  py-s pl-l pr-s jt-between align-center">
                <div className=" gap-xs">
                    <span className="text-s">is</span>
                    <a className="title-s link-hover">{product.category} </a>
                </div>
                <div className="btn-icon">
                    <Icon className={"text-xl"} icon={"arrow-right "}/>
                </div>
            </div>

        </Link>
    </div>
}
export {ProductCard}
const UIUXSection = () => {
    return <>
        <div id="ui" className="section">
            <WorkSection WorkDescription={UiUxDescription}></WorkSection>
        </div>
    </>
}
const ThreeDVisual = () => {
    return (
        <>
            <div id="threeD" className="section">
                <WorkSection WorkDescription={ThreeDDescription}></WorkSection>
            </div>
        </>
    );
};
// const AnimationSection = () => {
//     return (
//         <div id="animation" className="section ">
//             <WorkSection WorkDescription={AnimationDescription}></WorkSection>
//         </div>
//     );
// };

import {useNavigate, useSearchParams} from "react-router-dom";
import {Icon} from "../../Duck/DuckDesignSystem/Source/DuckIcon";

const Pagination = ({total,limit,setPage,setLoading}) => {
    const [searchParams] = useSearchParams();
    const currentPage = parseInt(searchParams.get("page")) || 1
    const indents = [];
    for (let i = 0; i < total; i++) {
        indents.push(
            i + 1 === currentPage ?
                <li><a className="pagination-link  is-current" aria-label="Goto page">{i + 1}</a></li> :
                <li><a onClick={() => {
                    setPage(i + 1)
                    setLoading(true)
                }} className="pagination-link bg-hover-light " aria-label="Goto page">{i + 1}</a>
                </li>
        )
    }
    return <nav className="pagination " role="navigation" aria-label="pagination">
        <div className="pag-left"> 1-{limit} of {total}</div>
        <div className="pag-right">
            <ul className="pagination-list">
                {indents}
            </ul>
            {currentPage === 1 ? <a className="pagination-previous is-disabled">
                    <Icon icon={"arrow-left"}></Icon>
                </a>
                :
                <a onClick={() => {
                    setPage(parseInt(currentPage - 1))
                    setLoading(true)
                }} className="pagination-previous">Trước</a>}
            {currentPage === total ? <a className="pagination-previous is-disabled"><Icon icon={"arrow-right "}/></a> :
                <a onClick={() => {
                    setPage(parseInt(currentPage - (-1)))
                    setLoading(true)
                }} className="pagination-next"><Icon
                    icon={"arrow-right "}/> </a>}
        </div>


    </nav>
}
export default Pagination
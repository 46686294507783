import React from 'react';

function TableContextRender({context}) {
    const tableContext = context
    return (
        <div className="table-section  is-relative">
            <table className={`table `}>
                <thead>
                <tr>
                    {tableContext.content[0].map((cellData, cell) => {
                        return (
                            <th key={cell} className="cell-editor">
                                <div dangerouslySetInnerHTML={{__html: cellData}}
                                     className="is-fill-x p-s supeenty"></div>
                            </th>
                        )
                    })}
                </tr>
                </thead>
                <tbody>{tableContext.content.slice(1).map((rowData, row) => (
                    <tr key={row}>{rowData.map((cellData, cell) => (
                        <td key={cell}
                            className="cell-editor is-relative ">
                            <div dangerouslySetInnerHTML={{__html: cellData}} className="is-fill-x p-s supeenty"></div>
                        </td>
                    ))}</tr>
                ))}</tbody>
            </table>
        </div>
    );
}

export default TableContextRender;
import React, {useEffect, useState} from "react";
import {Canvas} from "@react-three/fiber";
import {ContactShadows, PerformanceMonitor,} from "@react-three/drei";
import {Model} from "./Skull";
import CustomCursor from "../../src/Duck/DuckDesignSystem/Source/Support/CustomCursor"
import {DesignElement} from "./Product/DesignElement";
import {DevelopElement} from "./Product/DevelopElement";
import {ExpandElement, SlideElement,} from "../Duck/DuckDesignSystem/animate/AnimateElement";
import {useLocation} from "react-router-dom";
import {Icon} from "../Duck/DuckDesignSystem/Source/DuckIcon";

function ThreeD(props) {
    const [perfSucks, degrade] = useState(false);
    const [flag, setFlag] = useState(0);
    const [collapse, setCollapse] = useState(true);
    const location = useLocation();
    const handleClick = async (value) => {
        await new Promise((r) => setTimeout(r, 500)).then(degrade(!perfSucks));
        if (value !== 0) {
            setFlag(value)
        } else {
            setFlag(0)
        }
    };
    useEffect(() => {
        if (flag !== 0) {
            if (window.location.href.indexOf("#Design") > -1) {
                // Thay đổi đường link bằng cách loại bỏ phần "#Design"
                window.location.href = window.location.href.replace("#Design", "");
            }
            if (window.location.href.indexOf("#Develop") > -1) {
                // Thay đổi đường link bằng cách loại bỏ phần "#Design"
                window.location.href = window.location.href.replace("#Develop", "");
            }
        }

    }, []);
    useEffect(() => {
        if (location.pathname !== "/duck") setCollapse(false)
        const {hash} = location;
        if (hash) {
            // Loại bỏ ký tự '#' từ fragment
            const targetId = hash.substring(1);
            console.log(targetId)
            if (targetId === "Design") {
                degrade(true)
                setFlag(-1)
            }
            if (targetId === "Develop") {
                degrade(true)
                setFlag(1)
            }
            // Nếu tìm thấy, cuộn đến phần tử đó
        }
    }, [location])
    useEffect(() => {
        document.title = "Work của Hi nè";
    })
    useEffect(() => {
        const navbar = document.getElementById("bottomMenu");
        if (perfSucks) {
            navbar.style.display = "none";
        } else {
            navbar.style.display = "flex";
        }
    }, [perfSucks]);

    const Elementor = () => {


        return (<>
            {flag !== 0 ? (<div style={{
                transform: "translate(0,-150px)",
            }}
                                className={` toggle-animate  ${perfSucks ? animate.default : animate.toggle}`}
            >
                <div>{flag === 1 ? <DevelopElement/> : <DesignElement/>}</div>
            </div>) : null}

            {perfSucks ? (<BottomMenu handleClick={handleClick} flag={flag}></BottomMenu>) : null}
        </>);
    };
    const animate = {
        default: "open", toggle: "close",
    };
    return (<div className="   is-relative "
                 style={{minHeight: "100vh"}}>
        {/*<CustomCursor></CustomCursor>*/}
        <div
            className="pt-xxl ">   {perfSucks ? null : <div className="container-xl is-absolute " style={{
            zIndex: 0, bottom: "75px", left: " 50%", transform: "translateX(-50%)"
        }}>
            <div className="  align-start jt-center   ">




            </div>
        </div>}

            <Canvas id="skull" eventPrefix="client" camera={{position: [0, 0, 0], fov: 60}}
                    style={{
                        height: perfSucks ? '600px' : '600px', width: "auto", transition: " height 1.5s linear"
                    }}
            >
                <fog attach="fog" args={['black', 15, 21.5]}/>
                <PerformanceMonitor onDecline={() => degrade(true)}/>
                <Model perfSucks={perfSucks}></Model>
                <ContactShadows
                    resolution={512}
                    position={[0, -1.0, 0]}
                    opacity={0.6}
                    scale={10}
                    blur={2}
                    far={1}
                />
                <pointLight position={[0, 0, 8]}/>
            </Canvas>
        </div>
        {perfSucks ? null :
            <div className="container-full  border rounded-x  is-absolute " style={{bottom: "0", height: "75px"}}>
                <div className="container-xl grid-2 wrapper is-fill-y ">
                    <a href={"#Design"} onClick={() => handleClick(-1)}
                       className="col  border-r text-bold is-center hi-xl px-xxl cursor-pointer">Design</a>
                    <a href={"#Develop"} onClick={() => handleClick(1)}
                       className="col is-center text-bold hi-xl px-xxl cursor-pointer">Develop</a>
                </div>


            </div>}

        {flag !== 0 ? <Elementor/> : null}
    </div>);
}


function BottomMenu({handleClick, flag}) {

    const scrollToElement = (id) => {
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth'
        })
    }
    const [menuExpand, setMenuExpand] = useState(false);
    return (<div className={`bottom-menu is-flex gap-xs font-text  ${menuExpand ? "menu-expand" : "menu-collapse"}`}>
        <div className="p-xs rounded-s menu-right is-flex gap-xs  bg-mute">
            <a href={""}
               onClick={() => handleClick(0)}
               className="btn btn-outline btn-l text-white">
                <div className="icon icon-arrow-left"></div>
            </a>
            <h4 className="h-m text-white text-bold">.to Work</h4>
        </div>

        <ExpandElement className="p-xs rounded-s text-white gap-xs  items  bg-darkgray"
        >
            <div className="item p-xs is-flex jt-between is-fill-x">
                <div className="text-bold text-l">
                    {flag === -1 ? "De.sign" : "De.velop"}
                </div>
                {flag === -1 ?
                    <div onClick={() => setMenuExpand(!menuExpand)} className="menu-collapse-button text-xl ml-m">
                        {menuExpand ? <div className=" icon icon-menu-close"></div> :
                            <div className=" icon icon-menu"></div>}
                    </div> : null}

            </div>
            {flag === -1 ? <>
                <div className="item item-collapse">
                    <SlideElement direction={!menuExpand} delay={200}>
                        <div
                            onClick={() => scrollToElement("ui")}
                            className="btn btn-outline btn-l text-white is-fill-x"
                        >
                            UIUX
                        </div>
                    </SlideElement>
                </div>
                <div className="item item-collapse">
                    <SlideElement direction={!menuExpand} delay={700}>
                        <div
                            onClick={() => scrollToElement("threeD")}
                            className="btn btn-outline btn-l text-white is-fill-x"
                        >
                            3D Visualization
                        </div>
                    </SlideElement>
                </div>
                {/*<div className="item item-collapse">*/}
                {/*    <SlideElement direction={!menuExpand} delay={1200}>*/}
                {/*        <div*/}
                {/*            onClick={() => scrollToElement("animation")}*/}
                {/*            className="btn btn-outline btn-l text-white is-fill-x"*/}
                {/*        >*/}
                {/*            Animation*/}
                {/*        </div>*/}
                {/*    </SlideElement>*/}
                {/*</div>*/}
            </> : ""}

        </ExpandElement>

    </div>);
}

export {ThreeD};

import {configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Import loại lưu trữ bạn muốn sử dụng
import authReducer from './Auth/authSlice';
import {authApi} from './Auth/authServices';
import editorReducer from "./Editor/editorSlice";

// Cấu hình redux-persist cho auth
const authPersistConfig = {
    key: 'auth', // Key riêng cho auth
    storage,
};

// Cấu hình redux-persist cho editor
const editorPersistConfig = {
    key: 'editor', // Key riêng cho editor
    storage,
};

const store = configureStore({
    reducer: {
        editor: editorReducer,
        auth: persistReducer(authPersistConfig, authReducer),
        [authApi.reducerPath]: authApi.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(authApi.middleware);
    },
});

const persistor = persistStore(store);

export { persistor };
export default store;
import React, {useEffect} from 'react';
import {Player} from "@lottiefiles/react-lottie-player";
import uiLotF from "../../static/lottie/ui.json";
import ui1 from "../../static/ui/h1.png"
import styled, {keyframes} from "styled-components";
import {AnimateElement, SlideElement} from "../../Duck/DuckDesignSystem/animate/AnimateElement";
import {useRef, useState} from "react";
import {useIsFromBottomViewport, useIsInViewport} from "../checker";
import {Link} from "react-router-dom";

function CardsUiCpn() {
    const elRef = useRef();
    const isInViewport = useIsInViewport(elRef);
    const [os, setOs] = useState(0)

    useEffect(() => {
        if (elRef) {
            const element = document.getElementById("workEle").childNodes[0]
            const scrollHandler = _ => {
                setOs(document.getElementById("checkpoint").getBoundingClientRect().top);
                if(element.scrollLeft -os>= window.innerHeight*3.2) return
                element.scrollLeft = -os

            };
            window.addEventListener("scroll", scrollHandler, false)
            return () => {
                window.removeEventListener('scroll', scrollHandler);
            };
        }

    }, [os]);


    return (
        <div ref={elRef}>
            <div id="checkpoint"></div>

            <div id="workEle" className=" is-relative  " >
                <div className="  is-flex   is-sticky is-overflow-x is-sticky" style={{top: "15vh"}}>
                    <div className=" border dash is-flex vertical p-l bg-white jt-between is-sticky"
                         style={{zIndex: 1, minWidth: "80%", height: "65vh", left: "0"}}>
                        <div className="hi-xxl ">
                            Understand
                            <div className="columns s-vertical">
                                <div className="col text-m">
                                    Comprehend the customer's desire

                                 </div>
                                <div className="col-2"></div>

                            </div>

                        </div>
                        <div className="hi-l ">01</div>
                    </div>
                    <div className=" border  dash is-flex vertical p-l bg-white jt-between is-sticky"
                         style={{zIndex: 2, minWidth: "80%", height: "65vh", left: "20%"}}>
                        <div className="hi-xxl ">
                            Design
                            <div className="columns s-vertical">
                                <div className="col text-m">
                                    Create designs that are highly practical, user-friendly </div>
                                <div className="col-2"></div>

                            </div>
                        </div>
                        <div className="hi-l ">02</div>
                    </div>
                    <div className=" border   dash is-flex vertical p-l bg-white jt-between is-sticky"
                         style={{zIndex: 3, minWidth: "80%", height: "65vh", left: "30%"}}>
                        <div className="hi-xxl ">
                            Develop
                            <div className="columns s-vertical">
                                <div className="col text-m">
                                    Build products that are practical, enhance products and support customers
                                </div>
                                <div className="col-2"></div>

                            </div>
                        </div>
                        <div className="hi-l ">03</div>
                    </div>

                    <div className="   is-sticky"
                         style={{zIndex: 4, minWidth: "80%", height: "75vh", left: "80%"}}>

                    </div>
                </div>
                <div className="columns layout-1-1  is-sticky" style={{top: "85vh"}}>
                    <div className="col text-xs text-darkgray ">
                        Simple workflow make it
                        <br/>easy to grow your business quickly.
                    </div>
                    <div className="is-flex jt-end col " >
                        <Link to="/work" className="btn btn-outline">Explore All Work
                            <div className="icon icon-arrow-right"></div>
                        </Link>
                    </div>
                </div>

            </div>

        </div>


    )
        ;
}

export default CardsUiCpn;

function FlashIcon({icon}) {
    const spin = keyframes`
      50% {
        opacity: 0;
      }
    `;
    const Flash = styled.div`
      animation: ${spin} ${Math.floor(Math.random() * 5) + 5}s ease-in infinite;`


    return (
        <Flash>

            <div className="m-l p-m bg-white rounded-s shader-1">
                <img className="size-l" src={icon} alt=""/>
            </div>
        </Flash>

    );
}

function IconUI() {
    const currentIcon = index => (require("../../static/icon/icon" + index.toString().padStart(2, '0') + ".svg"))
    let indents = [];
    for (let i = 1; i <= 4; i++) {
        let indent = [];
        for (let j = 1; j < 3; j++) {
            indent.push(
                <AnimateElement>
                    <FlashIcon key={i * j} icon={currentIcon(i * j)}></FlashIcon>
                </AnimateElement>
            );
        }
        indents.push(<div className="is-flex">
            {indent}
        </div>)
    }
    return (
        <div>
            {indents}
        </div>
    );
}


import React from 'react';
import {SlideElement} from "../../animate/AnimateElement";
import {Icon} from "../DuckIcon";
import {Link} from "react-router-dom";
import DesignPack from "./DesignPack";
import Resource from "./Resource";

function Foundation(props) {
    return (
        <div>

            <div className="container-l ">
                <div className="section mt-xxl p-m ">


                    <div className="grid-2 mt-xxl">
                        <div className="col">
                            <Link to={"/"} className="   product-card card vertical rounded-s border">
                                <div className=" card-img  is-relative ">
                                    <div className=" card-img  is-relative ">
                                        <div className="grid-blink ">

                                            <img src="https://i.imgur.com/VsObH9W.png" alt=""/>

                                        </div>
                                    </div>
                                </div>
                                <div className="card-content  p-m">
                                    <div className={"px-m gapy-xs "}>
                                        <h1> Focus</h1>
                                        <div className="text-xs">Prioritize the development of simple and accessible
                                            interfaces, focusing on providing essential information to users and
                                            avoiding clutter with unnecessary details.
                                        </div>
                                    </div>

                                </div>
                            </Link>
                        </div>


                        <div className="col">
                            <div className="   product-card card vertical rounded-s border">
                                <div className=" card-img  is-relative ">
                                    <div className="grid-blink ">
                                        <img src="https://i.imgur.com/gXrfr1q.png" alt=""/>
                                    </div>
                                </div>
                                <div className="card-content  p-m">
                                    <div className={"px-m gapy-xs "}>
                                        <h1> Consistency</h1>
                                        <div className="text-xs">Ensuring consistency in the user interface helps users
                                            adapt quickly, form usage habits, and use the system effectively over time.
                                            This improves user satisfaction, system efficiency, and productivity.
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="   product-card card vertical rounded-s border">
                                <div className=" card-img  is-relative ">
                                    <div className="grid-blink ">
                                        <img src="https://i.imgur.com/vVW7F5c.png" alt=""/>
                                    </div>
                                </div>
                                <div className="card-content  p-m">
                                    <div className={"px-m gapy-xs "}>
                                        <h1> Accessibility</h1>
                                        <div className="text-xs">Highlight interactive elements to prevent users from
                                            getting lost.
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="   product-card card vertical rounded-s border">
                                <div className=" card-img  is-relative ">
                                    <div className="grid-blink ">
                                        <img src="https://i.imgur.com/t4PVDsI.png" alt=""/>
                                    </div>
                                </div>
                                <div className="card-content  p-m">
                                    <div className={"px-m gapy-xs "}>
                                        <h1> Module</h1>
                                        <div className="text-xs">Build flexible replacement components that adhere to
                                            established principles.
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Foundation;
import {createSlice} from '@reduxjs/toolkit'
import {userLogin} from './Action'

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null


const initialState = {
    loading: false,
    userInfo: null,
    userToken: null,
    error: null,
    success: false,
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('userToken') // deletes token from storage
            state.loading = false
            state.userInfo = null
            state.userToken = null
            state.error = null
        },
    },
    extraReducers: {
        // login user
        [userLogin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userLogin.fulfilled]: (state, action) => {
            console.log(action)
            state.loading = false
            state.userToken = action.payload.userToken
            state.userInfo = action.payload.userInfo
        },
        [userLogin.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
    },
})
export const { logout } = authSlice.actions
export default authSlice.reducer
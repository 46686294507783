import {Icon} from "../../DuckDesignSystem/Source/DuckIcon";
import {useToast} from "./useToast";
import React, {useEffect, useRef, useState} from "react";

const toastTypes = {
    success: {
        icon: <Icon icon={"checked "}/>,
        color: "#56a264",
        progressBarClass: "Thành công",
    },
    warning: {
        icon: <Icon icon={"circle-ignore"}/>,
        color: "#ff8d4f",

        progressBarClass: "Cảnh báo",
    },
    info: {
        icon: <Icon icon={"circle-info"}/>,
        color: "#485FC7",
        progressBarClass: "Thông báo",
    },
    error: {
        icon: <Icon icon={"circle-ignore"}/>,
        color: "#c00000",
        progressBarClass: "Lỗi",
    },
};

const Toast = ({message, type, id}) => {
    const {icon, color, progressBarClass} = toastTypes[type];
    const toast = useToast() // call useToast
    const timerID = useRef(null);

    const [dismissed, setDismissed] = useState(false);

    const handleDismiss = () => {
        setDismissed(true);
        setTimeout(() => {
            toast.remove(id);
        }, 400);
    };
    useEffect(() => {
        timerID.current = setTimeout(() => {
            handleDismiss();
        }, 4000);

        return () => {
            clearTimeout(timerID.current);
        };
    }, []);
    return (
        <div className={`toast border  ${dismissed ? "toast-dismissed" : ""}`}>

            <div className="toast-message">
                <div className="is-block">
                    <div className={"is-flex"}  style={{color:color}}>
                        <div className="toast-icon">
                            {icon}
                        </div>
                        <div className={`text-xs text-capitalize `}>{progressBarClass}</div>
                    </div>
                    <p className="title-s mt-s">{message}</p>
                </div>

                <div onClick={handleDismiss} className="  is-flex align-start   p-xs  ">
                    <Icon className="text-l text-mute is-absolute" icon="multiply"></Icon>
                </div>
            </div>

        </div>
    )
}

export default Toast;
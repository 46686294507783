import React, {useEffect, useState, useRef} from 'react';
import {InputField} from "../Duck/DuckReact/Input";
import {SlideElement} from "../Duck/DuckDesignSystem/animate/AnimateElement";
import {Link} from "react-router-dom";

function Other(props) {
    useEffect(()=>{
        document.title = "Liên hệ với Hi nè";
    })
    return (
        <div className="Main">
                <SlideElement className="container-xl">
                    <div className=" p-m my-xxl border">
                        <div className="hi-4xl text-bold">
                            HAVE AN IDEAL ?
                        </div>
                        <div className="columns pt-l">

                            <div className="col">
                                <form action="">
                                    <InputField required label={"Name"} tooltip={"required"}
                                                placeholder={"Enter Your Name"}></InputField>
                                    <InputField required label={"Email"} tooltip={"required"}
                                                placeholder={"Enter Your Email"}></InputField>
                                    <div className=" is-fill-x">
                                        <div className="field has-info  is-required">
                                            <div className="label"> Ideal
                                            </div>

                                            <textarea className="is-fill-x" title="input"
                                                      placeholder="Type something..."/>
                                        </div>

                                    </div>
                                    <div className="columns layout-1-1   p-m">
                                        <div className="col text-xs text-darkgray ">
                                            I only use your email to communicate
                                            <br/> All information is kept confidential.
                                        </div>
                                        <div className="is-flex jt-end col ">
                                            <Link to="/sendmail" className="btn ">Send email
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>


                    </div>
                </SlideElement>
        </div>
    );
}

export default Other;
import React, {useState} from "react";
import Tabs from "../../../DuckReact/Tabs";
import {Highlight, themes} from "prism-react-renderer";
import FormatHTMLString from "../Controler/JSXtoString";

const Layout42 = ({children, code, size}) => {
    const childrenArray = React.Children.toArray(children);
    const [tab, setTab] = useState(childrenArray.length > 1 ? 0 : 1)
    const tabs = childrenArray.length > 1 ? [{
        tabId: 0,
        tabName: "Controller"
    }, {
        tabId: 1,
        tabName: "</>"
    }] : [{
        tabId: 1,
        tabName: "</>"
    }]

    function changeTab(value) {
        setTab(value)
    }

    return <div className={`mt-xl `}>
        <div className={`mt-l bg-white border rounded-l columns gap-l l-vertical is-relative `}>
            <div className={"col rounded-l-m   is-center"}>
                <div className="p-xl  is-fill-x ">
                    {childrenArray[0]}
                </div>
            </div>
            <div className={` col is-flex vertical rounded-r-m border-special is-relative is-scroll-x ${
                size === "s" ? "y-quarter" : size === "m" ? "y-half" : size === "l" ? "y-three-quarter" : ""
            }`} style={{overflow: "visible"}}>
                <div className="border-b   " style={{left: "0"}}>
                    <Tabs tabs={tabs} handleClick={changeTab}></Tabs>
                </div>
                {tab === 0 ? <div className={`p-xl  ${tab === 0 ? 'slide-in' : 'slide-out'}`}>
                        {childrenArray[1]}
                    </div> :
                    <div className={`is-grow is-scroll-y  ${tab === 0 ? 'slide-out' : 'slide-in'}`}>
                        <Highlight
                            theme={themes.oneDark}
                            code={FormatHTMLString(code)}
                            language="jsx"
                        >
                            {({className, style, tokens, getLineProps, getTokenProps}) => (
                                <pre style={{flexGrow: "1", overflowY: "auto", borderRadius: "0", ...style}}>
                                    {tokens.map((line, i) => (
                                        <div className={"is-flex"} key={i} {...getLineProps({line})}>
                                            {line.map((token, key) => (
                                                <span className={"text-l"} key={key} {...getTokenProps({token})} />
                                            ))}
                                        </div>
                                    ))}
                                </pre>
                            )}
                        </Highlight>
                    </div>
                }

            </div>
        </div>
    </div>
}
const Layout42Main = ({children}) => {
    const childrenArray = React.Children.toArray(children);
    return <div className="columns mt-xxl gap-l vertical section">
        <div className="col">
            {childrenArray[0]}
        </div>
        <div className="col">
            {childrenArray[1]}
        </div>
    </div>
}
export {Layout42, Layout42Main}

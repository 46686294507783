import React from 'react';

function ImageContextRender({context}) {
    const imageContext = context
    return (
        <div className={" bg-light is-relative p-s rounded-s"}>
            <img className={`rounded-s ratio-${imageContext.defaultState.image.ratio}`} src={imageContext.defaultState.image.url} alt=""/>
            <i  dangerouslySetInnerHTML={{ __html: imageContext.content}} className="is-fill-x "></i>
        </div>
    );
}
export default ImageContextRender;
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Navigate, NavLink, Outlet, useParams} from "react-router-dom";
import {Icon} from "../Duck/DuckDesignSystem/Source/DuckIcon";
import {useToast} from "../Duck/DuckReact/Toast/useToast";
import {TabItem} from "../Duck/DuckReact/Tabs";
import ImageDashBoard from "./DashBoard/Image/ImageDashBoard";
import {useGetUserDetailsQuery} from "../redux/Auth/authServices";
import authApi from "../Api/Auth/AuthApi";
import {logout} from "../redux/Auth/authSlice";
import News from "./DashBoard/NewsManager";
import ViewNews from "./DashBoard/News/ViewNews";
import NewNews from "./DashBoard/News/NewNews/NewNews";
import logo from "../static/logo-beta.svg";
import {getNews} from "../Api/Auth/Services/News/NewsApi";

let AdminItem = []
const ADMIN_IMAGE = 'Image';
const ADMIN_NEWS = 'News';
// return Menu
const AdminMenu = ({currentID, setCurrentID}) => {
    const sub = useRef(null)
    const [mItem, setMItem] = useState([])
    const [visible, setVisible] = useState(null);
    let elmWidth = 0
    let elmHeight = 0
    let offSet = 0
    const osStyle = {
        opacity: visible ? 1 : 0,
        width: elmWidth,
        transform: `translateY(${offSet}px)`,
        transitionDuration: '200ms'
    }
    useMemo(() => {
        const elm = sub.current
        if (elm) {
            if (visible != null) {
                elmWidth = visible.current.clientWidth
                offSet += visible.current.offsetTop
                elm.style.transform = `translateY(${offSet}px)`
                elm.style.width = `${elmWidth}px`
            } else {
                elm.style.opacity = 0;
                setVisible(null)
            }
        }
    }, [visible])

    useEffect(() => {
        setMItem(AdminItem)
    }, [AdminItem])
    const onHover = ref => {
        if (ref)
            setVisible(ref)
        else setVisible(null)
    }

    const handleJump = id => {
        console.log("id", id)
        setCurrentID(id)
    }
    return <>
        <div ref={sub} className="side-sub" id="subSide"
             style={visible ? osStyle : {}}></div>
        {
            mItem.map((item, index) =>
                <div key={index} className={"item"}>
                    <TabItem onHover={onHover}>
                        <NavLink onClick={() => handleJump(item.id)} to={`${item.id}`}
                                 className={` sidebar-btn  ${currentID === item.id ? "active" : ""} `}>
                            <Icon className="sidebar-icon text-l mx-s" icon={item.icon}/>
                            <div className="item-text">
                                {item.name}
                            </div>
                        </NavLink>

                    </TabItem>
                </div>
            )
        }
    </>
}

function Header(props) {
    const toast = useToast()
    const dispatch = useDispatch()
    const {userInfo, userToken} = useSelector((state) => state.auth)
    const {data, isFetching} = useGetUserDetailsQuery('userDetails', {
        // perform a re-fetch every 15m
        pollingInterval: 900000,
    })
    useEffect(() => {
        if (userToken) {
            dispatch(authApi.getUserDetails())
        }
    }, [userToken, dispatch])

    return <div className="navbar p-m  border-b bg-white is-sticky" style={{zIndex: 1, top: "0"}}>
        <div className="nav-brand">

        </div>
        <div className="nav-main is-flex jt-end gapx-s  ">
            <h4 className={"text-capitalize"}>{userInfo.name}</h4>
            <div onClick={() => {
                dispatch(logout())
                toast.success("Logout Successfully!")
            }} className="size-xl rounded border">
                <img src={"https://i.imgur.com/gE1mED6.jpeg"} alt="Log out"/>
            </div>
        </div>

    </div>
}

function Admin(props) {
    const [currentID, setCurrentID] = useState()
    const [expandItem, setExpandItem] = useState(false)
    const [expandMenu, setExpandMenu] = useState(false)
    const [projectMenu, setProject] = useState(false)
    const toast = useToast()
    const {userInfo, userToken} = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    // automatically authenticate user if token is found

    const {data, isFetching} = useGetUserDetailsQuery('userDetails', {
        // perform a refetch every 15mins
        pollingInterval: 900000,
    })
    if (!userInfo) {
        return <Navigate to={"/login"}></Navigate>
    }
    const handleClick = (value) => {
        if (value !== 0) {
            const element = document.getElementById(`item${value}`);
            if (element.classList.contains("expand")) {
                element.classList.remove("expand")
            } else element.classList.add("expand")
        } else {
            setExpandItem(!expandItem)
        }
    }
    AdminItem = []
    AdminItem.push({name: "Image", id: "/addImage", icon: "image"})
    AdminItem.push({name: "Editor", id: "/news", icon: "header"})


    return (
        <div className="container-full bg-mute  columns gap-s p-s is-sticky " id={"dashboard"}>
            <div className={` sidebar font-text  ${expandMenu ? "" : "hide"}`} id="sidebar">
                <div className={`sidebar-header`}>
                    <div className="sidebar-brand">
                        <img src={logo} alt=""/>
                    </div>
                </div>
                <div className={`sidebar-main  `}>
                    <div onClick={() => setExpandMenu(!expandMenu)} className="sidebar-mobile-collapse">
                        <Icon className={" text-l"} icon={expandMenu ? "close" : "menu"}/>
                    </div>
                    <div className="sidebar-items ">
                        <AdminMenu currentID={currentID} setCurrentID={setCurrentID}/>
                    </div>
                    <div className="sidebar-items p-x ">
                        <div onClick={() => {
                            dispatch(logout())
                            toast.success("Logout Successfully!")
                        }} onMouseEnter={() => expandItem ? setProject(true) : {}}
                             onMouseLeave={() => setProject(false)}
                             className={`item ${projectMenu ? "show" : ""} align-center cursor-pointer expand-vertical bg-hover-black rounded-s`}>
                            <div className={`item-collapse  p-x`}>
                                <h5 className={"text-white py-m"}>Account</h5>
                                <div className="px-xs sidebar-btn columns align-center  rounded-s bg-hover-mute  ">

                                    <img alt={"project"} className={"sidebar-icon ratio-1-1 size-xl rounded-s"}
                                         src={"https://i.imgur.com/33V0Db0.png"}/>
                                    <div className="text-white  col pl-m ">
                                        <div className={"jt-between  is-fill-x is-flex"}>
                                            <h6 className={"text-capitalize"}>Hine.Studio</h6>
                                            <Icon icon={"checked text-white"}></Icon>
                                        </div>
                                    </div>


                                </div>
                                <div onClick={() => {
                                    dispatch(logout())
                                    toast.success("Logout Successfully!")
                                }} className="sidebar-btn px-xs columns align-center rounded-s  bg-hover-mute  ">
                                    <div className="sidebar-icon ratio-1-1 size-xl">
                                        <Icon icon={"sign-out"} className={"text-white"}></Icon>
                                    </div>
                                    <div className="text-white col pl-m">
                                        <div className={"jt-between is-fill-x is-flex"}>
                                            <h6 className={"text-capitalize"}>Log Out</h6>
                                        </div>
                                    </div>

                                </div>
                                <div className="divider my-s"></div>


                            </div>

                            <div className="sidebar-btn px-xs   ">
                                <img alt={"project"} className={"sidebar-icon ratio-1-1 size-xl rounded-s"}
                                     src={"https://i.imgur.com/33V0Db0.png"}/>
                                <div className="text-white col pl-m">
                                    <div className={"jt-between is-fill-x is-flex"}>
                                        <h6 className={"text-capitalize"}>Hine.Studio</h6>
                                        <Icon className={`${projectMenu ? "rotate-180" : ""} text-white`}
                                              icon={"up-down"}></Icon>
                                    </div>
                                    <p className={"text-xs text-disable"}>admin@hine.studio</p>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                <div className="sidebar-bottom">

                    <a onClick={() => handleClick(0)}>
                        <Icon className={"text-white text-xl"} icon={expandItem ? "sign-in" : "sign-out"}/>
                    </a>
                </div>

            </div>
            <div className="col-5  bg-white   font-text smooth-scroll rounded-m border " id={"special"}>
                <Outlet/>
            </div>
        </div>
    );
}

export default Admin;
const SectionCpn = (props) => {
    const ref = useRef(null);
    return <div ref={ref} id={props.sectionId}>
        {props.elements}
    </div>
}
const NewsDashboard = () => {
    return <SectionCpn icon={"header"} sectionId={ADMIN_NEWS} title={"News"}
                       subtitle={"News"}
                       elements={<News/>}/>
}
const ImageDE = () => {
    return <SectionCpn icon={"image"} sectionId={ADMIN_IMAGE} title={"Image"}
                       subtitle={"Image"}
                       elements={<ImageDashBoard/>}/>
}
const ViewNewsDE = () => {
    return <SectionCpn icon={"image"} sectionId={ADMIN_IMAGE} title={"Image"}
                       subtitle={"Image"}
                       elements={<ViewNews/>}/>
}
const ViewNewsDETEST = () => {
    return <SectionCpn icon={"image"} sectionId={ADMIN_IMAGE} title={"Image"}
                       subtitle={"Image"}
                       elements={<ViewNewsTEST/>}/>
}
const NewNewsDE = () => {
    return <SectionCpn icon={"circle"} sectionId={ADMIN_IMAGE} title={"circle"}
                       subtitle={"circle"}
                       elements={<NewNews/>}/>
}
const UpdateNewsDE = () => {
    const news = useParams()
    return <SectionCpn icon={"circle"} sectionId={ADMIN_IMAGE} title={"circle"}
                       subtitle={"circle"}
                       elements={<NewNews news={news}/>}/>
}
export {AdminItem, NewsDashboard, ImageDE, ViewNewsDE, NewNewsDE, UpdateNewsDE, ViewNewsDETEST}

function ViewNewsTEST() {
    const {customUrl} = useParams();
    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (customUrl) {
            getNews(customUrl).then((data) => {
                setNews(JSON.stringify(data))
                setLoading(true)
            });
        }
    }, [customUrl])
    return <div className="container">
        {loading ? <div>{news}</div> : <div>Loading...</div>}
    </div>

}
import React, {useState} from 'react';
import img from "../../../../../static/img.png";
import {Layout42} from "../../Support/Layout";
import {Segment} from "../Input/DuckSelect";
import {Icon} from "../../DuckIcon";


function CardSection(props) {

    const [direction, setDirection] = useState(0)
    const cardString = <div className={`card ${!direction?"vertical":""} bg-light  rounded-l`}>
        <div className="card-img "><img className="ratio-21-9" src="https://i.imgur.com/zj6aW15.jpg" alt=""/></div>
        <div className="card-content p-m   is-flex vertical jt-between  ">
            <div className="card-title ">
                <div className="is-flex jt-between align-center ">
                    <div><h1>OrZana ca</h1></div>
                </div>
            </div>
            <div className="card-body gapy-s">
                <div className="p-m bg-sliver rounded-m is-flex jt-between align-center">
                    <div className="">Final-file.png</div>
                    <div className="btn btn-s btn-ghost">
                        <div className="text-center icon icon-download text-xl"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    return (
        <div className="section">
            <h3 className="h-l">Card</h3>
            <div className="pt-xl columns gap-l l-vertical">
                <div className="col-3 columns s-vertical">

                    <div className="text-m is-2-line">
                        Card thường được sử dụng để hiển thị thông tin hoặc nội dung liên quan trong một gói tổ chức,
                        thường là hình chữ nhật hoặc vuông.
                    </div>
                </div>
                <div className="section">
                    <Layout42 size={"m"} code={cardString}>
                        <div className={` card ${direction === 0 ? "vertical" : ""} bg-light  rounded-l`}>
                            <div className="card-img ">
                                {direction === 0 ?
                                    <img className="ratio-21-9" src={"https://i.imgur.com/zj6aW15.jpg"} alt=""/> :
                                    <img className="ratio-3-4" src={"https://i.imgur.com/zj6aW15.jpg"} alt=""/>}
                            </div>
                            <div className="card-content p-m   is-flex vertical jt-between  ">
                                <div className="card-title ">
                                    <div className="is-flex jt-between align-center ">
                                        <div>
                                            <h1>
                                                OrZana ca
                                            </h1>
                                        </div>
                                    </div>

                                </div>
                                <div className="card-body gapy-s">
                                    <div className="p-m bg-sliver rounded-m is-flex jt-between align-center">
                                        <div className="">Final-file.png</div>
                                        <div className="btn btn-s btn-ghost">
                                            <Icon className={"text-xl"} icon={"download"}></Icon>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                        <div>
                            <div className="field has-label-inline">
                                <div className="label">Direction</div>
                                <Segment handleClick={() => {
                                }} segment={[{action: "device-mobile"}, {action: "device-tablet"}]}
                                         child={({item, index}) => (
                                             <div key={index} className={`btn ${direction === index ? "active" : ""}`}
                                                  onClick={() => {
                                                      setDirection(index)
                                                  }}><Icon className={"text-l"} icon={item.action}/></div>)}
                                >
                                </Segment>
                            </div>

                        </div>
                    </Layout42>
                </div>

            </div>

        </div>
    );
}

export default CardSection;
import duckImg from "../static/nen/duck.png";

const hostUrl ="hine.studio"
console.log(process.env)

const WorkTimeline = {
    timelines: [
        {
            id: 0,
            title: "Fogo VietNam",
            img: require("../static/partner/fogo.png"),
            schedule: {
                startTime: new Date("2020-07-01"),
                endTime: new Date("2021-03-01")
            },
            description: "Giải pháp tìm phòng trọ",
            content: "Ui designer, Logo creator, Graphic designer",
            project: [
                {image: "https://i.imgur.com/ZNRzWkU.png"},
            ]
        },
        {
            id: 1,
            title: "Bisto, GeeerVN",
            img: require("../static/partner/Bisto.png"),
            schedule: {
                startTime: new Date("2021-03-01"),
                endTime: new Date("2021-09-01")
            },
            description: "Trang thương mại điện tử về thời trang",
            content: "UI web designer, FE developer",
            project: [
                {image: "https://i.imgur.com/jYjooVh.png"},
            ]

        },
        {
            id: 2,
            title: "Vuong Soft",
            img: require("../static/partner/bujo.png"),
            schedule: {
                startTime: new Date("2021-9-01"),
                endTime: new Date("2021-12-01")
            },
            description: "Giải pháp công nghệ cho các công việc ngắn hạn",
            content: "UI/UX designer, FE developer",
            project: [
                {image: "https://i.imgur.com/jByaI8w.png"},
            ]
        },
        {
            id: 3,
            title: "Global safe Corp",
            img: require("../static/partner/Global.png"),
            schedule: {
                startTime: new Date("2021-12-01"),
                endTime: new Date("2022-04-01")
            },
            description: "Dịch vụ quản trị hợp đồng bảo hiểm và tài chính",
            content: "Product designer, Business Analyst",
            project: [
                {image: "https://i.imgur.com/o3ytgPB.png"},
                {image: "https://i.imgur.com/EfdufgF.png"},
            ]
        },
        {
            id: 4,
            title: "VC Corporation",
            img: require("../static/partner/VCC.png"),
            schedule: {
                startTime: new Date("2022-04-01"),
                endTime: new Date("2999-04-01")
            },
            description: "Công ty hàng đầu trong lĩnh vực Internet và nội dung số ở Việt Nam",
            content: "Product designer",
            project: [
                {image: "https://i.imgur.com/U3Urf7A.png"},
            ]
        }
    ]
}
const UiUxDescription = {
    id: "01",
    title: "UI/UX",
    lotF: require("../static/lottie/data.json"),
    description: "Bring your ideas come true",
    content: "Creating beautiful, simple interfaces is my joy, and it's what I do best as a UI/UX designer. This passion consistently inspires me to craft my own unique design system.",
    products: [
        {
            title: "DUCK Design System",
            content:
                "DuckEditor is a versatile animal that can walk, fly, and swim. Similarly, the DuckEditor UI design system is flexible " +
                "and can be used in a variety of ways. DuckUI is perfect for designers who want a reliable and easy-to-use system" +
                " that can be customized to their specific needs. With its flexibility, DuckUI can be used for everything " +
                "from small websites to large enterprise applications.",
            img: require("../static/ui/duckui.png"),
            logo: require("../static/ui/duckui-logo.png"),
            exp: "T9 - 2022",
            category: "Design System",
            refTo: "https://duck."+hostUrl+"/introduction", //TODO >?????
            CustomContent: [{icon: "figma"}, {icon: "ps"}, {icon: "reactjs"}],
            tag: [{tag: "figma"}, {tag: "UI/UX"}, {tag: "reactjs"}],
        },
        {
            title: "Duck icon library",
            content:
                "The Duck icon is a great way to add some personality to your design system. It's flexible enough" +
                " to be used in a variety of ways, from simple decoration to more complex interactions. Plus, it's just really cute!",
            img: "https://i.imgur.com/cFWj8AC.png",
            refTo: "https://duck."+hostUrl+"/duck-icon",
            logo: "https://i.imgur.com/eCvX9en.png",
            exp: "T9 - 2022",
            category: "Icon Library",
            CustomContent: [{icon: "figma"}, {icon: "reactjs"}],
            tag: [{tag: "figma"}, {tag: "svg"}, {tag: "icon"}],
        },
        {
            title: "Breakout Principle",
            content:
                "DuckEditor is a versatile animal that can walk, fly, and swim. Similarly, the DuckEditor UI design system is flexible " +
                "and can be used in a variety of ways. DuckUI is perfect for designers who want a reliable and easy-to-use system" +
                " that can be customized to their specific needs. With its flexibility, DuckUI can be used for everything " +
                "from small websites to large enterprise applications.",
            img: require("../static/ui/BreakoutPrinciple.png"),
            logo: require("../static/ui/BreakoutPrinciple-logo.png"),
            exp: "T9 - 2022",
            category: "Design",
            refTo: "https://duck."+hostUrl+"/introduction", //TODO >?????
            CustomContent: [{icon: "figma"}, {icon: "ps"}, {icon: "reactjs"}],
            tag: [{tag: "figma"}, {tag: "UI/UX"}, {tag: "reactjs"}],
        },
    ],

}
const ThreeDDescription = {
    id: "02",
    title: "3D Visualization",
    description: "It’s a way of seeing and imagining.",
    content: "The process of creating illustrative images that capture and evoke a sense of realism and authenticity. This involves meticulously crafting each element of the illustration to ensure it resonates with the viewer and portrays the desired message or theme in a manner that feels genuine and true to life.",
    products: [
        {
            title: "Space Asset",
            content:
                "DuckEditor is a versatile animal that can walk, fly, and swim. Similarly, the DuckEditor UI design system is flexible " +
                "and can be used in a variety of ways. DuckUI is perfect for designers who want a reliable and easy-to-use system" +
                " that can be customized to their specific needs. With its flexibility, DuckUI can be used for everything " +
                "from small websites to large enterprise applications.",
            img: "https://i.imgur.com/eJxmgtk.png",
            logo: "https://i.imgur.com/eJxmgtk.png",
            exp: "T9 - 2022",
            category: "Model Library",
            refTo: "https://portfo."+hostUrl+"/3d/forest", //TODO >?????
            CustomContent: [{icon: "figma"}, {icon: "ps"}, {icon: "reactjs"}],
            tag: [{tag: "figma"}, {tag: "blender"}, {tag: "3D"}],
        },
        {
            title: "Sweet house",
            content:
                "DuckEditor is a versatile animal that can walk, fly, and swim. Similarly, the DuckEditor UI design system is flexible " +
                "and can be used in a variety of ways. DuckUI is perfect for designers who want a reliable and easy-to-use system" +
                " that can be customized to their specific needs. With its flexibility, DuckUI can be used for everything " +
                "from small websites to large enterprise applications.",
            img: require("../static/ui/Layout.png"),
            logo: require("../static/ui/Layout-logo.png"),
            refTo: "https://portfo."+hostUrl+"/doc/1ab9dff4-7caf-48a2-a969-31f384cb1d2e",
            CustomContent: [{icon: "figma"}, {icon: "ps"}, {icon: "reactjs"}],
            tag: [{tag: "figma"}, {tag: "ps"}, {tag: "reactjs"}],
        },
        {
            title: "From Blender to React",
            content:
                "DuckEditor is a versatile animal that can walk, fly, and swim. Similarly, the DuckEditor UI design system is flexible " +
                "and can be used in a variety of ways. DuckUI is perfect for designers who want a reliable and easy-to-use system" +
                " that can be customized to their specific needs. With its flexibility, DuckUI can be used for everything " +
                "from small websites to large enterprise applications.",
            img: require("../static/ui/Buddha.png"),
            refTo: "https://portfo."+hostUrl+"3d/forest",
            logo: "https://i.imgur.com/5DJ90jW.png",
            exp: "T3- 2023",
            category: "Material Library",
            CustomContent: [{icon: "figma"}, {icon: "ps"}, {icon: "reactjs"}],
            tag: [{tag: "figma"}, {tag: "blender"}, {tag: "3D"}],
        },
        {
            title: "LYNHI material library",
            content:
                "If you want your design to be truly groundbreaking, you need to follow the DuckEditor Design system. " +
                "This system is based on the principle of breakthrough, which states that in order to" +
                " achieve something great, you need to take DUCK RULE and push boundaries.",
            img: require("../static/ui/MaterialLibrary.png"),
            refTo: "https://portfo."+hostUrl+"3d/duck",
            logo: require("../static/ui/MaterialLibrary-logo.png"),
            exp: "T3- 2023",
            category: "Material Library",
            CustomContent: [{icon: "reactjs"}],
            tag: [{tag: "figma"}, {tag: "blender"}, {tag: "3D"}],
        },
    ],
}

const SelectedProject = {
    id: "01",
    title: "UI/UX",
    lotF: require("../static/lottie/data.json"),
    description: "Bring your ideas come true",
    content: "  Website design inspiration. Discover the best website designs of the world. Hine recognizes the talent and effort of the best designers, web developers and digital agencies.",
    products: [
        {
            title: "DUCK Design System",
            content:
                "DuckEditor is a versatile animal that can walk, fly, and swim. Similarly, the DuckEditor UI design system is flexible " +
                "and can be used in a variety of ways. DuckUI is perfect for designers who want a reliable and easy-to-use system" +
                " that can be customized to their specific needs. With its flexibility, DuckUI can be used for everything " +
                "from small websites to large enterprise applications.",
            img: require("../static/ui/duckui.png"),
            logo: require("../static/ui/duckui-logo.png"),
            exp: "T9 - 2022",
            category: "Design System",
            refTo: "https://duck."+hostUrl+"/introduction",
            tag: [{tag: "figma"}, {tag: "UI/UX"}, {tag: "reactjs"}],
        },
        {
            title: "Duck icon library",
            content:
                "The Duck icon is a great way to add some personality to your design system. It's flexible enough" +
                " to be used in a variety of ways, from simple decoration to more complex interactions. Plus, it's just really cute!",
            img: "https://i.imgur.com/cFWj8AC.png",
            refTo: "https://duck."+hostUrl+"/duck-icon",
            logo: "https://i.imgur.com/eCvX9en.png",
            exp: "T9 - 2022",
            category: "Icon Library",
            tag: [{tag: "figma"}, {tag: "svg"}, {tag: "icon"}],
        },
        {
            title: "The Forest",
            content:
                "DuckEditor is a versatile animal that can walk, fly, and swim. Similarly, the DuckEditor UI design system is flexible " +
                "and can be used in a variety of ways. DuckUI is perfect for designers who want a reliable and easy-to-use system" +
                " that can be customized to their specific needs. With its flexibility, DuckUI can be used for everything " +
                "from small websites to large enterprise applications.",
            img: require("../static/ui/Buddha.png"),
            refTo: "https://portfo."+hostUrl+"/3d/forest",
            logo: "https://i.imgur.com/5DJ90jW.png",
            exp: "T3- 2023",
            category: "Visual Pipeline",
            tag: [{tag: "blender"}, {tag: "3D"}],
        },
    ],

}
const SelectedElementCt = [
    {
        id: 0,
        title: "Duck Editor",
        description: "Powerful reactjs Editor",
        content: "Note, text editor, custom component",
        ref: "/duck"
    },
    {
        id: 1,
        title: "Nhan",
        description: "Powerful reactjs Editor",
        content: "Note, text editor, custom component",
        ref: "/duck"

    },
    {
        id: 2,
        title: "Duck Chart",
        description: "Duck system analytics charts",
        content: "Custom chart by Duck Chart API",
        ref: "/duck"

    },
    {
        id: 3,
        title: "100+ Duck's template",
        description: "All of Duck's template",
        content: "Figma template",
        ref: "/duck"
    },
    {
        id: 4,
        title: "Duck Figma Plugin",
        description: "Powerful content generate figma plugin",
        content: "Auto generate content in figma",
        ref: "/duck"
    },
]

const CodeProducts = [
    {
        title: "DUCK UI REACT",
        content:
            "DuckEditor is a versatile animal that can walk, fly, and swim. Similarly, the DuckEditor UI design system is flexible " +
            "and can be used in a variety of ways. DuckUI is perfect for designers who want a reliable and easy-to-use system" +
            " that can be customized to their specific needs. With its flexibility, DuckUI can be used for everything " +
            "from small websites to large enterprise applications.",
        img: duckImg,
        refTo: "code/duck",
        CustomContent: [{icon: "figma"}, {icon: "ps"}, {icon: "reactjs"}],
        tag: [{tag: "figma"}, {tag: "ps"}, {tag: "reactjs"}],
    },
    {
        title: "The Function",
        content:
            "If you want your design to be truly groundbreaking, you need to follow the DuckEditor Design system. " +
            "This system is based on the principle of breakthrough, which states that in order to" +
            " achieve something great, you need to take DUCK RULE and push boundaries.",
        img: duckImg,
        refTo: "code/duck",
        CustomContent: [{icon: "reactjs"}],
        tag: [{tag: "figma"}, {tag: "ps"}, {tag: "reactjs"}],
    },
];
const ListToolBar = [
    // {
    //     group: [
    //         {
    //             tool: "undo",
    //             icon: "icon-undo",
    //             style: "text-l",
    //
    //         },
    //         {
    //             tool: "redo",
    //             icon: "icon-redo",
    //             style: "text-l",
    //
    //         }
    //     ]
    // },
    {
        group: [
            {
                tool: "header",
                icon: "icon-header-1",
                style: "text-l",
                listTools: [
                    {
                        tool: "headXXL",
                        icon: "icon-header-1",
                        style: "h-xxl",
                    },
                    {
                        tool: "headXL",
                        icon: "icon-header-2",
                        style: "h-xl",
                    },
                    {
                        tool: "headL",
                        icon: "icon-header-3",
                        style: "h-l",
                    },
                    {
                        tool: "textXXL",
                        icon: "icon-header-4",
                        style: "text-xxl",
                    },
                    {
                        tool: "textXL",
                        icon: "icon-header-5",
                        style: "text-xl",
                    },
                    {
                        tool: "textL",
                        icon: "icon-header-6",
                        style: "text-l",
                    }
                ]

            },
            {
                tool: "color",
                icon: "size-m rounded-xs bg-black",
                style: "text-l",
                listTools: [
                    {
                        tool: "colorLight",
                        icon: "size-m rounded-xs bg-black",
                        style: "text-black",
                    },
                    {
                        tool: "colorLight",
                        icon: "size-m rounded-xs bg-mute",
                        style: "text-mute"
                    },
                    {
                        tool: "colorLight",
                        icon: "size-m rounded-xs bg-darkgray",
                        style: "text-darkgray"
                    },
                    {
                        tool: "colorLight",
                        icon: "size-m rounded-xs bg-disable",
                        style: "text-disable"
                    },
                    {
                        tool: "colorLight",
                        icon: "size-m rounded-xs bg-sliver",
                        style: "text-sliver"
                    },
                    {
                        tool: "colorLight",
                        icon: "size-m rounded-xs bg-light",
                        style: "text-light"
                    },
                    {
                        tool: "colorLight",
                        icon: "size-m rounded-xs bg-primary-dark",
                        style: "text-primary-dark"
                    },
                    {
                        tool: "colorLight",
                        icon: "size-m rounded-xs bg-primary",
                        style: "text-primary"
                    },
                    {
                        tool: "colorLight",
                        icon: "size-m rounded-xs bg-primary-light",
                        style: "text-primary-light"
                    },

                ]

            },
            {
                tool: "textBold",
                icon: "icon-style-bold",
                style: "text-bold",
            },
            {
                tool: "textItalic",
                icon: "icon-style-italic",
                style: "text-italic",

            },
            {
                tool: "textUnderLine",
                icon: "icon-underline",
                style: "text-underline"

            },
            {
                tool: "textUppercase",
                icon: "icon-uppercase",
                style: "text-uppercase"

            },
        ]
    },
    {
        group: [
            {
                tool: "textAlign",
                icon: "icon-justify-left",
                style: "text-left",
                listTools: [
                    {
                        tool: "textAlignLeft",
                        icon: "icon-justify-left",
                        style: "text-align-left"
                    }
                    ,
                    {
                        tool: "textAlignCenter",
                        icon: "icon-justify-center",
                        style: "text-align-center"
                    }
                    ,
                    {
                        tool: "textAlignRight",
                        icon: "icon-justify-right",
                        style: "text-align-right"
                    }

                ]

            },
            {
                tool: "list",
                icon: "icon-list",
                style: "list",
                listTools: [
                    {
                        tool: "listBullet",
                        icon: "icon-list",
                        style: "list-bullet"
                    }
                    ,
                    {
                        tool: "listOrdinal",
                        icon: "icon-ordinal",
                        style: "list-ordinal"
                    }
                ]
            },
            {
                tool: "increaseIndent",
                icon: "icon-increase-indent",
                style: "increase-indent",
            },
            {
                tool: "decreaseIndent",
                icon: "icon-decrease-indent",
                style: "decrease-indent",

            },
        ]
    },
    {
        group: [

            {
                tool: "addTable",
                icon: "icon-table",
                style: "table"

            },
            {
                tool: "link",
                icon: "icon-link",
                style: "is-link",
                type: "embed"
            },
            {
                tool: "addImage",
                icon: "icon-image",
                style: "is-image",
                type: "embed"

            },
            {
                tool: "quote",
                icon: "icon-quote",
                style: "is-quote"

            }
        ]
    }
]
export {
    CodeProducts,
    UiUxDescription,
    ThreeDDescription,
    WorkTimeline,
    ListToolBar,
    SelectedElementCt,
    SelectedProject
};
import {base} from "../../../url";
import axios from 'axios';
import {useState} from "react";
import toast from "../../../../Duck/DuckReact/Toast/Toast";
import {useToast} from "../../../../Duck/DuckReact/Toast/useToast";

async function handelGetImage() {

    return await axios.get(`${base}/image/`, {withCredentials: true})
        .then((response) => response.data)
        .catch(error => console.log('error', error));
}

async function handleDeleteImage(uuid) {
    return await axios.delete(`${base}/image/${uuid}`, {withCredentials: true})
        .then(response => response)
        .catch((error) => {
            console.log(error);
        });
}

async function handleUploadImage(file) {
    const files = new File([file], file.name);

    let fileData = new FormData();
    fileData.append('image', files)
    let uploadFileConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        timeout: 3000,
        url: 'https://api.imgur.com/3/image',
        headers: {
            'Authorization': 'Client-ID ee667a2664d8e18',
        },
        data: fileData
    }
    const fileRes = await axios.request(uploadFileConfig).then((res) => res) // Handling success
        .catch((err) => {
            if (err.code === 'ECONNABORTED') {
                console.log('The request timed out.');
            } else {
                return err
            }

        }); // Handling error

    const params = JSON.stringify({

        "link": fileRes.data["data"]["link"],

        "url": fileRes.data["data"]["link"],

    });


    let config = {
        method: 'post',
        url: `${base}/image/`,
        data: params,
        withCredentials: true
    };

    return await axios.request(config)
        .then((response) => response)
        .catch(error => console.log('error', error));

}

export {handelGetImage, handleUploadImage, handleDeleteImage}
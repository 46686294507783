import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import {getSuggestTags} from "../../../../Api/Auth/Services/News/NewsApi";
import toast from "../../../../Duck/DuckReact/Toast/Toast";
import {ContextMenu} from "../../../../Duck/DuckEditor/Context/TableContext";
import {Icon} from "../../../../Duck/DuckDesignSystem/Source/DuckIcon";
const TagsInput = ({ onTagsChange }) => {
    const [tags, setTags] = useState([]);
    const [suggestedTags, setSuggestedTags] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false)
    const fetchSuggestedTags = useCallback(
        debounce(async (query) => {
            if (query) {
                try {
                    getSuggestTags(query).then(data=>{
                        setSuggestedTags(data)
                        setLoading(true);
                        console.log(suggestedTags)
                    }).catch(res => {
                        console.log(res)
                        toast.error(res)
                    })
                } catch (error) {
                }
            } else {
                setSuggestedTags([]);
            }
        }, 200), // Chờ 300ms trước khi gọi API
        []
    );

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        fetchSuggestedTags(value);
    };

    const handleAddTag = (tag) => {
        if (tag && !tags.includes(tag)) {
            setTags([...tags, {tag}]);
        }
        setInputValue('');
        setSuggestedTags([]);
        onTagsChange(tags)
    };

    const handleRemoveTag = (tag) => {
        setTags(tags.filter((t) => t !== tag));
        onTagsChange(tags)
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            handleAddTag(inputValue.trim());
        }
    };
    const addMenu = () => {
        const cateMenu = [];

        if (!suggestedTags || suggestedTags.length === 0) {
            return cateMenu; // Trả về mảng rỗng nếu categories không hợp lệ
        }

        // Thêm vào mảng chỉ khi categories có giá trị
        for (let i = 0; i < suggestedTags.length; i++) {
            const suggestTag = suggestedTags[i]; // Đảm bảo truy cập đúng
            cateMenu.push({
                actionName:suggestTag.Tag, // Sửa lại thành category.name
                action: function () {
                    handleAddTag(suggestTag.Tag)
                },
            });
        }
        return cateMenu; // Trả về cateMenu đã được tạo
    };
    const addNewsMenu = addMenu();
    return (
        <div>

            <div className="field">
                <div className="label">Tags</div>
                <input
                    type="text"
                    list={"tags"}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Add or search tags"
                />
                {loading && suggestedTags?.length>0 ?
                <ContextMenu menu={addNewsMenu}
                             position={{top: 40, right: 0}}></ContextMenu> : null}


            </div>
            <div className={"is-flex gap-xs wrap px-m"}>
                {tags.map((tag, index) => (
                    <span className={"chips pr-s active"} key={index} >
            {tag.tag}
                        <div className= "bg-hover-light rounded" onClick={() => handleRemoveTag(tag)} style={{ marginLeft: '5px' }}>
              <Icon icon={"close"}></Icon>
            </div>
          </span>
                ))}
            </div>
        </div>
    );
};

export default TagsInput;

import React, {useState} from 'react';
import {Layout42} from "../../Support/Layout";
import DuckSelect from "../Input/DuckSelect";
import Modal from "../../../../DuckReact/Modal";
import {InputField} from "../../../../DuckReact/Input";

const sizes = [{size: "Medium", value: 1}, {size: "Smaill", value: 2}]

function ModalSection(props) {
    const [modalSize, setModalSize] = useState(0)
    const [modal, setModal] = useState(false)
    const closeModal = () => {
        setModal(false);
    };
    return (
        <div className="section ">
            <Modal size={modalSize === 1 ? "" : "s"} isOpen={modal}>
                <div className="modal-header  ">
                    <h2>Vui lòng điền thông tin của bạn</h2>

                    <div className=" bg-light p-m is-fill-x rounded-s">
                        <p className={"text-s"}> Chúng tôi chỉ sử dụng email của bạn để liên lạc Tất cả các thông
                            tin được giữ bí mật. </p>
                    </div>
                </div>
                <div className="modal-body  is-center ">
                    <div className="is-flex vertical is-fill-x p-m">
                        <InputField onChange={() => {
                        }} label={"Tên của bạn"}></InputField>
                        <InputField onChange={() => {
                        }} label={"Email của bạn"}></InputField>
                    </div>

                </div>

                <div className="modal-bottom  ">
                    <div onClick={() => setModal(false)} className="btn btn-fill">
                        Huỷ
                    </div>
                    <div onClick={() => setModal(false)} className="btn ">
                        Xác nhận
                    </div>
                </div>
            </Modal>
            <div className="title-l">
                Cách sử dụng
            </div>
            <div className="mb-xl">
                <div className="text-m">Modal thường được sử
                    dụng để hiển thị thông điệp, form, hoặc nội dung quan trọng mà yêu cầu sự chú ý hoặc xác nhận từ
                    phía người dùng
                </div>
            </div>
            <Layout42 size={'s'} code={""}>
                <div className={"is-center"}>
                    <div onClick={(e) => setModal(true)} className="btn">Show Modal</div>
                </div>
                <>
                    <DuckSelect data={sizes} placeholder={"Select toast"}
                                child={({item, index,}) => (
                                    <div title={item.size} key={index} className={"p-s"} onClick={() => {
                                        setModalSize(item.value)
                                    }}>
                                        {item.size}
                                    </div>
                                )}/>
                </>
            </Layout42>
        </div>
    );
}

export default ModalSection;
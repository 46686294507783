import React, {useEffect, useState} from "react";
import ImageContextRender from "./Context/ImageContextRender";
import TableContextRender from "./Context/TableContextRender";
import CustomContext from "./Context/CustomContext";
import CustomContextRender from "./Context/CustomContextRender";

function ContextRender({context, key}) {
    const [className, setClassName] = useState("")
    const data = context
    useEffect(() => {
        setClassName(`editable ${data.defaultState.textBold ? "text-bold " : ""} 
        ${data.defaultState.header}  ${data.defaultState.color} 
        ${data.defaultState.quote ? "is-quote" : ""} 
        ${data.defaultState.textItalic ? "text-italic" : ""}
        ${data.defaultState.textIndent ? "text-indent" : ""}
        
        ${data.defaultState.textUnderline ? "text-underline" : ""}
        ${data.defaultState.textUppercase ? "text-uppercase" : ""}
        ${data.defaultState.textAlign} 
    `)
        // eslint-disable-next-line
    }, [context])
    return (
        <div key={key} className={` is-relative gapy-m`}>
            <div id={data.id}
                 className={className}>
                {data.defaultState.image ?<ImageContextRender  context={data}></ImageContextRender>
                    : null}
                {data.defaultState.table  ? <TableContextRender  context={data}>
                    </TableContextRender>
                    :  null}
                {data.defaultState.isComponent  ? <CustomContextRender context={data}/>
                    :  null}
                {data.defaultState.table || data.defaultState.image? null
                    :    <div  dangerouslySetInnerHTML={{ __html: data.content }}>
                    </div>
                }
            </div>
        </div>
    );
}

export default ContextRender;

import React from 'react';
import {SlideElement} from "../Duck/DuckDesignSystem/animate/AnimateElement";
import {Icon} from "../Duck/DuckDesignSystem/Source/DuckIcon";
import pdf from "./cv2024.pdf"
import GsapCarousel from "../menu-item/GsapCarousel";
function AllElement(props) {
    const GraphicListElement = [{
        id: 3,
        title: "Where ?",
        description: "I'm from NgheAn, Vietnam",
        content: "Note, text editor, custom component",
        image: "https://64.media.tumblr.com/530878c592cd3c3bfde1d6cc5dd63749/e5d8ed8d7ece2675-7a/s640x960/3b759a52ba2625fef02a36f4433482a325005ef5.pnj",
        ref: "/duck"
    },
        {
            id: 10,
            title: "Who ?",
            description: "Hello, I'm Hiep, but you can call me Hi, just Hi",
            content: "Note, text editor, custom component",
            image: "https://64.media.tumblr.com/e5265fd45f6c31cda42860b20078e066/e5d8ed8d7ece2675-c0/s1280x1920/900bd484f318b76b35eb902e7a5cef4b1256c1ba.pnj",

            ref: "/duck"
        },
    ]
    const ThreeListElement = [
        {
            id: 7,
            title: "UI Guide",
            description: "Determination, resolve",
            content: "Note, text editor, custom component",
            image: "https://64.media.tumblr.com/58ac428607a8a45f44b572fe24b87991/e5d8ed8d7ece2675-1f/s2048x3072/fe85bfd829d20df93aa7c46c8be9043715803ae7.jpg",
            ref: "/duck"
        },
        {
            id: 8,
            title: "Skeleton",
            description:
                "\"Place the user at the center.\"",
            content: "Note, text editor, custom component",
            image: "https://64.media.tumblr.com/bf6bcecfa11ccbac33bad9f6af833602/e5d8ed8d7ece2675-0b/s1280x1920/627e29fe3f3e50d169b5e89fafdffbe0c852d2bc.jpg",
            ref: "/duck"
        }, {
            id: 9,
            title: "Rise of Pink",
            description:"I have to do it myself with the help of others.",
            content: "Note, text editor, custom component",
            image: "https://64.media.tumblr.com/2e03e30e317625853a63ff94eddc3fa9/e5d8ed8d7ece2675-15/s1280x1920/e1b5eb613a59f2380e8b9c528594e71f8749dfe0.pnj",
            ref: "/duck"
        },

        {
            id: 5,
            title: "Begin of Duck",
            description:"I have to become versatile, like a Duck.",
            content: "Note, text editor, custom component",
            image: "https://64.media.tumblr.com/1fdfc9da13f86634a8be76846b350681/e5d8ed8d7ece2675-a4/s2048x3072/7871dcb48623661175b98e0c6fdd30d7b4412d16.pnj",
            ref: "/duck"
        },
        {
            id: 4,
            title: "Begin of Passion",
            description: "I gradually realize my strengths.",
            content: "Note, text editor, custom component",
            image: "https://i.imgur.com/xzOMuop.png",
            ref: "/duck"
        },
        {
            id: 6,
            title: "Disorientation",
            description: "I lost my direction when stepping into the world.",
            content: "Note, text editor, custom component",
            image: "https://64.media.tumblr.com/7cee8231e1bf58adfd7513bdd63a1fa8/e5d8ed8d7ece2675-8a/s2048x3072/42e124f613753a42607bb33e4d6dbe6d69e2b48a.jpg",
            ref: "/duck"
        },

        {
            id: 11,
            title: "\"Nhẫn\"-Patience",
            description: "With normal temperament, I can only be patient.",
            content: "Note, text editor, custom component",
            image: "https://64.media.tumblr.com/d0004056d0b143fb806526f9c64f18f0/e5d8ed8d7ece2675-19/s1280x1920/59c44bb31a8de128e38a5084ff89380f15b576ce.pnj",

            ref: "/duck"
        }, {
            id: 12,
            title: "First Lesson",
            description: "I have learned that if I want something, I have to do it myself.",
            content: "Note, text editor, custom component",
            image: "https://64.media.tumblr.com/27598e18cc0317e7357fc3654f9094bd/e5d8ed8d7ece2675-43/s2048x3072/944deec03b2fc4e6a543b18eba58dea19d47a794.jpg",
            ref: "/duck"
        },
        {
            id: 13,
            title: "Grow up",
            description: "I was born into a loving family ",
            content: "Note, text editor, custom component",
            image: "https://64.media.tumblr.com/971f653c27055fa9b608d38f23f34c70/e5d8ed8d7ece2675-80/s2048x3072/c38a3b86df5547af31cd683e99f2ca89c2d0fa5e.pnj",
            ref: "/duck"
        },
    ]
    const UiListElememt = [{
        id: 0,
        title: "Duck Editor",
        description: "Develop, Contribute",
        content: "Note, text editor, custom component",
        image: "https://64.media.tumblr.com/d76b6e959e66834fd29f02ced4e818e9/a86532753ed5d490-01/s2048x3072/2c44b4432f56012cd7d4178a4acc866af4ffdb82.pnj",
        ref: "/duck"
    },
        {
            id: 1,
            title: "Duck Plugin",
            description: "Accelerate, Optimize",
            content: "Note, text editor, custom component",
            image: "https://i.imgur.com/Dzu04yB.png",
            ref: "/duck"
        },
        {
            id: 2,
            title: "Duck Template",
            description:  "Do as much as possible.",
            content: "Note, text editor, custom component",
            image: "https://i.imgur.com/jYjooVh.png",
            ref: "/duck"
        },
    ]
    const images = [
        { url: 'https://cdn.dribbble.com/userupload/13356208/file/original-2edd712722fe65ab4d47d72b50895514.png?resize=1024x1217' },
        { url: 'https://cdn.dribbble.com/userupload/13356208/file/original-2edd712722fe65ab4d47d72b50895514.png?resize=1024x1217' },
        { url: 'https://cdn.dribbble.com/userupload/13356208/file/original-2edd712722fe65ab4d47d72b50895514.png?resize=1024x1217' },
        { url: 'https://cdn.dribbble.com/userupload/13356208/file/original-2edd712722fe65ab4d47d72b50895514.png?resize=1024x1217' },
        { url: 'https://cdn.dribbble.com/userupload/13356208/file/original-2edd712722fe65ab4d47d72b50895514.png?resize=1024x1217' },
        { url: 'https://cdn.dribbble.com/userupload/13356208/file/original-2edd712722fe65ab4d47d72b50895514.png?resize=1024x1217' },
        { url: 'https://cdn.dribbble.com/userupload/13356208/file/original-2edd712722fe65ab4d47d72b50895514.png?resize=1024x1217' },
    ];
    return (
        <div>
            <div className=" hero has-background is-flex jt-center align-center is-half   ">
                <img src={"https://i.imgur.com/fKfrWXx.png"} alt=""/>
                <div className=" container-xl  is-center ">
                    <div className="text-center  is-sticky">
                        <SlideElement>
                            <div className=" hi-4xl text-bold font-text text-semi"> Hoàng Tuấn Hiệp
                            </div>
                            <div className="text-l font-text">Hi nè</div>
                            <div className="is-flex gapx-xs is-center mt-xxl">
                                <a onClick={()=>window.open(pdf)} className="btn btn-outline"><Icon className={"text-l mr-s"}
                                                                                                    icon={"download"}></Icon>Download CV
                                </a>
                            </div>
                        </SlideElement>

                    </div>
                </div>
            </div>
            <div className=" ">
                <div className="container-xxl ">
                    <div className="border is-sticky   rounded-l is-overflow  pt-l " style={{
                        top: 80,
                        zIndex: 2}}>
                        <GsapCarousel images={images}></GsapCarousel>
                    </div>
                    <div className="is-full-y"></div>
                    {/*<img src={"https://64.media.tumblr.com/52f5c308bda75ecb301f65ecb4c999c8/fc429e0a856c916b-d7/s2048x3072/e0b033577037d9d00d84efaa210678b48f047c66.pnj"} alt=""/>*/}

                </div>
            </div>



        </div>
    );
}

export default AllElement;
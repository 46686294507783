import React from 'react';

function Kimnhan(props) {
    return (
        <svg className="    size-s-x2" width="126" height="64" viewBox="0 0 126 64"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_14304_1840)">
                <path
                    d="M32.0078 62.4911C45.794 56.3944 56.0745 41.9148 56.0745 41.9148C56.0745 41.9148 73.6863 53.9481 79.0438 61.2794"
                    stroke="#0000001f" stroke-width="4" stroke-miterlimit="10"/>
                <path
                    d="M64.5415 48.4001C78.32 42.3034 88.6082 27.8237 88.6082 27.8237C88.6082 27.8237 120.928 51.5322 123.557 61.0507"
                    stroke="#0000001f" stroke-width="4" stroke-miterlimit="10"/>
                <path
                    d="M62.4913 1.52417C78.6607 1.52417 94.1679 7.94745 105.601 19.381C117.035 30.8145 123.458 46.3216 123.458 62.4911H1.52441C1.52441 46.3216 7.94769 30.8145 19.3812 19.381C30.8147 7.94745 46.3219 1.52417 62.4913 1.52417Z"
                    stroke="#0000001f" stroke-width="4" stroke-miterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0_14304_1840">
                    <rect width="125.02" height="64" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Kimnhan;


const Fogo = () => {
    return (
        <svg className="    size-s-x2" width="42" height="62" viewBox="0 0 42 62"
             fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.3157 10.6667C13.4935 10.6667 4.35809 7.82222 0.0914286 0C-1.33079 25.6 14.2042 19.9111 21.3157 25.6C21.2067 27.7333 15.7359 31.2889 7.91364 24.8889C10.047 43.3778 24.2692 40.5333 26.4025 39.1111C26.4025 41.9556 19.2914 55.5974 8.62475 61.1556C18.5803 59.0222 39.8041 45.5111 41.2264 29.8667C42.6486 14.2222 29.138 10.6667 21.3157 10.6667Z"
                fill="#0000001f"/>
        </svg>
    );
};
const HineStudio = () => {
    return (
        <svg className="    size-s-x2" width="64" height="64" viewBox="0 0 100 64"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.2659 20.3321C32.5006 20.3321 35.4272 20.9996 38.0457 22.3345C40.6642 23.6181 42.718 25.6462 44.2069 28.4187C45.7473 31.1913 46.5174 34.7854 46.5174 39.2009V63.2298H29.1119V42.2045C29.1119 39.586 28.6498 37.7376 27.7256 36.6594C26.8014 35.5299 25.5435 34.9651 23.9518 34.9651C22.7709 34.9651 21.667 35.2475 20.6402 35.8122C19.6646 36.3257 18.8688 37.1985 18.2527 38.4308C17.6879 39.663 17.4055 41.3317 17.4055 43.4368V63.2298H0V6.08423H17.4055V33.4248L13.2467 29.882C14.8897 26.6987 17.0975 24.3112 19.87 22.7196C22.6939 21.1279 25.8259 20.3321 29.2659 20.3321Z"
                fill="#0000001f"/>
            <path
                d="M55.6167 63.2298V21.1023H73.0222V63.2298H55.6167ZM64.3194 17.7136C61.1361 17.7136 58.5946 16.8664 56.6949 15.1721C54.7952 13.4777 53.8453 11.3726 53.8453 8.85679C53.8453 6.34095 54.7952 4.23586 56.6949 2.54151C58.5946 0.847171 61.1361 0 64.3194 0C67.5027 0 70.0443 0.795828 71.944 2.38748C73.8437 3.97914 74.7936 6.03289 74.7936 8.54873C74.7936 11.2186 73.8437 13.4264 71.944 15.1721C70.0443 16.8664 67.5027 17.7136 64.3194 17.7136Z"
                fill="#0000001f"/>
            <path
                d="M90.1329 63.9999C87.2577 63.9999 84.8445 63.0758 82.8934 61.2274C80.9424 59.3277 79.9668 56.9145 79.9668 53.9879C79.9668 51.01 80.9424 48.6225 82.8934 46.8255C84.8445 45.0284 87.2577 44.1299 90.1329 44.1299C93.0081 44.1299 95.4213 45.0284 97.3724 46.8255C99.3234 48.6225 100.299 51.01 100.299 53.9879C100.299 56.9145 99.3234 59.3277 97.3724 61.2274C95.4213 63.0758 93.0081 63.9999 90.1329 63.9999Z"
                fill="#0000001f"/>
        </svg>
    );
};
const GlobalSafe = ()=><svg className="    size-s-x2" width="116" height="64" viewBox="0 0 116 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14304_909)">
        <path
            d="M7.17013 61.2975H8.18778V63.3518C7.61253 63.7749 6.92602 64.0013 6.22267 63.9999C5.87877 64.0244 5.53371 63.9748 5.20893 63.8541C4.88414 63.7335 4.58655 63.5442 4.33464 63.2983C4.08273 63.0523 3.88188 62.7549 3.74456 62.4244C3.60724 62.0938 3.53638 61.7374 3.53638 61.377C3.53638 61.0167 3.60724 60.6602 3.74456 60.3297C3.88188 59.9992 4.08273 59.7017 4.33464 59.4557C4.58655 59.2098 4.88414 59.0206 5.20893 58.8999C5.53371 58.7792 5.87877 58.7296 6.22267 58.7542C6.60201 58.7345 6.98087 58.802 7.33253 58.952C7.68419 59.102 8.00009 59.3308 8.25796 59.6223L7.53274 60.3193C7.37604 60.1349 7.18308 59.9881 6.96706 59.8889C6.75104 59.7897 6.51706 59.7405 6.28115 59.7446C5.86855 59.7446 5.47285 59.916 5.1811 60.2209C4.88934 60.5259 4.72544 60.9396 4.72544 61.3709C4.72544 61.8022 4.88934 62.2159 5.1811 62.5209C5.47285 62.8259 5.86855 62.9972 6.28115 62.9972C6.58671 63.0028 6.8886 62.9271 7.15844 62.7771L7.17013 61.2975Z"
            fill="#0000001f"/>
        <path
            d="M9.22876 58.8396H10.3985V62.9481H12.8315V63.9018H9.32234L9.22876 58.8396Z"
            fill="#0000001f"/>
        <path
            d="M13.0308 61.3709C13.0301 61.0135 13.0996 60.6597 13.2348 60.3314C13.37 60.0031 13.5681 59.7072 13.8168 59.462C14.0656 59.2169 14.3598 59.0276 14.6813 58.9058C15.0028 58.7841 15.3447 58.7324 15.686 58.7542C16.0299 58.7296 16.375 58.7792 16.6997 58.8999C17.0245 59.0206 17.3221 59.2098 17.574 59.4557C17.8259 59.7017 18.0268 59.9992 18.1641 60.3297C18.3014 60.6602 18.3723 61.0167 18.3723 61.377C18.3723 61.7374 18.3014 62.0938 18.1641 62.4244C18.0268 62.7549 17.8259 63.0523 17.574 63.2983C17.3221 63.5442 17.0245 63.7335 16.6997 63.8541C16.375 63.9748 16.0299 64.0244 15.686 63.9999C15.3441 64.0199 15.002 63.9666 14.6804 63.8435C14.3589 63.7204 14.0649 63.5299 13.8163 63.2837C13.5677 63.0376 13.3698 62.741 13.2347 62.4121C13.0996 62.0831 13.0302 61.7288 13.0308 61.3709V61.3709ZM17.2066 61.3709C17.1997 61.0582 17.1047 60.7545 16.9335 60.498C16.7622 60.2414 16.5224 60.0434 16.244 59.9287C15.9656 59.814 15.661 59.7877 15.3684 59.8531C15.0758 59.9185 14.8081 60.0728 14.599 60.2965C14.3898 60.5202 14.2484 60.8034 14.1925 61.1107C14.1367 61.418 14.1687 61.7357 14.2848 62.0241C14.4009 62.3124 14.5957 62.5585 14.845 62.7316C15.0942 62.9047 15.3868 62.9971 15.686 62.9972C15.8901 63.0025 16.093 62.9637 16.2821 62.8834C16.4712 62.8031 16.6424 62.6828 16.7851 62.5302C16.9278 62.3776 17.0389 62.1959 17.1114 61.9965C17.1839 61.797 17.2163 61.584 17.2066 61.3709V61.3709Z"
            fill="#0000001f"/>
        <path
            d="M23.6399 62.5201C23.6399 63.4005 22.9732 63.9018 21.6982 63.9018H19.1833V58.8396H21.5228C22.6925 58.8396 23.3592 59.3654 23.3592 60.1602C23.3629 60.3901 23.3045 60.6165 23.1907 60.8133C23.0769 61.0102 22.9123 61.1694 22.7159 61.2729C22.986 61.3333 23.2269 61.4916 23.396 61.7199C23.5652 61.9482 23.6516 62.2316 23.6399 62.5201V62.5201ZM20.3063 59.72V60.9428H21.4175C21.9672 60.9428 22.2597 60.7349 22.2597 60.3436C22.2597 59.9523 21.9672 59.7444 21.4175 59.7444L20.3063 59.72ZM22.5053 62.3612C22.5053 61.9332 22.1895 61.7375 21.6163 61.7375H20.3063V62.9603H21.6163C22.2246 63.0215 22.5053 62.838 22.5053 62.3612Z"
            fill="#0000001f"/>
        <path
            d="M27.6874 62.8258H25.4299L24.9971 63.9018H23.8274L25.9914 58.8396H27.1611L29.2431 63.9018H28.0734L27.6874 62.8258ZM27.3248 61.9332L26.5645 59.989L25.7925 61.9332H27.3248Z"
            fill="#0000001f"/>
        <path
            d="M29.792 58.8396H30.9617V62.9481H33.3947V63.9018H29.8856L29.792 58.8396Z"
            fill="#0000001f"/>
        <path
            d="M35.5593 63.4129L35.9453 62.5202C36.411 62.8643 36.9659 63.052 37.5361 63.0583C38.2029 63.0583 38.4719 62.8259 38.4719 62.508C38.4719 61.5543 35.6529 62.2146 35.6529 60.3315C35.6529 59.4633 36.3196 58.7541 37.6999 58.7541C38.289 58.7398 38.8709 58.8918 39.3843 59.1943L39.0334 60.0869C38.6292 59.8371 38.1694 59.7022 37.6999 59.6956C37.0332 59.6956 36.7758 59.9524 36.7758 60.2703C36.7758 61.1997 39.5948 60.5638 39.5948 62.4224C39.5948 63.2661 38.9164 63.9998 37.5361 63.9998C36.8359 64.0148 36.1475 63.8103 35.5593 63.4129V63.4129Z"
            fill="#0000001f"/>
        <path
            d="M43.5601 62.8258H41.3026L40.8698 63.9018H39.7703L41.9342 58.8396H43.0454L45.2094 63.9018H44.0397L43.5601 62.8258ZM43.2092 61.9332L42.4372 59.989L41.6652 61.9332H43.2092Z"
            fill="#0000001f"/>
        <path
            d="M46.7884 59.7811V61.1384H49.0342V62.0799H46.7884V63.9263H45.6187V58.8396H49.2915V59.7811H46.7884Z"
            fill="#0000001f"/>
        <path
            d="M53.8068 62.9603V63.9018H50.0989V58.8396H53.8068V59.7811H51.2101V60.8816H53.4676V61.7865H51.2101V63.0092L53.8068 62.9603Z"
            fill="#0000001f"/>
        <path
            d="M56.4032 61.3709C56.4011 61.0151 56.4687 60.6627 56.6019 60.3353C56.7351 60.0079 56.931 59.7125 57.1776 59.4673C57.4242 59.2221 57.7162 59.0323 58.0357 58.9097C58.3552 58.787 58.6953 58.7341 59.0351 58.7541C59.4111 58.737 59.786 58.8082 60.1322 58.9627C60.4784 59.1172 60.787 59.351 61.0352 59.6468L60.31 60.3437C60.1675 60.162 59.9894 60.0145 59.7875 59.9111C59.5857 59.8077 59.365 59.7509 59.1403 59.7446C58.7277 59.7446 58.332 59.9159 58.0403 60.2209C57.7485 60.5259 57.5846 60.9395 57.5846 61.3709C57.5846 61.8022 57.7485 62.2158 58.0403 62.5208C58.332 62.8258 58.7277 62.9971 59.1403 62.9971C59.365 62.9908 59.5857 62.934 59.7875 62.8306C59.9894 62.7272 60.1675 62.5797 60.31 62.398L61.0352 63.095C60.6845 63.5172 60.2203 63.8195 59.7031 63.9624C59.1859 64.1054 58.6395 64.0824 58.1349 63.8964C57.6303 63.7105 57.1909 63.3702 56.8738 62.9198C56.5566 62.4694 56.3764 61.9297 56.3564 61.3709H56.4032Z"
            fill="#0000001f"/>
        <path
            d="M61.3746 61.3714C61.3465 60.8171 61.478 60.2668 61.7522 59.7914C62.0264 59.3161 62.4306 58.9376 62.9126 58.7048C63.3947 58.472 63.9324 58.3957 64.4564 58.4857C64.9803 58.5756 65.4665 58.8277 65.852 59.2094C66.2375 59.5911 66.5047 60.0849 66.619 60.6269C66.7334 61.169 66.6896 61.7344 66.4935 62.2502C66.2973 62.766 65.9577 63.2084 65.5185 63.5204C65.0793 63.8324 64.5608 63.9996 64.0298 64.0004C63.6875 64.0221 63.3446 63.9702 63.0224 63.8477C62.7001 63.7252 62.4053 63.5348 62.1564 63.2883C61.9074 63.0418 61.7095 62.7445 61.575 62.4147C61.4406 62.085 61.3723 61.7299 61.3746 61.3714V61.3714ZM65.5504 61.3714C65.5435 61.0587 65.4485 60.755 65.2773 60.4985C65.106 60.2419 64.8662 60.0439 64.5878 59.9292C64.3094 59.8145 64.0048 59.7882 63.7122 59.8536C63.4196 59.919 63.1519 60.0733 62.9428 60.297C62.7336 60.5207 62.5922 60.8039 62.5363 61.1112C62.4805 61.4185 62.5125 61.7362 62.6286 62.0246C62.7446 62.3129 62.9395 62.559 63.1887 62.7321C63.438 62.9052 63.7306 62.9976 64.0298 62.9977C64.2299 62.9963 64.4278 62.9526 64.6113 62.8693C64.7949 62.786 64.9605 62.6647 65.0982 62.5128C65.2358 62.3609 65.3427 62.1815 65.4124 61.9854C65.4821 61.7892 65.5131 61.5804 65.5036 61.3714H65.5504Z"
            fill="#0000001f"/>
        <path
            d="M70.6275 63.9018L69.6917 62.4957H68.6507V63.9018H67.481V58.8396H69.5864C70.8848 58.8396 71.6919 59.5366 71.6919 60.6737C71.7053 61.0155 71.6169 61.3532 71.4388 61.6402C71.2607 61.9271 71.0017 62.1492 70.6976 62.2756L71.7855 63.9018H70.6275ZM69.5747 59.7934H68.6507V61.5541H69.5747C70.2531 61.5541 70.6041 61.224 70.6041 60.6737C70.6041 60.1235 70.2531 59.7934 69.5747 59.7934V59.7934Z"
            fill="#0000001f"/>
        <path
            d="M76.8266 60.6737C76.8266 61.8109 76.0195 62.5079 74.7211 62.5079H73.7503V63.9018H72.5806V58.8396H74.6743C76.0312 58.8396 76.8266 59.5366 76.8266 60.6737ZM75.6569 60.6737C75.6569 60.1235 75.3177 59.7934 74.6275 59.7934H73.7152V61.5541H74.6275C75.3528 61.5541 75.692 61.224 75.692 60.6737H75.6569Z"
            fill="#0000001f"/>
        <path
            d="M77.3882 61.3714C77.3602 60.8171 77.4917 60.2668 77.7659 59.7914C78.0401 59.3161 78.4443 58.9376 78.9263 58.7048C79.4084 58.472 79.9461 58.3957 80.47 58.4857C80.994 58.5756 81.4801 58.8277 81.8656 59.2094C82.2511 59.5911 82.5183 60.0849 82.6327 60.6269C82.747 61.169 82.7033 61.7344 82.5071 62.2502C82.3109 62.766 81.9713 63.2084 81.5322 63.5204C81.093 63.8324 80.5744 63.9996 80.0435 64.0004C79.7012 64.0221 79.3583 63.9702 79.036 63.8477C78.7137 63.7252 78.419 63.5348 78.17 63.2883C77.9211 63.0418 77.7232 62.7445 77.5887 62.4147C77.4542 62.085 77.386 61.7299 77.3882 61.3714V61.3714ZM81.5641 61.3714C81.5572 61.0587 81.4622 60.755 81.2909 60.4985C81.1197 60.2419 80.8799 60.0439 80.6015 59.9292C80.3231 59.8145 80.0185 59.7882 79.7259 59.8536C79.4333 59.919 79.1656 60.0733 78.9564 60.297C78.7473 60.5207 78.6059 60.8039 78.55 61.1112C78.4941 61.4185 78.5262 61.7362 78.6423 62.0246C78.7583 62.3129 78.9532 62.559 79.2024 62.7321C79.4517 62.9052 79.7442 62.9976 80.0435 62.9977C80.2475 63.003 80.4504 62.9642 80.6396 62.8839C80.8287 62.8035 80.9999 62.6833 81.1426 62.5307C81.2853 62.3781 81.3963 62.1964 81.4688 61.997C81.5414 61.7975 81.5738 61.5845 81.5641 61.3714V61.3714Z"
            fill="#0000001f"/>
        <path
            d="M86.64 63.9018L85.7042 62.4957H84.6631V63.9018H83.4934V58.8396H85.5989C86.8973 58.8396 87.7044 59.5366 87.7044 60.6737C87.7179 61.0155 87.6294 61.3532 87.4513 61.6402C87.2732 61.9271 87.0142 62.1492 86.7101 62.2756L87.798 63.9018H86.64ZM85.5872 59.7934H84.6631V61.5541H85.5872C86.2657 61.5541 86.6166 61.224 86.6166 60.6737C86.6166 60.1235 86.2657 59.7934 85.5872 59.7934V59.7934Z"
            fill="#0000001f"/>
        <path
            d="M91.8337 62.8258H89.5762L89.1551 63.9018H87.9854L90.1493 58.8396H91.319L93.483 63.9018H92.3133L91.8337 62.8258ZM91.4828 61.9332L90.7108 59.989L89.9388 61.9332H91.4828Z"
            fill="#0000001f"/>
        <path
            d="M94.7463 59.7934H93.1555V58.8396H97.3782V59.7934H95.8342V63.9018H94.7463V59.7934Z"
            fill="#0000001f"/>
        <path
            d="M99.9512 61.3714C99.9232 60.8171 100.055 60.2668 100.329 59.7914C100.603 59.3161 101.007 58.9376 101.489 58.7048C101.971 58.472 102.509 58.3957 103.033 58.4857C103.557 58.5756 104.043 58.8277 104.429 59.2094C104.814 59.5911 105.081 60.0849 105.196 60.6269C105.31 61.169 105.266 61.7344 105.07 62.2502C104.874 62.766 104.534 63.2084 104.095 63.5204C103.656 63.8324 103.137 63.9996 102.606 64.0004C102.264 64.0221 101.921 63.9702 101.599 63.8477C101.277 63.7252 100.982 63.5348 100.733 63.2883C100.484 63.0418 100.286 62.7445 100.152 62.4147C100.017 62.085 99.949 61.7299 99.9512 61.3714V61.3714ZM104.127 61.3714C104.12 61.0587 104.025 60.755 103.854 60.4985C103.683 60.2419 103.443 60.0439 103.164 59.9292C102.886 59.8145 102.581 59.7882 102.289 59.8536C101.996 59.919 101.729 60.0733 101.519 60.297C101.31 60.5207 101.169 60.8039 101.113 61.1112C101.057 61.4185 101.089 61.7362 101.205 62.0246C101.321 62.3129 101.516 62.559 101.765 62.7321C102.015 62.9052 102.307 62.9976 102.606 62.9977C102.809 62.9996 103.009 62.9584 103.195 62.8765C103.382 62.7947 103.55 62.6739 103.69 62.5217C103.83 62.3694 103.939 62.1889 104.01 61.9911C104.081 61.7933 104.113 61.5825 104.104 61.3714H104.127Z"
            fill="#0000001f"/>
        <path
            d="M110.561 58.8396V63.9018H109.637L107.216 60.8327V63.9018H106.046V58.8396H106.982L109.392 61.921V58.8396H110.561Z"
            fill="#0000001f"/>
        <path
            d="M15.5801 14.135C15.025 14.8632 14.2638 15.3892 13.4055 15.6378C12.5472 15.8865 11.6357 15.845 10.8012 15.5194C9.9668 15.1939 9.25221 14.6008 8.75965 13.8251C8.2671 13.0493 8.0218 12.1306 8.05883 11.2004C8.07217 10.1572 8.42811 9.1506 9.06698 8.34917C9.70586 7.54773 10.5889 7.00022 11.568 6.7984C11.7939 6.78497 12.0204 6.78497 12.2464 6.7984C12.8209 6.77697 13.3934 6.88118 13.9272 7.10438C14.4609 7.32757 14.9442 7.66482 15.3461 8.09454C15.7438 8.58365 15.0654 9.24395 14.656 8.74261C14.2955 8.3114 13.8299 7.99043 13.3088 7.81387C12.7877 7.6373 12.2305 7.61175 11.6966 7.73994C10.8892 7.93061 10.1743 8.41834 9.68222 9.11417C9.19016 9.80999 8.95377 10.6675 9.01617 11.5304C9.07857 12.3932 9.43561 13.2039 10.0221 13.8145C10.6087 14.425 11.3856 14.7949 12.2113 14.8565C12.7009 14.87 13.187 14.767 13.6328 14.5552C14.0786 14.3433 14.4725 14.0282 14.7847 13.6337V11.7873H12.4452C12.3289 11.7873 12.2173 11.739 12.1351 11.653C12.0528 11.567 12.0066 11.4504 12.0066 11.3288C12.0066 11.2072 12.0528 11.0905 12.1351 11.0045C12.2173 10.9185 12.3289 10.8702 12.4452 10.8702H15.2058C15.5918 10.8702 15.7087 11.0781 15.7087 11.3838V13.6826C15.7242 13.8452 15.678 14.0077 15.5801 14.135Z"
            fill="#0000001f"/>
        <path
            d="M28.9385 15.6638C28.5876 15.6638 28.4355 15.4926 28.4355 15.1625V7.25111C28.4355 7.11491 28.4873 6.98428 28.5794 6.88797C28.6716 6.79166 28.7965 6.73755 28.9268 6.73755C29.0571 6.73755 29.1821 6.79166 29.2742 6.88797C29.3663 6.98428 29.4181 7.11491 29.4181 7.25111V14.7589H34.3426C34.4574 14.7589 34.5674 14.8066 34.6486 14.8915C34.7298 14.9763 34.7754 15.0914 34.7754 15.2114C34.7754 15.3314 34.7298 15.4464 34.6486 15.5313C34.5674 15.6161 34.4574 15.6638 34.3426 15.6638H28.9385Z"
            fill="#0000001f"/>
        <path
            d="M43.9927 11.1391C43.9703 10.0913 44.3144 9.071 44.9605 8.26955C45.6066 7.46811 46.5102 6.94062 47.5018 6.78606C47.9635 6.71878 48.432 6.71878 48.8937 6.78606C49.8961 6.93385 50.8134 7.45624 51.4758 8.25654C52.1381 9.05685 52.5007 10.0809 52.4964 11.1391C52.5282 11.7402 52.4426 12.3418 52.245 12.9072C52.0473 13.4726 51.7417 13.9901 51.3467 14.4282C50.9517 14.8663 50.4756 15.2158 49.9473 15.4555C49.419 15.6951 48.8497 15.82 48.2738 15.8224C47.6901 15.8394 47.1094 15.7282 46.5693 15.4961C46.0292 15.264 45.5417 14.916 45.1383 14.4747C44.7348 14.0333 44.4244 13.5084 44.2271 12.9339C44.0298 12.3594 43.9499 11.7479 43.9927 11.1391ZM44.9752 11.1391C44.9369 11.6114 44.9929 12.0869 45.1395 12.5354C45.2861 12.9839 45.5202 13.3957 45.827 13.7447C46.1338 14.0938 46.5065 14.3724 46.9217 14.563C47.3368 14.7536 47.7853 14.852 48.2387 14.852C48.6921 14.852 49.1406 14.7536 49.5557 14.563C49.9709 14.3724 50.3436 14.0938 50.6504 13.7447C50.9572 13.3957 51.1913 12.9839 51.3379 12.5354C51.4845 12.0869 51.5405 11.6114 51.5022 11.1391C51.5181 10.3179 51.25 9.51823 50.7472 8.88703C50.2444 8.25582 49.5406 7.83545 48.7651 7.70314C48.3767 7.65199 47.9837 7.65199 47.5954 7.70314C46.8238 7.83796 46.128 8.26835 45.6436 8.91039C45.1592 9.55243 44.9209 10.3601 44.9752 11.1758V11.1391Z"
            fill="#0000001f"/>
        <path
            d="M68.8023 15.6637H65.3751C65.3121 15.6653 65.2494 15.6539 65.1906 15.6302C65.1318 15.6065 65.0781 15.5709 65.0325 15.5255C64.9869 15.4801 64.9502 15.4257 64.9247 15.3655C64.8992 15.3053 64.8853 15.2404 64.8838 15.1746V7.39771C64.8853 7.33187 64.8992 7.26698 64.9247 7.20676C64.9502 7.14654 64.9869 7.09217 65.0325 7.04675C65.0781 7.00133 65.1318 6.96575 65.1906 6.94204C65.2494 6.91834 65.3121 6.90697 65.3751 6.9086H68.4982C69.6679 6.9086 71.6213 7.33657 71.6213 9.29301C71.6352 9.64273 71.5447 9.9883 71.3624 10.2819C71.18 10.5754 70.9149 10.8025 70.6036 10.9315C71.0687 11.0384 71.4844 11.3095 71.7805 11.6993C72.0767 12.0891 72.2353 12.5736 72.2295 13.0714C72.2295 15.1134 70.4633 15.6637 68.8023 15.6637ZM65.8546 7.80123V10.6136H68.545C69.7147 10.6136 70.6621 10.2957 70.6621 9.29301C70.6621 8.29033 69.317 7.80123 68.4982 7.80123H65.8546ZM65.8546 14.771H68.8023C69.972 14.771 71.2353 14.4531 71.2353 13.0714C71.2353 11.9831 70.3463 11.5062 69.2 11.5062H65.8546V14.771Z"
            fill="#0000001f"/>
        <path
            d="M84.4174 13.3525L83.353 15.5291C83.1191 16.0426 82.1833 15.688 82.4757 15.1255L86.4644 6.93295C86.505 6.85094 86.5676 6.78302 86.6444 6.73744C86.7212 6.69185 86.8091 6.67057 86.8972 6.67617C86.9837 6.67143 87.0697 6.69321 87.1445 6.73883C87.2193 6.78445 87.2797 6.85192 87.3183 6.93295L91.2369 15.0889C91.5878 15.688 90.6052 16.0304 90.3596 15.4924L89.3302 13.3525H84.4174ZM86.8972 8.1435L84.8151 12.4843H88.9676L86.8972 8.1435Z"
            fill="#0000001f"/>
        <path
            d="M103.18 15.6638C102.829 15.6638 102.677 15.4926 102.677 15.1625V7.25111C102.677 7.11491 102.729 6.98428 102.821 6.88797C102.913 6.79166 103.038 6.73755 103.169 6.73755C103.299 6.73755 103.424 6.79166 103.516 6.88797C103.608 6.98428 103.66 7.11491 103.66 7.25111V14.7589H108.584C108.699 14.7589 108.809 14.8066 108.89 14.8915C108.971 14.9763 109.017 15.0914 109.017 15.2114C109.017 15.3314 108.971 15.4464 108.89 15.5313C108.809 15.6161 108.699 15.6638 108.584 15.6638H103.18Z"
            fill="#0000001f"/>
        <path
            d="M68.3336 20.3341H85.5517V18.1942H68.3336C67.8642 18.1599 67.3936 18.2404 66.9591 18.4293C66.5246 18.6182 66.1382 18.9103 65.8305 19.2825C65.5655 19.6623 65.377 20.0944 65.2765 20.5526C65.1759 21.0108 65.1654 21.4856 65.2456 21.9481V52.5786H67.2926V35.2152H85.5517V33.0876H67.2926V21.8503V21.6302C67.2179 21.284 67.2722 20.921 67.4447 20.6153C67.6955 20.4059 68.0138 20.3052 68.3336 20.3341Z"
            fill="#0000001f"/>
        <path
            d="M91.3775 50.4021C91.2125 50.4203 91.0457 50.4037 90.887 50.3533C90.7282 50.3029 90.5808 50.2197 90.4534 50.1087C90.2863 49.801 90.2323 49.4401 90.3013 49.0938V48.8737V35.2153H108.584V33.0876H88.2777V48.7758C88.1996 49.2385 88.2111 49.7128 88.3115 50.1707C88.412 50.6286 88.5994 51.0607 88.8626 51.4415C89.1709 51.8169 89.5588 52.1118 89.9954 52.3028C90.432 52.4939 90.9053 52.5758 91.3775 52.542H108.584V50.4021H91.3775Z"
            fill="#0000001f"/>
        <path d="M108.572 18.1946H88.2659V20.3344H108.572V18.1946Z" fill="#0000001f"/>
        <path
            d="M51.1512 21.1052C50.9052 20.4825 50.486 19.9515 49.9479 19.5809C49.4098 19.2102 48.7776 19.0171 48.1333 19.0264V21.1541C48.3813 21.1359 48.6283 21.2008 48.8387 21.3393C49.049 21.4778 49.2116 21.6827 49.303 21.9244C49.8879 23.404 58.4034 45.8297 61.0001 52.4938H63.1641C61.0001 46.9668 51.7711 22.6825 51.1512 21.1052Z"
            fill="#0000001f"/>
        <path
            d="M45.1038 21.1047C44.4488 22.6821 35.2666 46.9664 33.1494 52.579H35.3485C37.8867 45.8659 46.4022 23.4402 47.0455 22.0096C47.1369 21.7679 47.2996 21.5629 47.5099 21.4244C47.7202 21.2859 47.9673 21.221 48.2153 21.2392V19.026C47.5552 18.9965 46.9025 19.1803 46.3458 19.5522C45.7891 19.9241 45.3555 20.4662 45.1038 21.1047Z"
            fill="#0000001f"/>
        <path
            d="M24.5633 18.3416C23.4479 17.8534 22.2469 17.6154 21.038 17.6427C19.8291 17.6701 18.6392 17.9623 17.545 18.5005C16.5152 19.0426 15.5941 19.7855 14.8313 20.6893H28.5871C27.3526 19.7216 25.9992 18.932 24.5633 18.3416V18.3416Z"
            fill="#0000001f"/>
        <path
            d="M12.9838 23.917C12.7934 24.589 12.6834 25.2832 12.6562 25.9835H33.1729C32.558 24.8729 31.8242 23.8392 30.9856 22.9021H13.3581C13.2162 23.2332 13.0912 23.572 12.9838 23.917V23.917Z"
            fill="#0000001f"/>
        <path
            d="M14.8085 31.3517H35.1615C34.948 30.2936 34.6426 29.2581 34.2491 28.2581H12.937C13.3177 29.4296 13.9592 30.4899 14.8085 31.3517V31.3517Z"
            fill="#0000001f"/>
        <path
            d="M18.7147 34.2377C20.0482 34.8928 21.301 35.7139 22.4461 36.6832H35.4649C35.5468 35.6538 35.5468 34.619 35.4649 33.5896H17.7205C18.048 33.8097 18.3872 34.0053 18.7147 34.2377Z"
            fill="#0000001f"/>
        <path
            d="M25.7337 42.0636H34.1556L34.3778 41.5867C34.676 40.7337 34.9145 39.8591 35.0913 38.97H24.4236C25.0396 39.9076 25.4838 40.9566 25.7337 42.0636V42.0636Z"
            fill="#0000001f"/>
        <path
            d="M25.5464 46.9908C25.5464 47.1376 25.4528 47.2721 25.406 47.4188H30.8568C31.6927 46.4764 32.4299 45.4434 33.0559 44.3374H25.9675C25.9368 45.2371 25.7953 46.129 25.5464 46.9908V46.9908Z"
            fill="#0000001f"/>
        <path
            d="M21.6045 52.7383C24.0215 52.2372 26.3073 51.1988 28.3069 49.6936H24.3416C23.6148 50.8748 22.6871 51.9067 21.6045 52.7383V52.7383Z"
            fill="#0000001f"/>
        <path
            d="M11.0068 52.0408C12.1222 52.5289 13.3231 52.7669 14.532 52.7396C15.741 52.7122 16.9309 52.42 18.025 51.8818C19.0302 51.3534 19.931 50.6316 20.6803 49.7542H7.01807C8.24133 50.6966 9.57864 51.4655 10.9951 52.0408H11.0068Z"
            fill="#0000001f"/>
        <path
            d="M22.5635 46.4653C22.7538 45.81 22.8638 45.132 22.891 44.4478H2.39771C3.02468 45.56 3.76599 46.5974 4.60846 47.5414H22.1541C22.3175 47.1945 22.4544 46.8347 22.5635 46.4653V46.4653Z"
            fill="#0000001f"/>
        <path
            d="M20.774 39.0188H0.397705C0.611448 40.0782 0.920858 41.1141 1.32178 42.1124H22.6222C22.2532 40.9408 21.6192 39.8796 20.774 39.0188Z"
            fill="#0000001f"/>
        <path
            d="M16.7971 36.1331C15.4874 35.4741 14.2581 34.6531 13.1359 33.6875H0.0819203C-0.00582239 34.7167 -0.00582239 35.752 0.0819203 36.7811H17.8849C17.5457 36.5366 17.1831 36.3287 16.7971 36.1331Z"
            fill="#0000001f"/>
        <path
            d="M9.80151 28.271H1.37959C1.30941 28.43 1.22753 28.5767 1.16905 28.7356C0.861326 29.5958 0.615012 30.4786 0.432129 31.3768H11.1467C10.5126 30.4409 10.0559 29.3866 9.80151 28.271V28.271Z"
            fill="#0000001f"/>
        <path
            d="M10.0007 23.3423C10.0007 23.1956 10.0943 23.0488 10.1411 22.9021H4.67853C3.84144 23.8508 3.10057 24.8875 2.46777 25.9957H9.57961C9.60347 25.0953 9.74515 24.2025 10.0007 23.3423V23.3423Z"
            fill="#0000001f"/>
        <path
            d="M13.9424 17.5833C11.5224 18.0864 9.23318 19.1245 7.22827 20.628H11.2053C11.9321 19.4468 12.8598 18.4148 13.9424 17.5833V17.5833Z"
            fill="#0000001f"/>
        <path d="M97.9863 58.8396H99.1561V63.9018H97.9863V58.8396Z" fill="#0000001f"/>
        <path
            d="M115.801 3.33778C115.827 3.7956 115.764 4.25424 115.614 4.68549C115.465 5.11674 115.233 5.51148 114.932 5.84543C114.631 6.17938 114.268 6.44548 113.866 6.62737C113.463 6.80926 113.029 6.90309 112.59 6.90309C112.151 6.90309 111.717 6.80926 111.314 6.62737C110.912 6.44548 110.549 6.17938 110.248 5.84543C109.947 5.51148 109.715 5.11674 109.566 4.68549C109.416 4.25424 109.353 3.7956 109.379 3.33778C109.376 2.89537 109.458 2.45677 109.619 2.04785C109.781 1.63893 110.019 1.26796 110.32 0.956836C110.621 0.64571 110.978 0.400721 111.371 0.236319C111.764 0.0719163 112.184 -0.00857064 112.608 -0.000395247C113.029 -0.00869611 113.448 0.0719769 113.839 0.236797C114.23 0.401617 114.585 0.647198 114.883 0.958845C115.181 1.27049 115.416 1.6418 115.574 2.05056C115.732 2.45932 115.809 2.89712 115.801 3.33778V3.33778ZM115.181 3.33778C115.181 2.62108 114.908 1.93373 114.424 1.42694C113.939 0.920157 113.281 0.635448 112.596 0.635448C111.91 0.635448 111.253 0.920157 110.768 1.42694C110.283 1.93373 110.011 2.62108 110.011 3.33778C109.995 3.70054 110.05 4.06289 110.173 4.40239C110.297 4.74189 110.485 5.05132 110.728 5.31152C110.97 5.57172 111.261 5.77715 111.582 5.9151C111.903 6.05304 112.248 6.12056 112.596 6.11348C112.943 6.11696 113.287 6.04708 113.607 5.90804C113.928 5.769 114.218 5.56367 114.46 5.30439C114.703 5.04511 114.893 4.73724 115.019 4.39928C115.144 4.06132 115.204 3.70025 115.193 3.33778H115.181ZM113.613 3.96139L114.385 5.18417H113.461L112.795 4.12036H112.175V5.18417H111.227V1.51585H112.76C113.719 1.51585 114.292 2.02941 114.292 2.83644C114.296 3.07106 114.235 3.30188 114.117 3.50136C113.998 3.70083 113.828 3.86053 113.625 3.96139H113.613ZM112.678 3.38669C113.099 3.38669 113.344 3.17882 113.344 2.82421C113.344 2.46961 113.099 2.23728 112.678 2.23728H112.14V3.38669H112.678Z"
            fill="#0000001f"/>
    </g>
    <defs>
        <clipPath id="clip0_14304_909">
            <rect width="115.813" height="64" fill="white"/>
        </clipPath>
    </defs>
</svg>
const VuongSoft = ()=>  <svg className="    size-s-x2" width="64" height="64" viewBox="0 0 64 64"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM11.04 23.0512C13.0703 25.1025 17.6724 29.2049 19.838 29.2049C22.0037 29.2049 24.3499 24.1908 25.2522 21.6837C26.6058 24.1908 29.8543 29.2049 32.02 29.2049C34.1857 29.2049 37.4342 23.735 38.7877 21C39.9157 23.735 42.5777 29.2049 44.2019 29.2049C45.8262 29.2049 50.7441 25.1025 53 23.0512C51.6465 29.8887 48.0303 41.4863 46 42.17C43.4318 43.0349 21.1916 43.0883 19.838 42.4045C18.4845 41.7208 10.3632 29.8887 11.04 23.0512Z"
          fill="#0000001f"/>
</svg>
const More = ()=><svg className="    size-s-x2" width="37" height="10" viewBox="0 0 37 10" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
    <path
        d="M4.92117 6.47461V8.96484H3.08938V6.47461H0.761719V4.6875H3.08938V2.20898H4.92117V4.6875H7.24884V6.47461H4.92117Z"
        fill="#0000001f"/>
    <path
        d="M17.8837 9H15.9994V3.7207H15.9586L13.876 8.91211H12.6509L10.5741 3.7207H10.5274V9H8.6431V0.544922H11.1166L13.2401 5.97656H13.2868L15.4102 0.544922H17.8837V9Z"
        fill="#0000001f"/>
    <path
        d="M22.0724 9.15234C20.0714 9.15234 18.823 7.93359 18.823 5.80664C18.823 3.7207 20.1006 2.4668 22.0724 2.4668C24.0558 2.4668 25.3218 3.71484 25.3218 5.80664C25.3218 7.93945 24.0733 9.15234 22.0724 9.15234ZM22.0724 7.62891C22.7782 7.62891 23.2274 6.97852 23.2274 5.8125C23.2274 4.66406 22.7782 3.99023 22.0724 3.99023C21.3723 3.99023 20.9231 4.66406 20.9231 5.8125C20.9231 6.97852 21.3606 7.62891 22.0724 7.62891Z"
        fill="#0000001f"/>
    <path
        d="M26.121 9V2.61914H28.1336V3.82617H28.1745C28.4078 2.96484 28.9212 2.52539 29.6679 2.52539C29.8779 2.52539 30.0529 2.55469 30.1929 2.60742V4.37695C30.0179 4.30664 29.7787 4.25977 29.5337 4.25977C28.6645 4.25977 28.192 4.75195 28.192 5.64258V9H26.121Z"
        fill="#0000001f"/>
    <path
        d="M33.664 3.94336C33.0281 3.94336 32.5556 4.41797 32.5031 5.08008H34.8133C34.7782 4.40039 34.3174 3.94336 33.664 3.94336ZM34.8599 6.98438H36.7267C36.5284 8.30273 35.3616 9.15234 33.699 9.15234C31.6747 9.15234 30.4671 7.9043 30.4671 5.8418C30.4671 3.7793 31.6864 2.4668 33.629 2.4668C35.5425 2.4668 36.7617 3.72656 36.7617 5.67773V6.26367H32.4739V6.39258C32.4739 7.1543 32.9815 7.68164 33.7282 7.68164C34.2824 7.68164 34.7141 7.41797 34.8599 6.98438Z"
        fill="#0000001f"/>
</svg>
export {Fogo,HineStudio,GlobalSafe,VuongSoft,More};
import React, {useState} from 'react';
import {Icon} from "../../DuckIcon";
import {Layout42, Layout42Main} from "../../Support/Layout";
import {NewsModel} from "../../../../../Model/NewsModel";
import {Link} from "react-router-dom";
import {truncate} from "../../../../../Api/Auth/Services/Api";
import {Segment} from "../Input/DuckSelect";

const paper = {
    "limit": 7,
    "news": [
        {
            "title": "Tiêu đề bài viếtd",
            "content": "Mô tả bài viết",
            "like": 0,
            "unLike": 0,
            "isUpdate": false,
            "CreateAt": "2023-11-28T04:32:35.648548Z"
        },
        {
            "title": "đây là tiêu đề",
            "content": "Mô tả bài viết",
            "like": 0,
            "unLike": 0,
            "isUpdate": false,
            "CreateAt": "2023-11-28T04:32:35.648548Z"
        },
        {
            "title": "Tiêu đề bài viết",
            "content": "Mô tả bài viết",
            "categoryStr": "test",
            "like": 0,
            "unLike": 0,
            "isUpdate": false,
            "CreateAt": "2023-11-20T17:38:50.135334Z"
        },
        {

            "title": "đây là tiêu đề xá",
            "content": "Mô tả bài viết",
            "categoryStr": "test",
            "like": 0,
            "unLike": 0,
            "CreateAt": "2023-11-28T04:32:35.648548Z",
            "isUpdate": false,
        },
        {
            "title": "Bài Viết Forest",
            "content": "Mô tả bài viết",
            "like": 0,
            "unLike": 0,
            "isUpdate": false,
            "CreateAt": "0001-01-01T00:00:00Z"
        },
        {
            "title": "From Blender",
            "content": "to Your Website using React-Fiber",
            "thumbnail": "https://i.imgur.com/fKfrWXx.png",
            "like": 0,
            "unLike": 0,
            "createByID": "",
            "isUpdate": false,
            "CreateAt": "0001-01-01T00:00:00Z"
        },
        {
            "title": "From Blender",
            "content": "to Your Website using React-Fiber",
            "thumbnail": "https://i.imgur.com/fKfrWXx.png",
            "like": 0,
            "unLike": 0,
            "createByID": "",
            "isUpdate": false,
            "CreateAt": "0001-01-01T00:00:00Z"
        },
        {
            "title": "From Blender",
            "content": "to Your Website using React-Fiber",
            "thumbnail": "https://i.imgur.com/fKfrWXx.png",
            "like": 0,
            "unLike": 0,
            "createByID": "",
            "isUpdate": false,
            "CreateAt": "0001-01-01T00:00:00Z"
        },
        {
            "title": "From Blender",
            "content": "to Your Website using React-Fiber",
            "thumbnail": "https://i.imgur.com/fKfrWXx.png",
            "like": 0,
            "unLike": 0,
            "createByID": "",
            "isUpdate": false,
            "CreateAt": "0001-01-01T00:00:00Z"
        },
        {
            "title": "From Blender",
            "content": "to Your Website using React-Fiber",
            "thumbnail": "https://i.imgur.com/fKfrWXx.png",
            "like": 0,
            "unLike": 0,
            "createByID": "",
            "isUpdate": false,
            "CreateAt": "0001-01-01T00:00:00Z"
        }
    ],
    "page": 1
}
const tableString = <div className=" table-section border" >
    <table className="table-not-border">
        <thead>
        <tr>
            <th className="px-s "> Stt</th>
            <th className="px-s text-left "> Tiêu đề</th>
            <th className="p-s">
                <div className=" icon icon-up-down " />
            </th>
            <th className="p-s">
                <div className=" icon icon-like" />
            </th>
            <th className="p-s">
                <div className=" icon icon-unlike " />
            </th>
            <th className="p-m" />
        </tr>
        </thead>
        <tbody>
        <tr>
            <td className="p-s text-center">1</td>
            <td className="p-s ">
                <div className="is-block size-x-l-x2">
                    <a className="title-m" >
                        Tiêu đề bài viếtd
                    </a>
                    <div className="text-s">Mô tả bài viết</div>
                </div>
            </td>
            <td className="p-s text-right">
                {" "}
                <input disabled="" type="checkbox" defaultChecked="" />
            </td>
            <td className="p-s text-right">0</td>
            <td className="p-s text-center"> 0 </td>
            <td className="p-s is-relative">
                <div className="is-flex gapx-s jt-end ">
                    <button className="btn btn-ghost btn-s">
                        <div className=" icon icon-context-menu " />
                    </button>
                </div>
            </td>
        </tr>
        <tr>
            <td className="p-s text-center">2</td>
            <td className="p-s ">
                <div className="is-block size-x-l-x2">
                    <a className="title-m" >
                        đây là tiêu đề
                    </a>
                    <div className="text-s">Mô tả bài viết</div>
                </div>
            </td>
            <td className="p-s text-right">
                {" "}
                <input disabled="" type="checkbox" defaultChecked="" />
            </td>
            <td className="p-s text-right">0</td>
            <td className="p-s text-center"> 0 </td>
            <td className="p-s is-relative">
                <div className="is-flex gapx-s jt-end ">
                    <button className="btn btn-ghost btn-s">
                        <div className=" icon icon-context-menu " />
                    </button>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</div>

function TableSection(props) {
    const [border,setBorder]= useState("")
    const [direction, setDirection] = useState(0)
    return (
        <div className={"section"}>
            <div className="title-xxl my-l">Bảng</div>
            <Layout42Main>
                <>
                    <div className="text-m">
                        "Hero section" chủ yếu có tác dụng tạo ra một ấn tượng mạnh mẽ và thu hút sự chú ý người
                        dùng ngay từ khi họ truy cập trang web hoặc ứng dụng.
                        <br/>Nó thường đóng vai trò quan trọng
                        trong việc truyền đạt thông điệp chính và thúc đẩy hành động từ phía người dùng.
                    </div>

                    <div className="text-m mt-l">Dưới đây là một ví dụ:</div>
                </>
                <>
                    <Layout42 size={"l"} code={tableString}>
                        <div className=" table-section border" style={{width:"450px",height:"540px"}}>
                            <table className={`${border==="Non Border"?"table-not-border":border==="Border"?"":"table-strip-border"}`}>
                                <thead>
                                <tr>
                                    <th className={"px-s "}> Stt</th>
                                    <th className={"px-s text-left "} > Tiêu đề</th>



                                    <th className={"p-s"}>
                                        <Icon className={"text-l text-right"} icon={"up-down"}></Icon>
                                    </th>
                                    <th className={"p-s"}>
                                        <Icon className={"text-l text-right"} icon={"like "}/>
                                    </th>
                                    <th className={"p-s"}>
                                        <Icon className={"text-l text-center"} icon={"unlike "}/>
                                    </th>

                                    <th className={"p-m"}>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {paper.news.map((item, index) => {
                                    var news = new NewsModel(item)
                                    return (
                                        <tr key={index}>
                                            <td className={"p-s text-center"}>{index + 1}</td>
                                            <td className={"p-s "}>
                                                <div className="is-block size-x-l-x2">
                                                    <Link className="title-m"
                                                          to={"/doc/".concat(news.Uuid)} target="_blank">
                                                        {news.Title}
                                                    </Link>
                                                    <div className="text-s">
                                                        {truncate(news.Content, 250)}
                                                    </div>
                                                </div>

                                            </td>
                                            <td className={"p-s text-right"}>  <input disabled checked type="checkbox"/></td>
                                            <td className={"p-s text-right"}>{news.UnLike}</td>
                                            <td className={"p-s text-center"}> {news.Like} </td>
                                            <td className={"p-s is-relative"}>
                                                <div className="is-flex gapx-s jt-end ">
                                                    <button
                                                        className="btn btn-ghost btn-s">
                                                        <Icon className={"text-l"} icon={"context-menu"}/>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>

                        </div>
                        <div>
                            <Segment handleClick={() => {
                            }} segment={[{action: "Non Border"}, {action: "Strip"}, {action: "Border"},]}
                                     child={({item, index}) => (
                                         <div key={index} className={`btn ${direction === index ? "active" : ""}`}
                                              onClick={() => {
                                                  setDirection(index)
                                                  setBorder(item.action)
                                              }}>{item.action}</div>)}
                            >
                            </Segment>
                        </div>
                    </Layout42>
                </>

            </Layout42Main>
        </div>
    );
}

export default TableSection;
import React from "react";
import NotFound from "../../slide/NotFound";

function DevelopElement() {
  return (
    <>
      <div className="hero is-half   is-flex align-center jt-center">
        <div className="hero-content text-center pb-xxl">
          <div className="hi-5xl text-bold hi-uppercase ">D.evelop</div>
          <div className="text-l hi-text-semi">
            I loves to translate user story into practice
          </div>
        </div>
      </div>
      <NotFound></NotFound>
    </>
  );
}

export { DevelopElement };

import React, {useRef, useState} from 'react';
import {WorkTimeline} from "../slide/Item";
import CustomHoverTooltip from "../Duck/DuckDesignSystem/Source/Support/CustomHoverTooltip.js"


function ShortTimeline() {
    const byStartTime = WorkTimeline.timelines.slice(0)
    byStartTime.sort(function (a, b) {
        return a.schedule.startTime - b.schedule.startTime;
    })
    return byStartTime
}

function TimelineLong(startTime, endTime) {
    let startYear = startTime.getFullYear();
    let startMonth = startTime.getMonth();
    let endYear = endTime.getFullYear();
    let endMonth = endTime.getMonth();

    return (endYear - startYear) * 12 + (endMonth - startMonth);
}

function Work(props) {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = 2020; year <= currentYear; year++) {
        years.push(year);
    }
    const ourRef = useRef(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const mouseCoords = useRef({
        startX: 0,
        scrollLeft: 0
    });
    const [isVisible, setIsVisible] = useState(false);

    // const handleDragStart = (e) => {
    //     if (!ourRef.current) return
    //     const slider = ourRef.current.children[0];
    //     const startX = e.pageX - slider.offsetLeft;
    //     const scrollLeft = slider.scrollLeft;
    //     mouseCoords.current = { startX, scrollLeft }
    //     setIsMouseDown(true)
    //     document.body.style.cursor = "grabbing"
    // }
    // const handleDragEnd = () => {
    //     setIsMouseDown(false)
    //     if (!ourRef.current) return
    //     document.body.style.cursor = "default"
    // }
    // const handleDrag = (e) => {
    //     if (!isMouseDown || ! ourRef.current) return;
    //     e.preventDefault();
    //     const slider = ourRef.current.children[0];
    //     const x = e.pageX - slider.offsetLeft;
    //     const walkX = (x - mouseCoords.current.startX);
    //     slider.scrollLeft = -mouseCoords.current.scrollLeft -walkX;
    // }
    const onMouseEnter = (index)=>{
        setIsVisible(true)
        setData(byDate[index])
    }
    const onMouseOut = ()=>{
        setIsVisible(false)
    }
    var count = 0
    const byDate = ShortTimeline()
    const [data,setData] = useState(byDate[0])
    var startTime = new Date("2020-01-01")
    return (
        <div  ref={ourRef} className="Main border is-relative   align-center is-flex ">
            <div className="columns time-line">
                <div  className=" font-text text-bold text-black " style={{width: "2800px"}}>
                    <CustomHoverTooltip visible={isVisible}>
                        <div className="border   p-m bg-white">
                            <div className="columns unwrap align-center">
                                <img src={data.img} className={"size-l"} alt=""/>
                                <div className="title-xs unwrap">{data.title}</div>
                            </div>
                            <div className="py-s">
                                <div className="text-xs">{data.description}</div>
                                <div className="title-s">{data.content}</div>
                            </div>

                            <div className="grid-2">
                                {   data.project.map((item,index)=>
                                    <div className={"p-s bg-light rounded-s"}>
                                        <img src={item.image} alt="" className="size-y-m-x2 "/>
                                    </div>
                                )}

                            </div>
                        </div>
                    </CustomHoverTooltip>
                    <div  className="schedule">
                        {byDate.map((item, index) => {
                            var workTime = TimelineLong(item.schedule.startTime, item.schedule.endTime)
                            var freeTime = TimelineLong(startTime, item.schedule.startTime)
                            if (workTime >= 1000) {
                                workTime = 48 - count
                            }
                            count += workTime + freeTime
                            startTime = new Date(startTime.setMonth(startTime.getMonth() + workTime + freeTime))
                            return <>
                                {freeTime !== 0 ?
                                    <div className="" style={{gridColumn: `span ${freeTime}`}}></div> : null}
                                <div onMouseEnter={()=>onMouseEnter(index)} onMouseLeave={onMouseOut} key={index} className="bg-light columns is-relative rounded-s border  p-m "
                                     style={{gridColumn: `span ${workTime}`}}>
                                    <div  className=" is-sticky "  style={{left:"16px"}}>
                                        <div className="is-block is-sticky" >
                                            <div className="text-xs text-disable mb-s">
                                                {item.schedule.startTime.getMonth() + 1}/{item.schedule.startTime.getFullYear()}
                                                -{item.schedule.endTime.getFullYear() === 2999 ? "nay" : `${item.schedule.endTime.getMonth() + 1}/${item.schedule.endTime.getFullYear()}`}
                                            </div>
                                            <div className="columns unwrap align-center">
                                                <img src={item.img} className={"size-l"} alt=""/>
                                                <div className="title-m unwrap">{item.title}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        })}
                    </div>
                    <div className="stand columns unwrap align-end text-sliver">
                        {years.map((year) => (
                            <div className="col hi-xl px-l" key={year}>
                                <div>{year}</div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Work;
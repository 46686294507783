import React, {useRef, useState} from "react";
import {Icon} from "../../../Duck/DuckDesignSystem/Source/DuckIcon";
import {handleUploadImage} from "../../../Api/Auth/Services/Image/Image";
import {useToast} from "../../../Duck/DuckReact/Toast/useToast";


function DragDropFile() {
    // drag state
    const [dragActive, setDragActive] = useState(false);
    // ref
    const inputRef = useRef(null);
    const [filePreview, setFilePreview] = useState();
    const [file, setFile] = useState();
    const [uploaded, setUpload] = useState(false)
    const [loading, setLoading] = useState(false)
    // handle drag events
    const toast = useToast()
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFilePreview(URL.createObjectURL(e.target.files[0]));
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            setFilePreview(URL.createObjectURL(e.target.files[0]));
            setFile(e.target.files[0]);
        }
    };
    function handleFile(files) {
        setFilePreview(URL.createObjectURL(files[0]));
        setFile(files[0]);
    }

// triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };
    const onXClick = () => {
        setLoading(false)
        setUpload(false)
        setFilePreview(undefined)
    }
    const onUpload = async () => {
        setLoading(true)
        handleUploadImage(file).then(async function (message) {
            if (message.status === 200) {
                console.log(message.status)
                await setLoading(false)
                await toast.success("Tải lên thành công!")
                await setFilePreview()
            } else {
                toast.error("Tải lên thất bại!")
                setLoading(false)
            }
        }).catch(async err => {
            await setLoading(false)
            toast.error(err.message)
            await setFilePreview()
        })
    }
    return (
        <div className="card  vertical ">
            <form onEncrypted className=" form-file-upload p-m is-fill-y" onDragEnter={handleDrag}
                  onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} accept="image/*" type="file" className="input-file-upload" multiple={false}
                       onChange={handleChange}/>
                {filePreview === undefined ? <>
                    <label htmlFor="input-file-upload"
                           className={dragActive ? "drag-active p-l label-file-upload-l" : "p-l label-file-upload-l"}>
                        <div>
                            <Icon className="text-xxl text-sliver" icon="upload"></Icon>
                            <div className="gap-xs">
                                <p>Drag & drop</p>
                                <p>or <span className="upload-button text-primary text-bold"
                                            onClick={onButtonClick}>Browser</span></p>
                            </div>
                        </div>
                    </label>
                    <div className="is-flex jt-between p-s">
                        <p className={"text-xs text-disable"}>Supported image/*</p>
                        <p className={"text-xs text-disable"}>Maximum size: 12Mb</p>
                    </div>
                </> : <div className={`file-upload ${loading ? 'on-upload' : ""}`}>
                    {
                        uploaded === false ? <div className="loading">
                        </div> : ""
                    }

                    <img onClick={onButtonClick} className="border is-fill-y" src={filePreview}/>
                    <div onClick={onXClick} className="size-xl  is-center rounded  p-xs  control-file-upload">
                        <Icon className="text-xxl text-light is-absolute" icon="multiply"></Icon>
                    </div>
                </div>}
                {dragActive &&
                    <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag}
                         onDragOver={handleDrag}
                         onDrop={handleDrop}></div>}
            </form>
            <div className="card-content p-m">
                <div onClick={() => onUpload()}
                     className={`btn btn-primary is-fill-x ${filePreview === undefined ? "disable" : ""}`}>Upload
                </div>
            </div>
        </div>

    );
};
export default DragDropFile;

import {useParams} from "react-router-dom";
import {useState} from "react";
import {useEffect} from "react";
import {NewsModel} from "../../../Model/NewsModel";
import Viewer from "./Viewer";
import {getNews} from "../../../Api/Auth/Services/News/NewsApi";
import {WithRouter} from "../../withRouter";

function ViewNews() {
    const {customUrl} = useParams();
    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (customUrl) {
            getNews(customUrl).then((data) => {
                setNews(data)
                setLoading(true)
            });
        }
        document.title = news.Title;

    }, [customUrl])
    return <div className="container">
        {loading ? <Viewer news={news}/> : <div>Loading...</div>}
    </div>

}


export default WithRouter(ViewNews)

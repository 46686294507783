import React, {useEffect, useRef, useState} from "react";
import {useOutsideClick} from "../../../slide/checker";
import {useDispatch, useSelector} from "react-redux";
import {updateContent} from "../../../redux/Editor/editorSlice";
import {useToast} from "../../DuckReact/Toast/useToast";

function TableContext({context}) {
    const dispatch = useDispatch()
    const tableContext = useSelector(state => state.editor.listContext[state.editor.listContext.findIndex(ct => ct.id === context.id)])
    const cellRefs = useRef({});
    const ref = useRef(null)
    const toast=useToast()
    const [menu, setMenu] = useState(false)
    const [hasBorder, setBorder] = useState(true)
    const [hasStripper, setStripper] = useState(false)
    const [hasTitle, setTitle] = useState(true)
    const [isHover, setHover] = useState(false)
    const [activeCell,setActiveCell] = useState({row:0,cell:0})

    const addRow = (index) => {
        let newData = [...tableContext.content];
        console.log("insert to ", index, "data", newData[0])
        if (index !== -1) {
            newData.splice(index, 0, newData[0]);
        } else {
            newData.splice(0, 0, newData[0]);
        }
        console.log(newData)
        // TODO add to -1 ??
        // setData([...data, data[0]]);
        dispatch(updateContent({id: context.id, updateContent: [...newData]}))
    }
    const deleteRow = (index) => {
        console.log("delete row ", index)
        if (tableContext.content.length===2){
            toast.error("Không thể xóa hàng duy nhất của bảng")
        }
        else {
            let newData = [...tableContext.content];
            newData.splice(index, 1)
            // setData([...data, data[0]]);
            dispatch(updateContent({id: context.id, updateContent: [...newData]}))
        }

    }
    const addColumn = (index) => {
        let newData = [...tableContext.content.map((subarray) => {
            const newSubarray = subarray.slice();
            newSubarray.splice(index, 0, "x");
            return newSubarray;
        })]
        dispatch(updateContent({id: context.id, updateContent: [...newData]}))
    }
    const deleteColumn = (index) => {
        console.log("delete column ", index);
        console.log(tableContext.content[0].length)
        if (tableContext.content[0].length===1){
            toast.error("Không thể xóa cột duy nhất của bảng")
        }
        else {
            // Create a new array without the specified column
            const newData = [...tableContext.content].map((row) => [...row.slice(0, index), ...row.slice(index + 1)]);
            dispatch(updateContent({ id: context.id, updateContent: newData }));
        }

    };
    const clickMenu = (row, cell) => {
        if (!e) var e = window.event;
        e.stopPropagation();
        if (menu.row !== row && menu.cell !== cell) {
            setMenu({row: row, cell: cell})
        } else
            setMenu(false)
    }
    const contextMenu = (rowVal, columnVal) => {
        return [
            {
                actionName: "Insert row",
                icon: "icon-row",
                shortcut: "Ctr+K",
                listAction: [
                    {
                        actionName: "Insert above",
                        icon: "icon-insert-above",
                        shortcut: "",
                        x: rowVal,
                        action: function () {
                            console.log(rowVal)
                            addRow(rowVal - 1)
                        }
                    },
                    {
                        actionName: "Insert below",
                        icon: "icon-insert-below",
                        shortcut: "",
                        action: function () {
                            addRow(rowVal)
                        }
                    },
                ]
            },
            {
                actionName: "Insert column",
                icon: "icon-column",
                shortcut: "Ctr+K",
                listAction: [
                    {
                        actionName: "Insert left",
                        icon: "icon-insert-left",
                        shortcut: "",
                        action: function () {
                            addColumn(columnVal)
                        }
                    },
                    {
                        actionName: "Insert right",
                        icon: "icon-insert-right",
                        shortcut: "",
                        action: function () {
                            addColumn(columnVal + 1)
                        }
                    },
                ]
            },
            {
                actionName: "delete",
                icon: "icon-trash-can",
                shortcut: "",
                listAction: [
                    {
                        actionName: "Delete row",
                        icon: "icon-trash-can",
                        shortcut: "",
                        action: function () {
                            deleteRow(rowVal)
                        }
                    },
                    {
                        actionName: "Delete column",
                        icon: " icon-trash-can",
                        shortcut: "",
                        action: function () {
                            deleteColumn(columnVal)
                        }
                    },
                ]
            }
        ]
    }
    const setEndOfContentEditable = (contentEditableElement) => {
        var range, selection;
        if (document.createRange) {
            // IE 9+ và các trình duyệt khác
            range = document.createRange();
            range.selectNodeContents(contentEditableElement);
            range.collapse(false);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        } else if (document.selection) {
            // IE 8 và thấp hơn
            range = document.body.createTextRange();
            range.moveToElementText(contentEditableElement);
            range.collapse(false);
            range.select();
        }
    };
    const tableMenu = () => {
        return [{
            actionName: hasTitle ? "Turn off Header" : "Turn on header",
            icon: "icon-table",
            shortcut: "",
            action: function () {
                setTitle(!hasTitle)
            }
        },
            {
                actionName: hasBorder ? "Turn off border" : "Turn on border",
                icon: "icon-table",
                shortcut: "",
                action: function () {
                    setBorder(!hasBorder)
                }
            },
            {
                actionName: "Stripper",
                icon: "icon-pen",
                shortcut: "",
                action: function () {
                    setStripper(!hasStripper)
                }
            }]
    }
    const handleInputChange = (row, cell) => {
        const element = cellRefs.current[`${row}-${cell}`];
        if (element) {
            const newData = tableContext.content.map((rowArray, rowIndex) =>
                rowIndex === row
                    ? rowArray.map((cellValue, cellIndex) =>
                        cellIndex === cell ? element.innerHTML : cellValue
                    )
                    : rowArray
            );
            setActiveCell({row:row,cell:cell})
            dispatch(updateContent({id: context.id, updateContent: newData}));
        } else {
            console.error(`Ref for cell ${row}-${cell} not found.`);
        }
    };

    const refClick = useOutsideClick(clickMenu);

    useEffect(() => {
        // Đặt vị trí con trỏ vào cuối sau mỗi cập nhật
        if (cellRefs.current[`${activeCell.row}-${activeCell.cell}`])
        setEndOfContentEditable( cellRefs.current[`${activeCell.row}-${activeCell.cell}`]);
    }, [tableContext.content]);



    return <div contentEditable={false} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}
                className="table-section  is-relative" style={{overflowX:"visible"}}>
        <table ref={ref} contentEditable={false} id="resizeMe"
               className={`table ${hasBorder ? "" : "table-not-border"} ${hasStripper ? "table-striped" : ""}`}>
            {hasTitle ? <thead>
            <tr>
                {tableContext.content[0].map((cellData, cell) => {
                    return (
                        <th key={cell} className="cell-editor">
                            <div contentEditable={true}
                                 suppressContentEditableWarning={true}
                                 ref={(el) => (cellRefs.current[`${0}-${cell}`] = el)}
                                 onInput={() => handleInputChange(0, cell)}
                                 dangerouslySetInnerHTML={{__html: cellData}}
                                 className="is-fill-x p-s supeenty"></div>
                        </th>
                    )
                })}
            </tr>
            </thead> : null}
            <tbody>{tableContext.content.slice(hasTitle ? 1 : 0).map((rowData, row) => (
                <tr key={row}>{rowData.map((cellData, cell) => (
                    <td key={cell}
                        className="cell-editor is-relative ">
                        <div contentEditable={true}
                             suppressContentEditableWarning={true}
                             ref={(el) => (cellRefs.current[`${row+1}-${cell}`] = el)}
                             onInput={() => handleInputChange(row+1, cell)}
                             dangerouslySetInnerHTML={{__html: cellData}}
                             className="is-fill-x p-s supeenty"></div>
                        <div data-widget="cell-widget"
                             className={`cell-selector is-absolute  ${menu.row === row && menu.cell === cell ? "is-open" : null}`}
                             style={{top: "4px", right: "4px", zIndex: 2}}>
                            <div onClick={() => clickMenu(row, cell)}
                                 className={`px-xs rounded-xs border text-l icon icon-context-menu bg-white text-sliver `}>
                            </div>
                            {menu.row === row && menu.cell === cell ?
                                <ContextMenu menu={contextMenu(row, cell)}
                                             position={{top: 48, right: 4}}></ContextMenu> : null}
                        </div>
                    </td>
                ))}</tr>
            ))}</tbody>
        </table>
        <div data-widget="table-widget" contentEditable={false}
             className={`cell-selector is-absolute  ${menu.row === -1 && menu.cell === -1 ? "is-open" : ""}`}
             style={{top: "4px", left: "-24px", zIndex: 2}}>
            <div onClick={() => clickMenu(-1, -1)}
                 style={{display: `${isHover || menu.row === -1 && menu.cell === -1 ? "flex" : "none"}`}}
                 className={`px-xs rounded-xs border text-l icon icon-context-menu bg-white text-sliver `}>
            </div>
            {menu.row === -1 && menu.cell === -1 ?
                <ContextMenu menu={tableMenu()}
                             position={{top: 4, right: -250}}></ContextMenu> : null}
        </div>
    </div>

}

export default TableContext
const ContextMenu = ({menu, position}) => {
    const [isActive, setActive] = useState(false)
    const [childPosition, setPosition] = useState({top: 0, right: 0})
    const handleClick = (e, index) => {
        if (isActive === index) {
            setActive(false)
        } else {
            setActive(index)
            e.stopPropagation()
        }
    }
    return (
        <div style={{top: `${position.top}px`, right: `${position.right}px`, zIndex: 2}}
             className={`context-menu  select-expand`}>
            {menu.map((item, index) => (
                <div onClick={item.listAction ? (e) => {
                        handleClick(e, index)
                    }
                    : item.action
                } className="row">
                    <div className="menu-item">
                        {item.icon ? <div className={`icon ${item.icon}`}/> : ""}

                        {item.actionName}
                    </div>
                    <div className="menu-action">
                        {item.shortcut}
                        {item.listAction ? <div className={`px-s py-xs  icon icon-right-direction text-disable `}>
                        </div> : null}

                    </div>
                    {
                        isActive === index && <div style={{top: "0", right: "-102%", zIndex: 2}}
                                                   className={`context-menu ${isActive && "hidden-element"}`}>
                            {item.listAction.map((childAction, childIndex) => (
                                <div key={childIndex} onClick={childAction.action} className="row">
                                    <div className="menu-item">
                                        <div className={`icon ${childAction.icon}`}>
                                        </div>
                                        {childAction.actionName}
                                    </div>
                                </div>))}
                        </div>
                    }
                </div>))}
        </div>
    );
};
export {ContextMenu}


import React from 'react';
import {useSelector} from "react-redux";
import ContextRender from "./ContextRender";

function RenderEditor(props) {
    const listContext = useSelector(state => state.editor.listContext);
    return (
        <div>
            {listContext.map((context, index) =>
                <ContextRender key={context.id} context={context}>
                </ContextRender>)}
        </div>
    );
}
export function PreviewRender({data}){
    console.log(data)
    const listContext = JSON.parse(data).listContext
    return (
        <div>
            {listContext.map((context, index) =>
                <ContextRender key={context.id} context={context}>
                </ContextRender>)}
        </div>
    );
}
export default RenderEditor;
import React, {useRef, useState} from "react";
import {createCategory, deleteCategory} from "../../../../Api/Auth/Services/News/CategoryApi";
import {Icon} from "../../../../Duck/DuckDesignSystem/Source/DuckIcon";
import Modal from "../../../../Duck/DuckReact/Modal";

const CategorySection = ({categories, loading}) => {
    const [modal, setModal] = useState(false)
    const inputRef = useRef(null);

    const [updated, setUpdated] = useState('');
    const openModal = () => {
        setModal(true);
        if (modal) {
            document.body.style.overflow = 'hidden'
        }
    };
    const closeModal = () => {
        setModal(false);

    };
    const onUpload = () => {
        createCategory(inputRef.current.value)
    }

    return <>
        <Modal size={"s"} isOpen={modal}>
            <div className="modal-header ">
                <h2>Thêm thư mục</h2>
            </div>
            <div className="modal-body p-m is-flex vertical gapy-s">
                <div className="input-field is-fill-x">
                    <input className="is-fill-x" ref={inputRef} type="text" placeholder={"Thư mục..."}
                           required/>
                </div>


            </div>
            <div className="modal-bottom  ">
                <div onClick={() => setModal(false)} className="btn btn-fill">
                    Huỷ
                </div>
                <div onClick={() => onUpload()} className="btn ">Thêm</div>
            </div>
        </Modal>

        <div className=" is-overflow   is-flex vertical    is-grow">

            <div className="columns p-m layout-4-1">
                <div className="col ">
                    <input className={"is-fill-x"} placeholder={"Search"} type="text"/>
                </div>
                <div className="col">
                    <div onClick={openModal} className="btn   is-fill-x">
                        Thêm
                    </div>
                </div>
            </div>
            <div className="col bg-white  is-flex vertical    is-grow">
                <div className="  table-section   rounded-m">
                    <table className=" table-strip-border bg-white ">
                        <thead>
                        <tr>
                            <th className={"px-m py-s  text-left"}> Thư mục</th>
                            <th className={"p-s"}>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading ? loading : categories.length === 0 ? (
                            <div className="empty-state">Không có hình ảnh nào.</div>
                        ) : categories.map((item, index) => {
                            return <tr className={""} key={index}>
                                <td className={"px-m title-m text-uppercase text-left"}>{item.Name}</td>
                                <td className={"p-s "}>
                                    <div className="is-flex jt-end">
                                        <button onClick={() => deleteCategory(item.Name)}
                                                className="btn btn-ghost btn-s">
                                            <Icon className={"text-l"} icon={"circle"}/>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>

                </div>
            </div>

        </div>

    </>
}
export default CategorySection

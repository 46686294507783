import React, {useState} from "react";
import {Icon} from "../DuckDesignSystem/Source/DuckIcon";


const InputSwitch = ({onChange, checked}) => {
    let atrClass = "input-switch "

    return <label className={atrClass}>
        <input onChange={onChange} checked={checked} type="checkbox"></input>
        <span className={`slider ${checked===false?"disable":""}`}></span>
    </label>
}
const InputField = ({
                        onChange,
                        required,
                        type,
                        disable,
                        inputRef,
                        inline,
                        label,
                        tooltip,
                        clear,
                        placeholder,
                        size,
                        maxLength,
                        help
                    }) => {
    const [text, setText] = useState('');
    const [helper, setHelper] = useState(false);

    const handleChange = (event) => {
        const newText = event.target.value;
        setText(newText);
        console.log(newText)
        onChange(text); // Sử dụng giá trị mới ngay tại đây
    }

    return (
        <div className={`field ${inline ? "has-label-inline" : ""} ${required ? "is-required" : ""} ${tooltip ? "has-info" : ""}`}>
            {label ? <div className="label">{label}</div> : null}

            <input
                onFocus={() => setHelper(true)}
                onBlur={() => setHelper(false)}
                value={text}
                onChange={e=>handleChange(e)}
                disabled={disable}
                ref={inputRef}
                type={type}
                className={` is-${size}`}
                placeholder={placeholder ? placeholder : "Type something..."}
                maxLength={maxLength}
            />

            {help ? <p className={`help show ${helper ? "active" : ""}`}>{help}</p> : ''}
            <div onClick={() => setText('')} className="info-icon">
                <Icon icon={`multiply`} />
            </div>
        </div>
    );
}
export {InputSwitch, InputField};
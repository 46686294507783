import React from "react";
import {Layout42Main} from "../../Support/Layout";

const ColorsSection = () => {
        const variants = {
            main: "main",
            light: "light",
            hover: "hover",
            active: "active",
            dark: "dark",
        }
        const Colors = [
            {
                name: "primary",
                usage: "High priority elements / Negative actions",
                description:
                    "Primary colors indicate to the user that certain elements are interactive—for example, text and icons.",
            },
            {
                name: "secondary",
                usage: "High priority elements / Negative actions",
                description:
                    " For the light mode, there are 3 variants of tint colors: Tint, Tint 2, and Tint Tap State colors.",

            },

        ];
        return (
            <div className="" id="ColorsSection">
                <div className="section" id="gridSection">
                    {Colors.map((color, index) => (
                        <div className=" section  " key={index}>
                            <div className=" title-xl text-uppercase mb-l"> {color.name}</div>
                            <Layout42Main>
                                <>
                                    <div className="mb-l">
                                        <div className="title-l">Description</div>
                                        <div className="text-m">{color.description}</div>
                                    </div>
                                    <div className="mb-l">
                                        <div className="title-l">Usage</div>
                                        <div className="text-m">{color.usage}</div>
                                    </div>
                                </>
                                <div className={"text-white columns s-vertical rounded-s"}>
                                    <div className={` col is-grid  rounded-s p-l  bg-${color.name}`}>
                                        <div className="title-xl">Aa</div>
                                        <div className="">{color.name}</div>
                                    </div>
                                    <div className={`col is-grid rounded-s p-l  bg-${color.name}-light`}>
                                        <div className="title-xl">Aa</div>
                                        <div className="">{color.name}-light</div>
                                    </div>
                                    <div className={`col is-grid rounded-s p-l  bg-${color.name}-hover`}>
                                        <div className="title-xl">Aa</div>
                                        <div className="">{color.name}-hover</div>
                                    </div>
                                    <div className={`col is-grid rounded-s p-l  bg-${color.name}-active`}>
                                        <div className="title-xl">Aa</div>
                                        <div className="">{color.name}-active</div>
                                    </div>
                                    <div className={`col is-grid rounded-s p-l bg-${color.name}-dark `}>
                                        <div className="title-xl">Aa</div>
                                        <div className="">{color.name}-dark</div>
                                    </div>
                                </div>
                            </Layout42Main></div>
                    ))}
                    <div className="section">
                        <div className=" title-xl text-uppercase mb-l"> Shader</div>
                        <Layout42Main>
                            <>
                                <div className="mb-l">
                                    <div className="title-l">Description</div>
                                    <div className="text-m">Shader</div>
                                </div>

                            </>
                            <div className={"text-white grid-5 s-vertical rounded-s"}>
                                <div className={` col is-grid  rounded-s p-l  bg-white border`}>
                                    <div className="title-xl">Aa</div>
                                    <div className="">white</div>
                                </div>
                                <div className={` col is-grid  rounded-s p-l  bg-light`}>
                                    <div className="title-xl">Aa</div>
                                    <div className="">light</div>
                                </div>
                                <div className={` col is-grid  rounded-s p-l  bg-sliver`}>
                                    <div className="title-xl">Aa</div>
                                    <div className="">silver</div>
                                </div>
                                <div className={` col is-grid  rounded-s p-l  bg-disable`}>
                                    <div className="title-xl">Aa</div>
                                    <div className="">disable</div>
                                </div>
                                <div className={` col is-grid  rounded-s p-l  bg-mute`}>
                                    <div className="title-xl">Aa</div>
                                    <div className="">mute</div>
                                </div>
                                <div className={` col is-grid  rounded-s p-l  bg-outline`}>
                                    <div className="title-xl">Aa</div>
                                    <div className="">darkgray</div>
                                </div>
                                <div className={` col is-grid  rounded-s p-l  bg-black`}>
                                    <div className="title-xl">Aa</div>
                                    <div className="">black</div>
                                </div>
                            </div>
                        </Layout42Main>
                    </div>


                </div>
            </div>
        )
            ;
    }
;

export default ColorsSection;

import App, {AdminDomain, DuckDomain, PortfoDomain, RootDomain} from "./App";

export const APPS = [
    {
        subdomains: "www",
        app:  RootDomain,
        main: true,
    },
    {
        subdomains: "duck",
        app: DuckDomain,
        main: false,
    },
    {
        subdomains: "admin",
        app: AdminDomain,
        main: false,
    },
    {
        subdomains: "portfo",
        app: PortfoDomain,
        main: false,
    }
]
const getSubdomain = (location: string) => {
    const locationParts = location.split(".")
    let sliceTill = -2
    const isLocationHost = locationParts.slice(-1)[0] === "localhost";
    if (isLocationHost) sliceTill = -1
    return locationParts.slice(0, sliceTill).join("")
}
export const getApp = () => {
    const subDomain = getSubdomain(window.location.hostname)
    const mainApp = APPS.find((app) => app.main)

    if (!mainApp) throw new Error("Must have main App")
    if (subDomain === "") return mainApp.app;
    const app = APPS.find((app) => subDomain === app.subdomains)
    return app.app;
}
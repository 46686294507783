import {Player} from "@lottiefiles/react-lottie-player";
import uiLotF from "../../static/lottie/data.json";

const Card = (props) => {
    return (
        <div
            className={`card  l-vertical  gap-l   ${
                props.status === true ? "active" : ""
            }`}
            style={{maxWidth: `${props.width || "70vw"}`}}
        >
            <Player
                src={uiLotF}
                className="player  "
                autoplay={true}
                loop
                speed={3}
                keepLastFrame
            />
        </div>
    );
};
export default Card;

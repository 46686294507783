import React, {forwardRef, useImperativeHandle, useRef} from "react";
import ContextEdit from "./ContextEdit";
import {useSelector} from "react-redux";
import ReactDOM from 'react-dom';

const Editable = (props) => {
    const elementRef = useRef(null);
    const listContext = useSelector(state => state.editor.listContext);
    return (
        <div  ref={elementRef} className="mt-xs px-xxl gapy-s is-scroll-y " style={{height: "740px"}}>
            {listContext.map((context, index) =>
                <ContextEdit key={context.id} context={context}>
                </ContextEdit>)}
        </div>
    )
}

export default Editable

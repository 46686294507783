import {useDispatch, useSelector} from "react-redux";
import React, {useRef, useState} from "react";

import {useOutsideClick} from "../../slide/checker";
import {
    addAttachments,
    addContext,
    addImage,
    addTable,
    embedLink,
    isList,
    textBold,
    textHeader,
    textItalic,
    toggleAlign,
    toggleIndent,
    toggleQuote
} from "../../redux/Editor/editorSlice";
import {addIcon} from "@iconify/react";
import {ListToolBar} from "../../slide/Item";


function ToolBar(props) {
    const dispatch = useDispatch();
    const [menu, setMenu] = useState(false)
    const expandMenu = (state) => {
        if (!e) var e = window.event;
        e.stopPropagation();
        if (menu !== state)
            setMenu(state)
        // else
        // setMenu(false)`
    }
    const activeId = useSelector(state => state.editor.activeId);
    const refClick = useOutsideClick(expandMenu);
    const inputRef = useRef(null)
    const [image, setImage] = useState("")
    const [checkImage, setCheckImage] = useState(null)
    const [link, setLink] = useState("")
    const [checkLink, setCheckLink] = useState(null)
    const handleTextBold = () => {
        dispatch(textBold({id: activeId}));
    };

    const handleTextHeaderXXl = (headText) => {
        dispatch(textHeader({id: activeId, headerType: headText}));
    };

    const handleTextItalic = () => {
        dispatch(textItalic({id: activeId, isItalic: true}));
    };

    const handleToggleIndent = (textIndent) => {
        dispatch(toggleIndent({id: activeId, textIndent: textIndent}));
    };

    const handleAddAttachments = (attachment) => {
        dispatch(addAttachments({id: activeId, attachment}));
    };

    const handleEmbedLink = (linkEmbed) => {
        dispatch(embedLink({id: activeId, linkEmbed: linkEmbed}));
    };

    const handleAddImage = (image) => {
        dispatch(addImage({id: activeId, url: image}));
    };
    const handleToggleAlign = (align) => {
        dispatch(toggleAlign({id: activeId, textAlign: align}))
    }

    const handleAddIcon = (icon) => {
        dispatch(addIcon({id: activeId, icon}));
    };

    const handleToggleQuote = () => {
        dispatch(toggleQuote({id: activeId}));
    };

    const handleAddTable = () => {
        const tableData = "Your table content"; // Thay đổi dữ liệu bảng theo nhu cầu của bạn
        dispatch(addTable({id: activeId, tableData}));
    };
    const handleIsList = (list) => {
        dispatch(isList({id: activeId, list}));
    };
    const handleAddContext = () => {
        // Tạo data cho context mới (thay đổi dữ liệu theo yêu cầu của bạn)
        const newContextData = {
            header: "p",
            color: "black",
            textBold: false,
            textItalic: false,
            textUnderline: false,
            textUppercase: false,
            textAlign: "left",
            list: "",
            textIndent: "",
            attachment: "",
            linkEmbed: "",
            image: false,
            icon: "",
            quote: "",
            content: "new"
        };

        // Dispatch action addContext với id và data tương ứng
        dispatch(addContext({id: activeId, data: newContextData}));
    };

    const toggleClick = (state) => {
        console.log(state)
        const selection = window.getSelection();

        switch (state.tool) {
            case "headXXL":
                handleTextHeaderXXl("h-xxl")
                return;
            case "headXL":
                handleTextHeaderXXl("h-xl")
                return;
            case "headL":
                handleTextHeaderXXl("h-l")
                return;
            case "textXXL":
                handleTextHeaderXXl("text-xxl")
                return;
            case "textXL":
                handleTextHeaderXXl("text-xl")
                return;

            case "textL":
                handleTextHeaderXXl("text-l")
                return;
            case "textM":
                handleTextHeaderXXl("text-l")
                return;
            case "textBold":
                if (selection && selection.toString() !== "") {
                    document.execCommand("bold")
                } else {
                    handleTextBold()
                }
                return
            case "colorLight":
                if (selection && selection.toString() !== "") {
                    const selectionTmp = window.getSelection().getRangeAt(0).cloneContents();
                    const span = document.createElement('span');
                    span.appendChild(selectionTmp);
                    const wrappedSelection = `<span class="${state.style}">${span.innerHTML}</span>`;
                    document.execCommand('insertHTML', false, wrappedSelection);
                }
                return
            case "textItalic":
                if (selection && selection.toString() !== "") {
                    document.execCommand("italic")
                } else
                    handleTextItalic()
                return
            case "textUnderLine":
                if (selection && selection.toString() !== "") {
                    selection.focusNode.parentNode.classList.add("text-underline")
                }
                // todo

                return
            case "textUppercase":
                if (selection && selection.toString() !== "") {
                    selection.focusNode.parentNode.classList.add("text-uppercase")
                }
                // todo
                return

            case "textAlignLeft":
                handleToggleAlign("text-left")
                return
            case "textAlignCenter":
                handleToggleAlign("text-center")
                return
            case "textAlignRight":
                handleToggleAlign("text-right")
                return
            case "increaseIndent":
                handleToggleIndent()
                return
            case "decreaseIndent":
                handleToggleIndent()
                return
            case "listBullet":
                if (selection && selection.toString() !== "") {
                    document.execCommand('insertUnorderedList', false, null)
                }
                return
            case "listOrdinal":
                if (selection && selection.toString() !== "") {
                    document.execCommand('insertOrderedList', false, null)
                }
                return
            case "addTable":
                handleAddTable()
                return
            case "addAttachment":

                return
            case "link":
                if (selection && selection.toString() !== "") {
                    console.log("run")
                    document.execCommand("createLink", false, "https://www.w3schools.com/jsref/met_document_execcommand.asp")
                    selection.focusNode.parentNode.classList.add("is-link")
                }
                return
            case "addImage":
                console.log({"url":image})
                handleAddImage(image)
                return
            case "addEmoji":
                handleAddIcon()
                return
            case "quote":
                //TODO it is a section
                handleToggleQuote()
                return
            default:
                console.log("not run", state.tool)
                return
        }
    }
    const handleChangImage = (e) => {
        const isImageUrl = (url) => {
            const imageUrlRegex = /\bhttps?:\/\/\S+?\.(?:png|jpe?g|gif|bmp|svg)\b/g;
            // Kiểm tra xem chuỗi có bắt đầu bằng "http://" hoặc "https://" hay không

            return  imageUrlRegex.test(url);
        };

        if (e.target.value === "") setCheckImage(null)
        else {
            if (isImageUrl(e.target.value)) {
                setCheckImage(true)
                setImage(e.target.value)
            } else setCheckImage(false)
        }
    }
    const handleChangeLink =(e)=>{
        const isUrl = (url) => {
            // Kiểm tra xem chuỗi có bắt đầu bằng "http://" hoặc "https://" hay không
            return /^https?:\/\//.test(url)
        };
        if (e.target.value === "") setCheckLink(false)
        else {
            if (isUrl(e.target.value)) {
                setCheckLink(true)
                setLink(e.target.value)
            } else setCheckLink(false)
        }
    }

    return (
        <div className="is-sticky " style={{zIndex: 2}}>
            <div className="tool-bar is-flex unwrap gapx-s p-xs   border-b ">
                {ListToolBar.map((group, index) => <div key={index} className="   gap-s p-xs jt-start unwrap columns">{
                        group.group.map((tools, j) => {
                                return <div key={j} title={tools.tool}
                                         onClick={tools.listTools || tools.type === "embed" ? () => expandMenu(tools.tool) : () => toggleClick(tools)}
                                         className="col btn btn-s btn-ghost  is-relative">
                                        <div
                                            className={`bg-white  border grid-3 gap-x rounded-s  select-expand ${menu === tools.tool && tools.listTools ? "is-open" : null}`}>
                                            {tools.listTools ? tools.listTools.map((toolsExpand, i) =>
                                                <div key={i} className="col btn btn-s btn-ghost ">
                                                    <div title={toolsExpand.tool} onClick={() => toggleClick(toolsExpand)}
                                                         className={`text-xl  icon ${toolsExpand.icon}`}>
                                                    </div>
                                                </div>) : null}


                                        </div>
                                        <div
                                            className={`bg-white  border  gap-xs rounded-s  select-expand ${menu === tools.tool && tools.type === "embed" ? "is-open" : null}`}>
                                            {tools.type === "embed" ? tools.tool==="addImage"?
                                                <label  className="input-field">
                                                    <input title={"url"} onChange={e => handleChangImage(e)} type="text"/>
                                                </label >  :
                                                <label  className="input-field">
                                                    <input onChange={e => handleChangeLink(e)} type="text"/>
                                                 </label>
                                                : null}
                                            {checkImage!==false?null:<div className="text-xs text-secondary">Liên kết không hợp lệ</div>}
                                            <div className="is-flex gapx-xs jt-end">
                                                <div className="btn btn-s btn-outline" onClick={() => setMenu(false)}>Cancel
                                                </div>
                                                <div className="btn btn-s" onClick={() => {
                                                    setMenu(false)
                                                    toggleClick(tools)
                                                }}>{tools.tool==="addImage"?"Add Image":"Add Link"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`text-xl icon ${tools.icon}`}>
                                        </div>
                                        {tools.listTools ? <div className="text-m icon icon-down">
                                        </div> : null}

                                    </div>
                            }
                        )
                    }
                    </div>
                )}
            </div>

        </div>

    )
}

export default ToolBar


import React from "react";
import MeCha from "../Mecha/MeCha";
import CV from "./CV";
import {Player} from "@lottiefiles/react-lottie-player";
import lotF from "../static/lottie/arrowdown.json";
import {useEffect, useState} from "react";

const Letter = () => {
    // const Arr = ["/",">","?",":","#","@","&","*","!","o"]
    // const [text,setText] = useState("0")
    // useEffect(()=>{
    //     setInterval(() => {
    //         let rand = Math.floor(Math.random() * 10)
    //         setText(Arr[rand])
    //     }, 3000)
    //     console.log(text)
    // },[text])

    return <div className="  container-full">

        <div className="is-relative">
            <div className="hi-intro-text is-absolute text-italic" style={{zIndex: "0", bottom: "-5%", left:"50%", transform:"translateX(-50%"}}>Hi</div>
            <div className="is-sticky is-flex jt-center" style={{height: "50vh", zIndex: "2", top: "10%"}}>
                <MeCha/>
            </div>

            <div className=" is-overflow-x columns vertical p-l">

                <div className="font-text">
                    <div className="font-text" style={{height: "200vh"}}>
                        <div className="hi-5xl hi-text-center text-white">A</div>
                        <div className="hi-text-center  text-mute text-bold is-flex vertical align-center" style={{height: "50vh"}}>
                            <div className="h-xs is-flex">
                                Design With Love,
                                <br/>
                                Code With Passion.
                            </div>
                            <Player src={lotF} className="player fit-content size-xxl " autoplay={true} loop
                                    speed={0.7}/>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <CV/>
    </div>
}
export default Letter

const Doitac = [
    {img: require("../../static/partner/fogo.png")},
    {img: require("../../static/partner/fogo.png")},
    {img: require("../../static/partner/fogo.png")},
    {img: require("../../static/partner/fogo.png")},
    {img: require("../../static/partner/fogo.png")},
    {img: require("../../static/partner/fogo.png")},
    {img: require("../../static/partner/fogo.png")},
    {img: require("../../static/partner/fogo.png")}
]

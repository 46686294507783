import React, {useState} from 'react';
import img from "../../../../../static/img.png";
import {Layout42} from "../../Support/Layout";
import DuckSelect, {Segment} from "../Input/DuckSelect";
import {Icon} from "../../DuckIcon";

function ImageSection(props) {
    const [label, setLabel] = useState({pre: 1, suf: 1})
    const [direction, setDirection] = useState(0)
    const ratio = [{pre: "1", suf: "1"},
        {pre: "2", suf: "3"},
        {pre: "3", suf: "4"},
        {pre: "9", suf: "10"},
        {pre: "9", suf: "16"},
        {pre: "9", suf: "21"}
    ]
    return (
        <div className="section">
            <h3 className="h-l">Image</h3>
            <div className="pt-xl section">
                <div className="section">
                    <div className="h-s">Image Ratio
                    </div>
                    <div className="text-m is-2-line">
                        Use the primary button for the most important action in the view, for example on a sign-in
                        screen, landing screen, confirmation screen, error screen, or on a screen that has an explicit
                        primary action. A primary button always has a filled style. Note that there can only be one
                        primary action per view.
                    </div>
                </div>
                <Layout42 size={"m"} code={""}>
                    <div className={"columns"}>
                        <img src={img} alt=""
                             className={`border size-y-l-x2  ${direction === 0 ? `ratio-${label.pre}-${label.suf}` : `ratio-${label.suf}-${label.pre}`}`}/>
                        <div className="is-flex jt-between align-center ">
                            <div>
                                <div className="text-xs text-disable">
                                    3D design
                                </div>
                                <div className="title-xxl">
                                    OrZana ca
                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className="field has-label-inline">
                            <div className="label">Direction</div>
                            <Segment handleClick={() => {
                            }} segment={[{action: "device-mobile"}, {action: "device-tablet"}]}
                                     child={({item, index}) => (
                                         <div key={index} className={`btn ${direction === index ? "active" : ""}`}
                                              onClick={() => {
                                                  setDirection(index)
                                              }}><Icon className={"text-l"} icon={item.action}/></div>)}
                            >
                            </Segment>
                        </div>
                        <div className="field has-label-inline">
                            <div className="label">Ratio</div>
                            <DuckSelect data={ratio} placeholder={"select size"}
                                        child={({item, index}) => (
                                            <div
                                                title={direction === 0 ? `Ratio ${item.pre} x ${item.suf}` : `Ratio ${item.suf} x ${item.pre} `}
                                                key={index}
                                                className={"p-s"}
                                                onClick={() => {
                                                    setLabel({pre: item.pre, suf: item.suf})
                                                }}>
                                                {direction === 0 ? `Ratio ${item.pre} x ${item.suf}` : `Ratio ${item.suf} x ${item.pre} `}
                                            </div>
                                        )}/>
                        </div>
                    </div>

                </Layout42>
            </div>
        </div>
    )
        ;
}

export default ImageSection;
const VariantInputString = <div>
    <div className="field has-label-inline">
        <div className="label">color</div>
        <input type="color"/></div>
    <div className="field has-label-inline">
        <div className="label">date</div>
        <input type="date"/></div>
    <div className="field has-label-inline">
        <div className="label">datetime-local</div>
        <input type="datetime-local"/></div>
    <div className="field has-label-inline">
        <div className="label">date</div>
        <input type="date"/></div>
    <div className="field has-label-inline">
        <div className="label">range</div>
        <input type="range"/></div>
    <div className="field has-label-inline">
        <div className="label">time</div>
        <input type="time"/></div>
    <div className="field has-label-inline">
        <div className="label">url</div>
        <input type="url"/></div>
    <div className="field has-label-inline">
        <div className="label">week</div>
        <input type="week"/></div>
</div>

const UploadInputString = <div className="field has-label-inline">
    <div className="label">Upload File</div>
    <div className="  form-file-upload ">
        <input accept="image/*" type="file" 
                    className="input-file-upload"/>
        <label htmlFor="input-file-upload" 
                    className=" label-file-upload ">
            <div className=" gap-xs">
                <div className="icon icon-add"/>
                <p>Upload Image</p>
                <p className="upload-button ">Browser</p>
            </div>
        </label>
        <div className="is-flex jt-between p-s">
            <p className="text-xs text-disable">Supported image</p>
            <p className="text-xs text-disable">Maximum size: 12Mb</p>
        </div>
    </div>
</div>

const CheckboxString = <div>
    <label className="form-check">
        <input
            type="checkbox"
            id="cb1"
        />
        <label htmlFor="cb1">
            <span>Checkbox</span>
        </label>
    </label>
    <label className="form-check">
        <input
            type="checkbox"
            id="cb2"
            defaultChecked
        />
        <label className="checked" htmlFor="cb2">
            <span>Checkbox</span>
        </label>
    </label>
    <label className="form-check">
        <input
            type="checkbox"
            id="cb3"
            name="check"
        />
        <label className="unchecked" htmlFor="cb3">
            <span>Checkbox</span>
        </label>
    </label>
</div>

export {
    VariantInputString, UploadInputString, CheckboxString
}

import React, {useState} from "react";
import {Icon} from "../DuckIcon";
import {Highlight, themes} from "prism-react-renderer"
import FormatHTMLString from "../Controler/JSXtoString";


const HiLightCode = ({codeString, height}) => {
    const [copy, setCopy] = useState(false);
    const [showCode, setShowCode] = useState(false)

    const handleCopyClick = () => {
        try {
            const textArea = document.createElement('textarea');
            textArea.value = codeString;

            // Set the position to be off-screen
            textArea.style.position = 'fixed';
            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.opacity = '0';

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            document.execCommand('copy');
            document.body.removeChild(textArea);

            setCopy(true);
            setTimeout(() => {
                setCopy(false);
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error copying to clipboard:', error);
        }
    };


    return (
        <div className={`mt-s border is-overflow rounded-s rounded-b-m  is-flex is-fill-x ${height ? "is-fill-y" : ""}`}>
            <div className="text-light is-flex vertical is-fill-x">
                <div className="is-flex align-end jt-between px-l py-m">
                    <div style={{minWidth: "70px"}} onClick={() => setShowCode(!showCode)}
                         className="btn   btn-primary ">
                        {showCode ? "Hide code" : "Show code"}
                    </div>
                    <div className="btn btn-s btn-ghost " onClick={handleCopyClick}>
                        {copy ? <> Đã sao chép <Icon className={"text-m text-mute ml-s"} icon={"copy-success "}/></> :
                            <Icon className={"text-m text-mute"} icon={"copy"}></Icon>}
                    </div>
                </div>
                <div className="accordion">
                    <div className={`accordion-item ${showCode ? "active" : ""} is-scroll-y`}>
                        <Highlight
                            theme={themes.oneDark}
                            code={FormatHTMLString(codeString)}
                            language="jsx"
                        >
                            {({className, style, tokens, getLineProps, getTokenProps}) => (
                                <pre style={{flexGrow: "1", overflowY: "auto", borderRadius: "0", ...style}}>
                                    {tokens.map((line, i) => (
                                        <div key={i} {...getLineProps({line})}>
                                            {line.map((token, key) => (
                                                <span className={"text-l"} key={key} {...getTokenProps({token})} />
                                            ))}
                                        </div>
                                    ))}
                                </pre>
                            )}
                        </Highlight>
                    </div>
                </div>


            </div>
        </div>
    );
};


export default HiLightCode;

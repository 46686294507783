import React, {useEffect, useMemo, useRef, useState} from "react";
import '../Scss/Duck.scss'
import Introduction from './Introduction/Introduction.js'
import LayoutSection from './Section/Layout/LayoutSection.js'
import ButtonSection from './Section/Button/buttonSection.js'
import ColorsSection from './Section/Color/colorsSection.js'
import ViewportSection from './Section/Viewport/viewportSection.js'
import BreadCrumbSection from './Section/BreadCrumb/BreadCrumbSection.js'
import InputSection from './Section/Input/InputSection.js'
import CardSection from './Section/Card/CardSection.js'
import ImageSection from './Section/Image/ImageSection.js'
import HeroSection from './Section/Hero/HeroSection.js'
import TableSection from './Section/Table/TableSection.js'
import IconSection from './Section/Icon/IconSection.js'
import ModalSection from './Section/Modal/ModalSection.js'
import NotificationSection from "./Section/Notification/NotificationSection";
import {TabItem} from '../../DuckReact/Tabs.js'
import TextSection from './Section/TextSection/Text.js'
import logo from "../../../static/logo-beta.svg";
import {useIsInViewport, useIsTopOfViewport} from "../../../slide/checker";
import DuckIcon, {Icon} from "./DuckIcon";
import bg from "../../../Uxui/duckicon.png";
import {NavLink, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import SideBarSection from "./Section/SlideBar/SideBarSection";
import Footer from "../../../navbar/Footer";
import Foundation from "./Introduction/foundation";
const Customize = [{
        "name": "Colors",
        "id": "ColorsSection",
        "icon": "planet"
    },
    {
        "name": "Text",
        "id": "TextSection",
        "icon": "capitalize"
    },]
const Layout = [    {
    "name": "Viewport",
    "id": "containerSection",
    "icon": "device-window"
},    {
    "name": "Layout",
    "id": "LayoutSection",
    "icon": "layout"
},
]

const Element = [
    {
        "name": "Button",
        "id": "ButtonSection",
        "icon": "device-window"
    },
    {
        "name": "Input",
        "id": "InputSection",
        "icon": "password"
    },
    {
        "name": "Image",
        "id": "Image",
        "icon": "image"
    },
    {
        "name": "Toast",
        "id": "ToastSection",
        "icon": "notification-new"
    },
    {
        "name": "Table",
        "id": "TableSection",
        "icon": "table"
    },
]

const MenuItem = [

    {
        "name": "BreadCrumb",
        "id": "BreadCrumbSection",
        "icon": "message-hash"
    },
    {
        "name": "Card",
        "id": "Card",
        "icon": "device-tablet"
    },

    {
        "name": "Hero",
        "id": "HeroSection",
        "icon": "lighning"
    },
    {
        "name": "SideBar",
        "id": "SideBarSection",
        "icon": "table"
    },
    // {
    //     "name": "Chart",
    //     "id": "ChartSection",
    //     "icon": "align-bottom"
    // },

    {
        "name": "Modal",
        "id": "ModalSection",
        "icon": "device-window"
    }
]

const AllItem = [
    {
        "name": "Colors",
        "id": "ColorsSection",
        "icon": "planet"
    },
    {
        "name": "Text",
        "id": "TextSection",
        "icon": "capitalize"
    },
    {
        "name": "Viewport",
        "id": "containerSection",
        "icon": "device-window"
    },    {
        "name": "Layout",
        "id": "LayoutSection",
        "icon": "layout"
    },
    {
        "name": "Button",
        "id": "ButtonSection",
        "icon": "device-window"
    },
    {
        "name": "Input",
        "id": "InputSection",
        "icon": "password"
    },
    {
        "name": "Image",
        "id": "Image",
        "icon": "image"
    },
    {
        "name": "Toast",
        "id": "ToastSection",
        "icon": "notification-new"
    },
    {
        "name": "Table",
        "id": "TableSection",
        "icon": "table"
    },
    {
        "name": "BreadCrumb",
        "id": "BreadCrumbSection",
        "icon": "message-hash"
    },
    {
        "name": "Card",
        "id": "Card",
        "icon": "device-tablet"
    },

    {
        "name": "Hero",
        "id": "HeroSection",
        "icon": "lighning"
    },
    {
        "name": "SideBar",
        "id": "SideBarSection",
        "icon": "table"
    },
    // {
    //     "name": "Chart",
    //     "id": "ChartSection",
    //     "icon": "align-bottom"
    // },

    {
        "name": "Modal",
        "id": "ModalSection",
        "icon": "device-window"
    }
]
// return Menu
const Menu = ({activeItem}) => {
    const sub = useRef(null)
    const [mItem, setMItem] = useState(MenuItem)
    const [visible, setVisible] = useState(null);
    const [collapse, setCollapse] = useState("customize");
    const location = useLocation();

    let elmWidth = 0
    let offSet = 0
    const osStyle = {
        opacity: visible ? 1 : 0,
        width: elmWidth,
        transform: `translateY(${offSet}px)`,
        transitionDuration: '200ms'
    }
    useMemo(() => {
        const elm = sub.current
        if (elm) {
            if (visible != null) {
                elmWidth = visible.current.clientWidth
                offSet += visible.current.offsetTop
                elm.style.transform = `translateY(${offSet}px)`
                elm.style.width = `${elmWidth}px`
            } else {
                elm.style.opacity = 0;
                setVisible(null)
                return
            }
        }
    }, [visible])

    const onHover = ref => {
        if (ref)
            setVisible(ref)
        else setVisible(null)
    }
    const handleClick = (e) => {
        e.stopPropagation()

        setCollapse(!collapse)

    }
    const navigate = useNavigate()
    const handleJump = id => {
        navigate(`#${id}`)
    }
    useEffect(() => {
        // Kiểm tra xem có fragment không
        if (location.pathname !== "/") setCollapse(false)
        const {hash} = location;
        if (hash) {
            // Loại bỏ ký tự '#' từ fragment
            const targetId = hash.substring(1);

            // Tìm phần tử với id tương ứng
            const targetElement = document.getElementById(targetId);

            // Nếu tìm thấy, cuộn đến phần tử đó
            if (targetElement) {
                targetElement.scrollIntoView({behavior: 'smooth'});
            }
        }
    }, [location]);
    return <>
        <div ref={sub} className="side-sub" id="subSide"
             style={visible ? osStyle : {}}></div>
        <div className={`item`}>
            <TabItem onHover={onHover}>
                <NavLink to={"/introduction"} className="sidebar-btn">
                    <Icon className="sidebar-icon text-l mx-s" icon={"campfire"}/>
                    <div className="item-text ">
                        Introduction
                    </div>
                </NavLink>
            </TabItem>
        </div>
        <div className={`item`}>
            <TabItem onHover={onHover}>
                <NavLink to={"/foundation"} className="sidebar-btn">
                    <Icon className="sidebar-icon text-l mx-s" icon={"license"}/>
                    <div className="item-text ">
                        Foundation
                    </div>
                </NavLink>
            </TabItem>
        </div>
        {/*<div className={`item   ${collapse!=="" ? "": "show" } `}>*/}

        {/*    <TabItem onHover={onHover}>*/}
        {/*        <NavLink to={"/"} onClick={(e) => {*/}
        {/*            collapse!==""? setCollapse(""):*/}
        {/*        }} className={({isActive, isPending}) => isActive ? "sidebar-btn text-white is-flex" : "sidebar-btn is-flex"}*/}
        {/*                 data-expand={"expand"}>*/}
        {/*            <Icon className="sidebar-icon text-l mx-s" icon={"setting-2"}/>*/}
        {/*            <div className="item-text ">*/}
        {/*                Customize*/}
        {/*            </div>*/}
        {/*            <Icon className={"text-m text-disable"} icon={collapse!=="" ? "down rotate-180" : "down"}></Icon>*/}
        {/*        </NavLink>*/}

        {/*    </TabItem>*/}
        {/*    <div className="item-collapse ">*/}
        {/*       */}
        {/*    </div>*/}
        {/*</div>*/}
        <div className={`item  ${collapse==="customize" ? "show" : ""}`}>

            <TabItem onHover={onHover}>
                <NavLink to={"/"} onClick={(e) => {
                    collapse==="customize"? handleClick(e) : setCollapse("customize")
                }} className={({isActive, isPending}) => isActive ? "sidebar-btn text-white is-flex" : "sidebar-btn is-flex"}
                         data-expand={"expand"}>
                    <Icon className="sidebar-icon text-l mx-s" icon={"setting-2"}/>
                    <div className="item-text ">
                        Customize
                    </div>
                    <Icon className={"text-m text-disable"} icon={collapse==="customize" ? "down rotate-180" : "down"}></Icon>
                </NavLink>

            </TabItem>
            <div className="item-collapse ">
                {
                    Customize.map((item, index) =>
                        <NavLink to={`#${item.id}`} onClick={(e) => {
                            location.pathname === "" ?
                                handleClick(e) : setCollapse("customize")
                        }}
                                 className={`item ${activeItem === item.id ? "active" : ""}`}>
                            <TabItem onHover={onHover}>
                                <div
                                    className={`sidebar-btn ${activeItem === item.id ? "active" : ""}`}>
                                    <Icon className="sidebar-icon text-l mx-s" icon={item.icon}/>
                                    <div className="item-text ">
                                        {item.name}
                                    </div>
                                </div>
                            </TabItem>

                        </NavLink>
                    )
                }
            </div>
        </div>
        <div className={`item  ${collapse==="layout" ? "show" : ""}`}>

            <TabItem onHover={onHover}>
                <NavLink to={"/"} onClick={(e) => {
                    collapse==="layout"? handleClick(e) : setCollapse("layout")
                }} className={({isActive, isPending}) => isActive ? "sidebar-btn text-white is-flex" : "sidebar-btn is-flex"}
                         data-expand={"expand"}>
                    <Icon className="sidebar-icon text-l mx-s" icon={"layout"}/>
                    <div className="item-text ">
                        Layout
                    </div>
                    <Icon className={"text-m text-disable"} icon={collapse==="layout" ? "down rotate-180" : "down"}></Icon>
                </NavLink>

            </TabItem>
            <div className="item-collapse ">
                {
                    Layout.map((item, index) =>
                        <NavLink to={`#${item.id}`} onClick={(e) => {
                            location.pathname === "" ?
                                handleClick(e) : setCollapse("layout")
                        }}
                                 className={`item ${activeItem === item.id ? "active" : ""}`}>
                            <TabItem onHover={onHover}>
                                <div
                                    className={`sidebar-btn ${activeItem === item.id ? "active" : ""}`}>
                                    <Icon className="sidebar-icon text-l mx-s" icon={item.icon}/>
                                    <div className="item-text ">
                                        {item.name}
                                    </div>
                                </div>
                            </TabItem>

                        </NavLink>
                    )
                }
            </div>
        </div>
        <div className={`item  ${collapse==="element" ? "show" : ""}`}>

            <TabItem onHover={onHover}>
                <NavLink to={"/"} onClick={(e) => {
                    collapse==="element"? handleClick(e) : setCollapse("element")
                }} className={({isActive, isPending}) => isActive ? "sidebar-btn text-white is-flex" : "sidebar-btn is-flex"}
                         data-expand={"expand"}>
                    <Icon className="sidebar-icon text-l mx-s" icon={"context-menu-2"}/>
                    <div className="item-text ">
                        Element
                    </div>
                    <Icon className={"text-m text-disable"} icon={collapse==="element" ? "down rotate-180" : "down"}></Icon>
                </NavLink>

            </TabItem>
            <div className="item-collapse ">
                {
                    Element.map((item, index) =>
                        <NavLink to={`#${item.id}`} onClick={(e) => {
                            location.pathname === "" ?
                                handleClick(e) : setCollapse("element")
                        }}
                                 className={`item ${activeItem === item.id ? "active" : ""}`}>
                            <TabItem onHover={onHover}>
                                <div
                                    className={`sidebar-btn ${activeItem === item.id ? "active" : ""}`}>
                                    <Icon className="sidebar-icon text-l mx-s" icon={item.icon}/>
                                    <div className="item-text ">
                                        {item.name}
                                    </div>
                                </div>
                            </TabItem>

                        </NavLink>
                    )
                }
            </div>
        </div>
        <div className={`item  ${collapse==="component" ? "show" : ""}`}>

            <TabItem onHover={onHover}>
                <NavLink to={"/"} onClick={(e) => {
                    collapse==="component"? handleClick(e) : setCollapse("component")
                }} className={({isActive, isPending}) => isActive ? "sidebar-btn text-white is-flex" : "sidebar-btn is-flex"}
                         data-expand={"expand"}>
                    <Icon className="sidebar-icon text-l mx-s" icon={"component"}/>
                    <div className="item-text ">
                        Component
                    </div>
                    <Icon className={"text-m text-disable"} icon={collapse==="component" ? "down rotate-180" : "down"}></Icon>
                </NavLink>

            </TabItem>
            <div className="item-collapse ">
                {
                    MenuItem.map((item, index) =>
                        <NavLink to={`#${item.id}`} onClick={(e) => {
                            location.pathname === "" ?
                                handleClick(e) : setCollapse("component")
                        }}
                                 className={`item ${activeItem === item.id ? "active" : ""}`}>
                            <TabItem onHover={onHover}>
                                <div
                                    className={`sidebar-btn ${activeItem === item.id ? "active" : ""}`}>
                                    <Icon className="sidebar-icon text-l mx-s" icon={item.icon}/>
                                    <div className="item-text ">
                                        {item.name}
                                    </div>
                                </div>
                            </TabItem>

                        </NavLink>
                    )
                }
            </div>
        </div>
        <div className={`item`}>
            <TabItem onHover={onHover}>
                <NavLink to={"https://duck.hine.studio/duck-icon"} className="sidebar-btn">
                    <Icon className="sidebar-icon text-l mx-s" icon={"rocket"}/>
                    <div className="item-text ">
                        Icon
                    </div>
                </NavLink>
            </TabItem>

        </div>
        <div className={`item`}>
            <TabItem onHover={onHover}>
                <NavLink to={"/template"} className="sidebar-btn">
                    <Icon className="sidebar-icon text-l mx-s" icon={"timeline-event"}/>
                    <div className="item-text ">
                        Animation
                    </div>
                </NavLink>
            </TabItem>

        </div>
        <div className={`item`}>
            <TabItem onHover={onHover}>
                <NavLink to={"/template"} className="sidebar-btn">
                    <Icon className="sidebar-icon text-l mx-s" icon={"campfire"}/>
                    <div className="item-text ">
                        Template
                    </div>
                </NavLink>
            </TabItem>

        </div>

    </>
}


const Duck = () => {
    const location = useLocation();
    const [expandMenu, setExpandMenu] = useState(false)
    const tabs = [{
        tabId: 0,
        tabName: "Controller"
    }, {
        tabId: 1,
        tabName: "</>"
    }]
    useEffect(() => {
        document.title = "Duck Design System ";
    })
    useEffect(() => {
        {
            // Returns the cleanup function
            return () => {
                const metaTag = document.querySelector(`link[ref="stylesheet"]`);
                if (metaTag) {
                    metaTag.remove();
                }
            }
        }
    }, [])

    const handleClick = (value) => {
        if (value !== 0) {
            const element = document.getElementById(`item${value}`);
            if (element.classList.contains("expand")) {
                element.classList.remove("expand")
            } else element.classList.add("expand")
        } else {
            setExpandMenu(!expandMenu)
        }
    }
    const [projectMenu, setProject] = useState(false)
    const [currentSection, setCurrentSection] = useState("ViewPort")
    const [currentID, setCurrentID] = useState("ViewPortSection")
    const ref = useRef(null);
    const checkVisible = useIsInViewport(ref);
    const onAppear = string => {

        for (var i = 0; i < AllItem.length; i++) {
            for (const key in AllItem[i]) {
                if (AllItem[i][key].indexOf(string) !== -1) {
                    setCurrentSection(AllItem[i].name)
                    setCurrentID(AllItem[i].id)
                }
            }
        }

    }
    return <div className="container-full bg-mute text-white columns gap-s  is-sticky " id={"dashboard"}>
        <div className={`   sidebar  font-text ${expandMenu ? "" : "hide"}`} id="sidebar">
            <div className={`sidebar-header  jt-between`}>
                <div className="sidebar-brand">
                    <img src={logo} alt=""/>
                </div>

            </div>

            <div className={`sidebar-main `} id="expandMenu">
                <div onClick={() => setExpandMenu(!expandMenu)} className="sidebar-mobile-collapse">
                    <Icon className={"text-l"} icon={expandMenu ? "close" : "menu"}/>
                </div>
                <div className="sidebar-items">
                    <Menu activeItem={currentID}/>
                </div>
                <div className="sidebar-items p-x is-fill-x ">
                    <div onMouseEnter={() => expandMenu ? setProject(true):{} } onMouseLeave={() => setProject(false)}
                         className={`item ${projectMenu ? "show" : ""} align-center cursor-pointer expand-vertical bg-hover-black rounded-s`}>
                        <div className={`item-collapse  p-x`}>
                            <h5 className={"text-white py-m"}>Version</h5>
                            <div onClick={()=>setProject(false)} className="px-xs sidebar-btn columns rounded-s bg-hover-mute  ">

                                <img alt={"project"} className={"sidebar-icon ratio-1-1 size-xl rounded-s"}
                                     src={"https://i.imgur.com/33V0Db0.png"}/>
                                <div className="text-white  col pl-m ">
                                    <div className={"jt-between  is-fill-x is-flex"}>
                                        <h6 className={""}>v1.0</h6>
                                        <Icon icon={"checked text-white"}></Icon>
                                    </div>
                                    <p className={"text-xs text-disable"}>Stable</p>
                                </div>


                            </div>
                            <div  onClick={()=>setProject(false)} className="sidebar-btn px-xs columns rounded-s  bg-hover-mute  ">

                                <img alt={"project"} className={"sidebar-icon ratio-1-1 size-xl rounded-s"}
                                src={"https://i.imgur.com/0NG6Ogc.png"}/>
                                <div className="text-white col pl-m">
                                    <div className={"jt-between is-fill-x is-flex"}>
                                        <h6 className={""}>v1.2</h6>
                                    </div>
                                    <p className={"text-xs text-disable"}>Beta</p>
                                </div>

                            </div>
                            <div className="divider my-s"></div>


                        </div>

                        <div className="sidebar-btn px-xs   ">
                            <img alt={"project"} className={"sidebar-icon ratio-1-1 size-xl rounded-s"}
                                 src={"https://i.imgur.com/33V0Db0.png"}/>
                            <div className="text-white col pl-m">
                                <div className={"jt-between is-fill-x is-flex"}>
                                    <h6 className={""}>v1.0</h6>
                                    <Icon className={`${projectMenu ? "rotate-180" : ""} text-white`}
                                          icon={"up-down"}></Icon>
                                </div>
                                <p className={"text-xs text-disable"}>Stable</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="sidebar-bottom">
                <div onClick={() => handleClick(0)} className="btn btn-ghost">
                    <Icon className={"text-white text-xl px-m"} icon={expandMenu ? "open" : "insert-right"}/>

                </div>

            </div>

        </div>
        <div className="col-5 bg-white font-text smooth-scroll rounded-m border" id={"special"}>

            <div ref={ref} className=" navbar p-m   border-b " style={{top: "0"}}>
                <div className="nav-brand">
                    <h3> Duck Design Document</h3>
                </div>
                <div className="navbar-main columns layout-5-1 align-end">
                    <div className="col"></div>
                    <input placeholder={"Search"} type="text"/>

                </div>
            </div>

            <div className="border-b is-sticky bg-white " style={{zIndex: 12, top: "0"}}>
                <div className={`container-l navbar  px-m`}>
                    <div className="breadcrumb ">
                        {checkVisible ? "" : <div className="breadcrumb-item downComingElement">
                            Document
                        </div>}
                        {location.pathname === "/" ?<>

                            <div className="breadcrumb-item active">
                                <a href="#superRoot" className="  ">
                                    <div onClick={() => {
                                        window.onbeforeunload = function () {
                                            window.scrollTo(0, 0);
                                        }
                                    }
                                    } className=" downComingElement">
                                        {currentSection}
                                    </div>
                                </a>
                            </div></>:<>
                            <div className="breadcrumb-item active">
                                <a href="#superRoot" className="  ">
                                    <div onClick={() => {
                                        window.onbeforeunload = function () {
                                            window.scrollTo(0, 0);
                                        }
                                    }
                                    } className=" downComingElement">
                                        Introduction
                                    </div>
                                </a>
                            </div>
                        </>
                        }

                    </div>
                </div>
            </div>
            <Routes>
                <Route path={`/`} element={<div className=" align-center  " id="superRoot">

                    <SectionCpn onAppear={onAppear} sectionId={"ColorsSection"} title={"Colors"}
                                subtitle={"DuckUI Colors"}
                                elements={<ColorsSection/>}/>
                    <SectionCpn onAppear={onAppear} sectionId={"TextSection"} title={"Text"}
                                subtitle={"DuckUI Text"}
                                elements={<TextSection/>}/>
                    <SectionCpn onAppear={onAppear} sectionId={"containerSection"} title={"Viewport"}
                                subtitle={"DuckUI Viewport"}
                                elements={<ViewportSection/>}/>
                    <SectionCpn onAppear={onAppear} sectionId={"LayoutSection"} title={"Layout"}
                                subtitle={"DuckUI Layout"}
                                elements={<LayoutSection/>}/>
                    <SectionCpn onAppear={onAppear} sectionId={"ButtonSection"} title={"Button"}
                                subtitle={"DuckUI Button"}
                                elements={<ButtonSection/>}/>
                    <SectionCpn onAppear={onAppear} sectionId={"InputSection"} title={"Input"}
                                subtitle={"DuckUI Input"}
                                elements={<InputSection/>}/>
                    <SectionCpn onAppear={onAppear} sectionId={"Image"} title={"Image"}
                                subtitle={"DuckUI Image"}
                                elements={<ImageSection/>}/>
                    <SectionCpn onAppear={onAppear} sectionId={"ToastSection"} title={"Toast"}
                                subtitle={"DuckUI Toast"}
                                elements={<NotificationSection/>}/>
                    <SectionCpn onAppear={onAppear} sectionId={"TableSection"} title={"Table"}
                                subtitle={"DuckUI Input"}
                                elements={<TableSection/>}/>


                    <SectionCpn onAppear={onAppear} sectionId={"BreadCrumbSection"}
                                title={"BreadCrumb"}
                                subtitle={"DuckUI BreakCrumb"}
                                elements={<BreadCrumbSection/>}/>


                    <SectionCpn onAppear={onAppear} sectionId={"Card"} title={"Card"}
                                subtitle={"DuckUI Card"}
                                elements={<CardSection/>}/>

                    <SectionCpn onAppear={onAppear} sectionId={"HeroSection"} title={"Hero"}
                                subtitle={"DuckUI Hero"}
                                elements={<HeroSection/>}/>
                    <SectionCpn onAppear={onAppear} sectionId={"SideBarSection"} title={"SideBar"}
                                subtitle={"DuckUI Sidebar"}
                                elements={<SideBarSection/>}/>

                    <SectionCpn onAppear={onAppear} sectionId={"ModalSection"} title={"Modal"}
                                subtitle={"DuckUI Modal"}
                                elements={<ModalSection/>}/>

                    <SectionCpn onAppear={onAppear} sectionId={"IconSection"} title={"Icon"}
                                subtitle={"Icon Modal"}
                                elements={<IconSection/>}/>
                </div>}/>
                <Route path={`/introduction`} element={<Introduction/>}/>
                <Route path={`/foundation`} element={<Foundation/>}/>
            </Routes>
            <Footer></Footer>
        </div>
    </div>

}
export default Duck

const SectionCpn = (props) => {
    const ref = useRef(null);
    const checkVisible = useIsTopOfViewport(ref);
    useMemo(() => {
        if (checkVisible) {
            props.onAppear(props.title)
        }
        return () => {

        }
    }, [checkVisible])


    return <div ref={ref} id={props.sectionId}>
        <div className="section">
            <div className="  hero hero-bg  is-one-third ">
                {props.img ? <img src={props.img} alt=""/> : ""}
                <img src={bg} alt=""/>
                <div className=" hero-content  ">
                    <div className="container-l m-mobile-xxl p-m columns">
                        <div className="col">
                            <h1 className="h-xxl ">{props.title}</h1>
                            <div className="h-s text-sliver mb-xxl mb-mobile-xxl">{props.subtitle}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="mx-xxl  mx-mobile-xl container-l p-m ">
            {props.elements}
        </div>
    </div>
}

import {useEffect, useMemo} from "react";
import {Selector3D} from "../../menu-item/threeD";
import React from "react";

const MeCha = () => {
    const currentFrame = index => (require("./" + index.toString().padStart(4, '0') + ".png"))
    const frameCount = 120;

    const preloadImages =  () => {
        for (let i = 1; i <= frameCount; i++) {
            const img = new Image();
            img.src = currentFrame(i);
        }
    };
    preloadImages()


    useEffect( () => {
        const html = document.documentElement;
        const canvas = document.getElementById("hero");
        const context = canvas.getContext("2d");
        canvas.width =1000;
        canvas.height = 1000;

        const img = new Image()
        img.src = currentFrame(1);
        img.onload = function (index) {
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
        }
        const updateImage = index => {
            img.src = currentFrame(index);
            img.onload = function (index) {
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(img, 0, 0, canvas.width, canvas.height);
            }

        }
        var isScrolling;
         window.addEventListener('scroll', function (event) {
            window.clearTimeout(isScrolling);
            isScrolling = setTimeout(function () {
                const scrollTop = html.scrollTop;
                const maxScrollTop = html.scrollHeight - window.innerHeight;
                const scrollFraction = scrollTop / maxScrollTop;
                const frameIndex = Math.min(
                    frameCount - 15,
                    Math.ceil(scrollFraction * frameCount)
                );
                requestAnimationFrame(() => {
                    updateImage(frameIndex +15)
                })
            }, 0);


        });

    },[])

    return <canvas  id="hero">

    </canvas>
}
export default MeCha

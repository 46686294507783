import React from 'react';
import logo from "../../../../../static/logo.svg";
import {useState} from "react";
import HiLightCode from "../../Support/hiLightCode";
import {Icon} from "../../DuckIcon";

const SideBarSection = () => {
    const [expand, setExpand] = useState(false)
    const [expandItem, setExpandItem] = useState(false)
    const [expandMenu, setExpandMenu] = useState(false)
    const sidebarString = `<div className={\` sidebar border-r ${expand ? "expand" : ""}\`} id="sidebar"
                                 style={{height: '100%'}}>
                                <div className="sidebar-header">
                                    <img src={logo} alt=""/>
                                </div>
                                <div className={\`sidebar-main mt-s ${expandMenu ? "expand" : ""}\`} id="expandMenu">
                                    <div onClick={() => setExpandMenu(!expandMenu)} className="sidebar-collapse">
                                        {expandMenu ? <Icon icon={\`close\`}/> : <Icon icon={\`menu\`}/>}
                                    </div>
                                    <div className="sidebar-item">
                                        <a key={2311} id={\`item${2311}\`} className="item expand ">
                                            <div>
                                                <div className="sidebar-btn">
                                                    <Icon className="text-m mx-s" icon={"circle"}/>
                                                    <div className="item-text">
                                                        Items
                                                    </div>
                                                </div>
                                                <div onClick={() => handleClick(2311)}
                                                     className="expand-icon"></div>
                                            </div>
                                            <a className="item">
                                                <div className="sidebar-btn">
                                                    <Icon className="text-m mx-s" icon={"circle"}/>
                                                    <div className="item-text">
                                                        Items
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="item  ">
                                                <div className="sidebar-btn">
                                                    <Icon className="text-m mx-s" icon={"circle"}/>
                                                    <div className="item-text">
                                                        Items
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="item ">
                                                <div className="sidebar-btn">
                                                    <Icon className="text-m mx-s" icon={"circle"}/>
                                                    <div className="item-text">
                                                        Items
                                                    </div>
                                                </div>
                                            </a>
                                             <a className="item active">
                                                <div className="sidebar-btn">
                                                    <Icon className="text-m mx-s" icon={"circle"}/>
                                                    <div className="item-text">
                                                        Items
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="item active  hi-text-semi hi-flex align-center">
                                                <div className="sidebar-btn">
                                                    <Icon className="text-m mx-s" icon={"circle"}/>
                                                    <div className="item-text">
                                                        Items
                                                    </div>
                                                </div>
                                            </a>
                                        </a>
                                    </div>

                                </div>
                                <div className="sidebar-bottom">
                                    <div onClick={() => handleClick(0)}>
                                        <Icon className={"text-xl"} icon={expandItem ? "sign-in" : "sign-out"}/>
                                    </div>
                                </div>
                            </div>`
    const [clItem, setClItem] =useState(-1)
    const handleClick = (value) => {
        if (value === clItem) {
          setClItem(-1)
        } else {
            setClItem(value)
        }
    }

    return (
        <div className="mb-xxl font-text">
            <div className="section">
                <div className="section">
                    <div className="title-xxl mb-l">SideBar</div>
                    <div className="section">
                        <div className="title-l">
                            Cách sử dụng
                        </div>
                        <div className="mb-xl">
                            <div className="text-m">Là một phần của giao diện, thường nằm ở bên trái hoặc bên phải
                                trang, chứa các liên kết hoặc biểu tượng chức năng.
                                <br/>Thường mở và đóng để giữ giao diện gọn gàng và tối ưu hóa không gian.
                            </div>
                        </div>


                    </div>

                </div>
                <div className="section">
                    <div className="section">

                        <div className="col is-relative columns border  is-relative rounded-t-l" style={{height: '50vh'}}>
                            <div className={` sidebar  is-relative border-r ${expandItem ? "" : "hide"}`}
                                 style={{height: '100%'}}>
                                <div className="sidebar-header">
                                    <div className="sidebar-brand">
                                        <img src={logo} alt=""/>
                                    </div>
                                    <h3>Duck</h3>
                                </div>
                                <div className={`sidebar-main  is-relative  mt-s`}>
                                    <div className="sidebar-items">
                                        <a key={2311} id={`item${2311}`} className={`item ${clItem===2311?"show":""}`} onClick={() => handleClick(2311)} >
                                            <div className="sidebar-btn">
                                                <Icon className="sidebar-icon text-m mx-s" icon={"circle"}/>
                                                <div className="item-text ">
                                                    Items
                                                </div>
                                                <Icon icon={expandMenu?"up-direction":"down"}></Icon>
                                            </div>
                                            <div className="item-collapse">
                                                <a className="item">
                                                    <div className="sidebar-btn">
                                                        <Icon className="sidebar-icon text-m mx-s" icon={"circle"}/>
                                                        <div className="item-text ">
                                                            Items
                                                        </div>
                                                    </div>
                                                </a>
                                                <a className="item  ">
                                                    <div className="sidebar-btn">
                                                        <Icon className="sidebar-icon text-m mx-s" icon={"circle"}/>
                                                        <div className="item-text ">
                                                            Items
                                                        </div>
                                                    </div>
                                                </a>
                                                <a className="item active">
                                                    <div className="sidebar-btn">
                                                        <Icon className="sidebar-icon text-m mx-s" icon={"circle"}/>
                                                        <div className="item-text ">
                                                            Items
                                                        </div>
                                                    </div>
                                                </a>
                                                <a className="item ">
                                                    <div className="sidebar-btn">
                                                        <Icon className="sidebar-icon text-m mx-s" icon={"circle"}/>
                                                        <div className="item-text ">
                                                            Items
                                                        </div>
                                                    </div>
                                                </a>

                                            </div>


                                        </a>
                                        <a key={1111} id={`item${1111}`} className={`item active`}  >
                                            <div className="sidebar-btn">
                                                <Icon className="sidebar-icon text-m mx-s" icon={"circle"}/>
                                                <div className="item-text ">
                                                    Items
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                </div>
                                <div className="sidebar-bottom">
                                    <div className={"btn btn-ghost"} onClick={() => setExpandItem(!expandItem)}>
                                        <Icon className={"text-xl px-m"} icon={expandItem ? "sign-in" : "sign-out"}/>
                                    </div>
                                </div>
                            </div>
                            <h1 className="col h-xxl p-xxl text-sliver">
                            </h1>
                        </div>
                        <HiLightCode codeString={sidebarString}></HiLightCode>
                    </div>

                    <div className="section">
                        <div className="title-xxl mb-l">SideBar Brand</div>

                        <div className="mb-xl">
                            <div className="title-m mt-m">Logo và Thông Tin Thương Hiệu:</div>
                            <div className="text-m">Thường chứa logo của trang web hoặc ứng dụng để củng cố thương hiệu.
                                <br/> Có thể hiển thị thông tin về người dùng đăng nhập hoặc avatar.
                            </div>
                        </div>

                        <div className="mb-xl">
                            <div className="title-m mt-m">
                                Thực Hiện Chức Năng Tương Tác:
                            </div>
                            <div className="text-m">
                                Thường chứa logo của trang web hoặc ứng dụng để củng cố thương hiệu.
                                <br/> Có thể hiển thị thông tin về người dùng đăng nhập hoặc avatar.
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <div className="section">
                            <div className="title-xxl mb-l">SideBar Item</div>

                            <div className="mb-xl">
                                <div className="title-m mt-m">
                                    Liên Kết và Chức Năng:
                                </div>
                                <div className="text-m">
                                    Cung cấp liên kết đến các phần quan trọng khác của trang hoặc ứng dụng.
                                    <br/>Chứa các chức năng như menu điều hướng, tìm kiếm, và cài đặt.
                                </div>
                            </div>
                            <div className="mb-xl">
                                <div className="title-m mt-m">
                                    Danh Mục hoặc Dữ Liệu Quan Trọng:
                                </div>
                                <div className="text-m">
                                    Có thể chứa danh sách danh mục hoặc dữ liệu quan trọng mà người dùng có thể chuyển
                                    đến
                                    hoặc xem chi tiết.

                                </div>
                            </div>
                        </div>

                        <div className="mb-xl">
                            <div className="title-m mt-m">Liên Kết và Chức Năng:</div>
                            <div className="text-m">Cung cấp liên kết đến các phần quan trọng khác của trang hoặc ứng
                                dụng.
                                <br/> Chứa các chức năng như menu điều hướng, tìm kiếm, và cài đặt.
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="section">
                            <div className="title-xxl mb-l">SideBar Bottom</div>

                            <div className="mb-xl">
                                <div className="title-m mt-m">
                                    Chức Năng Thu Nhỏ/ Mở Rộng:
                                </div>
                                <div className="text-m">
                                    Có thể có chức năng thu nhỏ hoặc mở rộng để người dùng có thể tạm thời ẩn hoặc hiển
                                    thị
                                    sidebar.
                                </div>
                            </div>
                            <div className="mb-xl">
                                <div className="title-m mt-m">
                                    Responsive Design:
                                </div>
                                <div className="text-m">
                                    Cần được thiết kế để đáp ứng và phù hợp với kích thước màn hình khác nhau.
                                    <br/>Trong một số trường hợp, có thể được ẩn trên màn hình nhỏ và hiển thị khi người
                                    dùng chọn một biểu tượng hoặc nút.

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="note">
                            <div className="note-content">
                                <div className="note-title">Hiệu Ứng và Chuyển Động:</div>
                                <div className="note-info"> Sử dụng hiệu ứng và chuyển động để tạo trải nghiệm giao diện
                                    người dùng mượt mà và hấp dẫn.
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="section">


                    </div>
                </div>


            </div>

        </div>

    );
};

export default SideBarSection;

import React from 'react';
import {Layout42, Layout42Main} from "../../Support/Layout";

function BreadCrumbSection(props) {
    const breadCrumbString = `<ol class="breadcrumb">\n<li class="breadcrumb-item">
		<div class="hi-text-bold">Hine.</div>
	</li>
	<li class="breadcrumb-item">UIUX</li>
	<li class="breadcrumb-item active" \naria-current="page"> DuckEditor System </li>
</ol>`
    return (
        <div className="section ">
            <div className="title-xxl mb-l">BreadCrumb</div>
            <div className="section">
                <div className=" title-l mb-l">
                    State
                </div>
                <Layout42Main>
                    <div className="p-s rounded-s  rounded-l bg-light ">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <div className="size-l">
                                    <svg width="300" height="300" viewBox="0 0 300 300" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M262.229 108.209C261.118 107.565 261.28 105.913 262.495 105.497L272.059 102.22C277.563 100.335 277.563 92.5503 272.059 90.6646L234.693 77.863C231.989 76.9365 229.177 78.9461 229.177 81.8046L229.177 89.035C229.177 89.0362 229.175 89.0369 229.174 89.0363V89.0363C229.173 89.0357 229.172 89.0364 229.172 89.0376L229.172 111.522C229.172 119.713 238.048 124.826 245.134 120.716L262.229 110.8C263.224 110.223 263.224 108.786 262.229 108.209V108.209Z"
                                              fill="#DBDBDB"/>
                                        <path
                                            d="M70.0122 165.775C70.0122 156.502 77.5295 148.984 86.8026 148.984H220.722V238.748C220.722 248.532 212.791 256.463 203.008 256.463H123.751C94.0721 256.463 70.0122 232.403 70.0122 202.724V165.775Z"
                                            fill="#0A0A0A"/>
                                        <path
                                            d="M169.174 148.983C169.174 168.312 161.496 186.85 147.828 200.517C134.161 214.185 115.623 221.863 96.2944 221.863C76.9655 221.863 58.4282 214.185 44.7606 200.517C31.093 186.85 23.4146 168.312 23.4146 148.983L96.2944 148.983H169.174Z"
                                            fill="#242424"/>
                                        <path
                                            d="M179.837 75.0162C179.837 57.8325 193.767 43.9023 210.951 43.9023V43.9023C228.135 43.9023 242.065 57.8325 242.065 75.0162V225.347C242.065 242.53 228.135 256.46 210.951 256.46H192.314C185.423 256.46 179.837 250.874 179.837 243.983V75.0162Z"
                                            fill="#242424"/>
                                    </svg>
                                </div>
                            </li>
                            <li className="breadcrumb-item">
                                <div className="hi-text-bold">Hine.</div>
                            </li>
                            <li className="breadcrumb-item">UIUX</li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Duck System
                            </li>
                        </ol>
                    </div>
                    <div className="text-m">Indicate the current page’s location within a navigational hierarchy
                        that
                        automatically adds separators via CSS.
                    </div>
                </Layout42Main>
                
            </div>
            <div className="section">
                <Layout42 size={"s"} code={breadCrumbString}>
                    <div>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <div className="size-l">
                                    <svg width="300" height="300" viewBox="0 0 300 300" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M262.229 108.209C261.118 107.565 261.28 105.913 262.495 105.497L272.059 102.22C277.563 100.335 277.563 92.5503 272.059 90.6646L234.693 77.863C231.989 76.9365 229.177 78.9461 229.177 81.8046L229.177 89.035C229.177 89.0362 229.175 89.0369 229.174 89.0363V89.0363C229.173 89.0357 229.172 89.0364 229.172 89.0376L229.172 111.522C229.172 119.713 238.048 124.826 245.134 120.716L262.229 110.8C263.224 110.223 263.224 108.786 262.229 108.209V108.209Z"
                                              fill="#DBDBDB"/>
                                        <path
                                            d="M70.0122 165.775C70.0122 156.502 77.5295 148.984 86.8026 148.984H220.722V238.748C220.722 248.532 212.791 256.463 203.008 256.463H123.751C94.0721 256.463 70.0122 232.403 70.0122 202.724V165.775Z"
                                            fill="#0A0A0A"/>
                                        <path
                                            d="M169.174 148.983C169.174 168.312 161.496 186.85 147.828 200.517C134.161 214.185 115.623 221.863 96.2944 221.863C76.9655 221.863 58.4282 214.185 44.7606 200.517C31.093 186.85 23.4146 168.312 23.4146 148.983L96.2944 148.983H169.174Z"
                                            fill="#242424"/>
                                        <path
                                            d="M179.837 75.0162C179.837 57.8325 193.767 43.9023 210.951 43.9023V43.9023C228.135 43.9023 242.065 57.8325 242.065 75.0162V225.347C242.065 242.53 228.135 256.46 210.951 256.46H192.314C185.423 256.46 179.837 250.874 179.837 243.983V75.0162Z"
                                            fill="#242424"/>
                                    </svg>
                                </div>
                            </li>
                            <li className="breadcrumb-item">
                                <div className="hi-text-bold">Hine.</div>
                            </li>
                            <li className="breadcrumb-item">UIUX</li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Duck System
                            </li>
                        </ol>
                    </div>
                </Layout42>
            </div>

        </div>
    );
}

export default BreadCrumbSection;
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {userLogin} from "../redux/Auth/Action";
import {Navigate, useNavigate} from "react-router-dom";
import {useToast} from "../Duck/DuckReact/Toast/useToast";
import {useForm} from 'react-hook-form'
import logo from "../static/logo.svg";

function Login(props) {

    const {loading, userInfo, error} = useSelector((state) => state.auth)
    const dispatch = useDispatch();

    const toast = useToast()
    const [isLogin, setLogin] = useState(false)
    const {register, handleSubmit} = useForm()
    const navigate = useNavigate()
    useEffect(() => {
        if (userInfo) {
            navigate('/addImage')
        }
    }, [navigate, userInfo])


    const submitForm = (data) => {
        dispatch(userLogin(data))
    }


    if (isLogin) {
        return <Navigate to="/addImage"/>;
    }

    return (
        <div className="is-full-y is-center border p-l is-flex vertical">

            <div className="border px-xl py-l ">
                <div className="text-center">
                    <img src={logo} className={"size-x-xxl"} alt=""/>
                </div>
                <form className={"gap-m  is-flex vertical align-center"} onSubmit={handleSubmit(submitForm)}>
                    <div className="filed ">

                        <div className="label">
                            <label className="label" htmlFor="username">Username</label>
                        </div>
                        <div className="input-field">
                            <input   {...register('username')} type="text" placeholder={"Type something..."}
                                     required/>
                        </div>

                    </div>
                    <div className="filed">
                        <div className="label">
                            <label className="label" htmlFor="Password">Password</label>
                        </div>
                        <div className="input-field">
                            <input  {...register('password')} type="password" placeholder={"Type something..."}
                                    required/>
                        </div>

                    </div>
                    <button disabled={loading} type='submit' className={`btn  ${loading ? "loading" : " "}`}>Login
                    </button>

                </form>
            </div>
        </div>
    );
}

export default Login;
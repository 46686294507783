import React, {useState} from 'react';
import {Layout42} from "../../Support/Layout";
import {useToast} from "../../../../DuckReact/Toast/useToast";
import DuckSelect from "../Input/DuckSelect";

const types = [{type: "Success", value: 0}, {type: "Info", value: 1}, {type: "Warning", value: 2}, {
    type: "Error",
    value: 3
}]

function NotificationSection(props) {
    const toast = useToast()
    const [toastType, setTT] = useState(0)
    const handleClick = (num) => {
        if (toastType === 0)
            toast.success("Cập nhật thành công!")
        else if (toastType === 1)
            toast.info("Vui lòng cập nhật địa chỉ!")
        else if (toastType === 2)
            toast.warning("Hãy kiểm tra lại dữ liệu đăng nhập !")
        else
            toast.error("Dữ liệu không hợp lệ!")
    }
    return (
        <div className="section ">
            <div className="title-l">
                Cách sử dụng
            </div>
            <div className="mb-xl">
                <div className="text-m">Các loại toast thường được sử dụng
                    để hiển thị thông báo tới người dùng về kết quả của một hành động hoặc để cảnh báo về tình trạng cụ
                    thể. <br/>
                    Dưới đây là tác dụng thông thường của mỗi loại toast:
                </div>
            </div>


            <Layout42 size={'s'} code={""}>
                <div className={"is-center"}>
                    <div onClick={(e) => handleClick()} className="btn">Show Toast</div>
                </div>
                <>
                    <DuckSelect data={types} placeholder={"Select toast"}
                                child={({item, index,}) => (
                                    <div title={item.type} key={index} className={"p-s"} onClick={() => {
                                        setTT(item.value)
                                    }}>
                                        {item.type}
                                    </div>
                                )}/>
                </>
            </Layout42>
            <div className="mb-xl">
                <div className="title-m mt-m">
                    Thành công
                </div>
                <div className="text-m">
                   <span className={"title-m"}>
                       Mục Đích:
                   </span>  Hiển thị khi một hành động hoặc quyết định đã được thực hiện thành công.
                    <br/>
                    <span className={"title-m"}>
                        Tính Chất:
                    </span> Thông thường có màu xanh lá cây hoặc màu tương tự để tạo ra một cảm giác tích cực.
                </div>
            </div>
            <div className="mb-xl">
                <div className="title-m mt-m">
                    Thông tin
                </div>
                <div className="text-m">
                   <span className={"title-m"}>
                        Mục Đích:
                    </span>  Cung cấp thông tin chung hoặc cảnh báo mà không phải là một tình trạng lỗi.
                    <br/>
                    <span className={"title-m"}>
                        Tính Chất:
                    </span> Thường có màu xanh dương hoặc màu tương tự để làm nổi bật thông tin mà người dùng cần biết.
                </div>
            </div>
            <div className="mb-xl">
                <div className="title-m mt-m">
                    Cảnh báo
                </div>
                <div className="text-m">
                 <span className={"title-m"}>
                        Mục Đích:
                    </span> Cảnh báo về một điều gì đó có thể gây ra vấn đề trong tương lai hoặc yêu cầu sự chú ý từ phía người dùng.
                    <br/>
                    <span className={"title-m"}>
                        Tính Chất:
                    </span> Thường có màu vàng hoặc màu cam để tạo ra một cảm giác cảnh báo.
                </div>
            </div>
            <div className="mb-xl">
                <div className="title-m mt-m">
                    Thất bại
                </div>
                <div className="text-m">
                  <span className={"title-m"}>
                        Mục Đích:
                    </span>  Thông báo về một lỗi hoặc vấn đề xảy ra trong quá trình thực hiện một hành động.
                    <br/>
                    <span className={"title-m"}>
                        Tính Chất:
                    </span>Thường có màu đỏ hoặc màu tương tự để làm nổi bật tính chất tiêu cực.
                </div>
            </div>
        </div>
    );
}

export default NotificationSection;
import {createAsyncThunk} from '@reduxjs/toolkit';
import {getNews} from "../../Api/Auth/Services/News/NewsApi";

// Thực hiện request và lấy dữ liệu


// Action creator sử dụng createAsyncThunk
export const initializeData = createAsyncThunk('editor/initializeData', async (news) => {
    console.log(news)
    return await  JSON.parse(news.News.data);
});
import React, {useCallback, useEffect, useState, useRef} from "react";

import {useDispatch, useSelector} from "react-redux";
import TableContext from "./Context/TableContext";
import {addContext, deleteContext, updateActive, updateContent} from "../../redux/Editor/editorSlice";
import ImageContext from "./Context/ImageContext";
import ReactDOM from "react-dom";
import CustomContext from "./Context/CustomContext";
import toast from "../DuckReact/Toast/Toast";
import {useToast} from "../DuckReact/Toast/useToast";

function ContextEdit({context}) {
    const NEW_KEY = 'm';
    const contentEditableRef = useRef(null);
    const [className, setClassName] = useState("")
    const dispatch = useDispatch();
    const activeId = useSelector(state => state.editor.activeId);
    const data = useSelector(state => state.editor.listContext[state.editor.listContext.findIndex(ct => ct.id === context.id)])
    useEffect(() => {
        // Đặt vị trí con trỏ vào cuối sau mỗi cập nhật
        if (contentEditableRef.current)
            setEndOfContentEditable(contentEditableRef.current);
    }, [data.content]);
    const setEndOfContentEditable = (contentEditableElement) => {
        var range, selection;
        if (document.createRange) {
            // IE 9+ và các trình duyệt khác
            range = document.createRange();
            range.selectNodeContents(contentEditableElement);
            range.collapse(false);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        } else if (document.selection) {
            // IE 8 và thấp hơn
            range = document.body.createTextRange();
            range.moveToElementText(contentEditableElement);
            range.collapse(false);
            range.select();
        }
    };
    const getSelectedText = () => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            dispatch(updateActive(data.id))
            return data.id;
        }
    };

    const onKeyDown = useCallback((e) => {
        document.designMode = "on";
        updateActive(data.id)
        if (e.ctrlKey && e.key === NEW_KEY) {
            addTextSection(data.id)
        }
        document.designMode = "off";
    })

    useEffect(() => {
        setClassName(`editable ${data.defaultState.textBold ? "text-bold " : ""} 
        ${data.defaultState.header}  ${data.defaultState.color} 
        ${data.defaultState.quote ? "is-quote" : ""} 
        ${data.defaultState.textItalic ? "text-italic" : ""}
        ${data.defaultState.textIndent ? "text-indent" : ""}
        
        ${data.defaultState.textUnderline ? "text-underline" : ""}
        ${data.defaultState.textUppercase ? "text-uppercase" : ""}
        ${data.defaultState.textAlign} 
    `)
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        }
        // eslint-disable-next-line
    }, [onKeyDown, context])

    const handleAddContext = (id) => {
        // Tạo data cho context mới (thay đổi dữ liệu theo yêu cầu của bạn)
        const newContextData = {
            header: "p",
            color: "black",
            textBold: false,
            textItalic: false,
            textUnderline: false,
            textUppercase: false,
            textAlign: "left",
            list: "",
            textIndent: "",
            attachment: "",
            linkEmbed: "",
            image: false,
            icon: "",
            quote: "",
            content: "new"
        };

        // Dispatch action addContext với id và data tương ứng
        dispatch(addContext({id: id, data: newContextData}));
    };
    const addTextSection =
        (id) => {
            handleAddContext(activeId)
        }
    const toast = useToast()
    const [copy, setCopy] = useState(false);
    const actionCopyID = (id) => {
        try {
            const textArea = document.createElement('textarea');
            textArea.value = id;

            // Set the position to be off-screen
            textArea.style.position = 'fixed';
            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.opacity = '0';

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            document.execCommand('copy');
            document.body.removeChild(textArea);

            setCopy(true);
            setTimeout(() => {
                setCopy(false);
            }, 1000); // 1 second delay
            toast.success("copy")
        } catch (error) {
            console.error('Error copying to clipboard:', error);
        }
    };

    const deleteTextSection = (id) => {
        dispatch(deleteContext(id))
    };
    const handleInputChange = (e) => {
        const element = ReactDOM.findDOMNode(contentEditableRef.current);
        dispatch(updateContent({id: activeId, updateContent: element.innerHTML}))
    };
    return (
        <div className={`context is-relative ${activeId === context.id ? "context-active" : ""}`}>
            <div className="context-place text-sliver">
                <div onClick={() => deleteTextSection(data.id)}
                     className="delete  text-s icon icon-close "
                     title="delete section"/>
                <div onClick={() => addTextSection(data.id)} className=" text-xs icon icon-add "
                     title="add action"/>
                <div onClick={() => actionCopyID(data.id)} className=" text-xs icon icon-copy "
                     title="copy action"/>

            </div>
            <div id={data.id} suppressContentEditableWarning={true} onMouseUp={getSelectedText}
                 className={className}>
                {data.defaultState.image !== false ? <ImageContext context={data}></ImageContext>
                    : null}
                {data.defaultState.table ? <TableContext context={data}>
                    </TableContext>
                    : null}
                {data.defaultState.isComponent ? <CustomContext context={data}/>
                    : null}
                {data.defaultState.table || data.defaultState.image !== false || data.defaultState.isComponent === true ? null
                    : <div ref={contentEditableRef} onInput={e => handleInputChange(e)} contentEditable={true}
                           dangerouslySetInnerHTML={{__html: data.content}}>
                    </div>
                }

            </div>
        </div>

    )
}

export default ContextEdit
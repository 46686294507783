import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getPaper} from "../../../../Api/Auth/Services/News/PaperApi";
import {useToast} from "../../../../Duck/DuckReact/Toast/useToast";
import {Icon} from "../../../../Duck/DuckDesignSystem/Source/DuckIcon";
import {ContextMenu} from "../../../../Duck/DuckEditor/Context/TableContext";
import Pagination from "../../pagination";
import NewsDataTable from "../../NewsDataTable";

export const NewsSection = ({categories}) => {
    const [searchParams] = useSearchParams();
    const [limit,setLimitState] = searchParams.get("limit") || "8"
    const [paper, setPaper] = useState([])
    const [category, setCategoryState] = useState("")
    const [page, setPageState] = useState(searchParams.get("page") || 1)
    const [loading, setLoading] = useState(true)
    const [filter, setFilterState] = useState("all")
    const setPage = (newPage) => {
        setPageState(newPage);
        navigate(`/news?page=${newPage}&limit=${limit}`);
    };
    const setFilter = (newsFilter) => {
        setPage(1)
        if (newsFilter==="all"){
            setFilterState("all");
            navigate(`/news?page=${page}&limit=${limit}`);
        }else {
            setFilterState(newsFilter);
            navigate(`/news?page=${page}&limit=${limit}&category=${newsFilter}`);
        }

    };

    useEffect(() => {
        getPaper(filter, page, limit).then((data) => {
                setPaper(data);
                setLoading(false);
            }
        )
    }, [filter,page,limit])
    const toast = useToast()

    const navigate = useNavigate();

    const [addNews, setAddNews] = useState(false)


    const addMenu = () => {
        const cateMenu = [];

        if (!categories || categories.length === 0) {
            return cateMenu; // Trả về mảng rỗng nếu categories không hợp lệ
        }

        // Thêm vào mảng chỉ khi categories có giá trị
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i]; // Đảm bảo truy cập đúng
            cateMenu.push({
                actionName: category.Name, // Sửa lại thành category.name
                action: function () {
                    navigate(`new/${category.Name}`); // Sửa lại thành category.name
                },
            });
        }
        return cateMenu; // Trả về cateMenu đã được tạo
    };
    if (loading) {
        return <div>Loading...</div>;
    }
    const addNewsMenu = addMenu();
    return <>
        <div className="  is-flex vertical  is-overflow is-grow">
            <div className=" columns p-m  layout-4-2-1 ">
                <div className="col ">
                    <input className={"is-fill-x"} placeholder={"Search"} type="text"/>
                </div>
                <div className="col ">
                    <select onChange={e => setFilter(e.target.value)} className={"is-fill-x"} value={filter}
                            placeholder={"All"}>
                        <option value={"all"}>
                            All
                        </option>
                        {categories?.map((item, index) => {
                            return <option key={index} value={item.Name}>
                                {item.Name}
                            </option>
                        })}
                    </select>
                </div>
                <div className="is-relative">
                    <div onClick={()=>setAddNews(!addNews)} className="btn is-fill-x ">Bài viết mới <Icon icon={"down"} className={"title-m ml-s"}></Icon></div>
                    {addNews ?
                        <ContextMenu menu={addNewsMenu}
                                     position={{top: 40, right: 0}}></ContextMenu> : null}
                </div>
            </div>
            <div className="col columns bg-white  is-flex vertical    is-grow">
                <NewsDataTable paper={paper} loading={loading} setFilter={setFilter}></NewsDataTable>
                <div className="blank is-grow "></div>
                <div className="p-m">
                    <Pagination total={paper.totalPages} limit={paper.limit} setLoading={setLoading} setPage={setPage}/>
                </div>
            </div>
        </div>
    </>

}
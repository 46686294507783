import React, {useState} from 'react';
import {Icon} from "../../../../Duck/DuckDesignSystem/Source/DuckIcon";

function ElementMetaData ({ onMetadataChange }) {
    const [metaData, setMetaData] = useState({
        source: "hine",
        mark: [
            {xOffset: 1, yOffset: 1, data: "...",id:"..."},
        ],
    });
    // Cập nhật source và gọi callback khi có thay đổi
    const updateSource = (newSource) => {
        const updatedMetaData = {
            ...metaData,
            source: newSource,
        };

        setMetaData(updatedMetaData);
        onMetadataChange(updatedMetaData); // Gọi callback để gửi dữ liệu lên component cha
    };

    // Hàm để xóa mục dựa trên chỉ số
    const deleteMark = (index) => {
        setMetaData((prev) => {
            const newMark = prev.mark.filter((_, i) => i !== index); // Xóa mục dựa trên chỉ số
            const updatedMetaData = {
                ...prev,
                mark: newMark, // Cập nhật mảng 'mark'
            };

            // Cập nhật state với 'metaData' đã xóa
            onMetadataChange(updatedMetaData); // Gọi callback để gửi thay đổi lên component cha
            return updatedMetaData; // Trả về state đã cập nhật
        });
    };

    const updateMark = (index, field, value) => {
        const updatedMark = [...metaData.mark];
        updatedMark[index][field] = value;

        const updatedMetaData = {
            ...metaData,
            mark: updatedMark,
        };

        setMetaData(updatedMetaData);
        onMetadataChange(updatedMetaData); // Gọi callback để cập nhật
    };
    // Thêm một trường mới vào 'mark'
    const addMark = () => {
        setMetaData((prev) => ({
            ...prev,
            mark: [...prev.mark, {xOffset: 0, yOffset: 0, data: ""}],
        }));
    };

    return (<>
            <div className={" "}>
                <h3 className={"p-m"}>METADATA</h3>

                <div className="field ">
                    <label>Source</label>
                    <input
                        value={metaData.source}
                        onChange={(e) => updateSource(e.target.value)}
                    />
                </div>
                <div className={" y-quarter rounded-m is-scroll-y  my-s bg-light"}>
                    {metaData.mark.map((mark, index) => (
                        <div key={index} className="columns border-b rounded-x field unwrap align-start">
                            <div className="col">
                                <label>id</label>
                                <input
                                    type="text"
                                    value={mark.id}
                                    onChange={(e) => updateMark(index, 'id', e.target.value)}
                                />
                            </div>
                            <div className="col">
                                <label>X</label>
                                <input
                                    type="number"
                                    value={mark.xOffset}
                                    onChange={(e) => updateMark(index, 'xOffset', e.target.value)}
                                />
                            </div>

                            <div className="col">
                                <label>Y</label>
                                <input
                                    type="number"
                                    value={mark.yOffset}
                                    onChange={(e) => updateMark(index, 'yOffset', e.target.value)}
                                />
                            </div>

                            <div className="col-2">
                                <label>Data</label>
                                <input
                                    type="text"
                                    value={mark.data}
                                    onChange={(e) => updateMark(index, 'data', e.target.value)}
                                />

                            </div>

                            <div className="btn-ghost " onClick={() => deleteMark(index)} ><Icon icon={"close"}
                                     className={"title-m"}></Icon>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="p-m">
                    <div className="btn btn-outline is-fill-x" onClick={addMark}>Add Field <Icon icon={"add"}
                                                                                                 className={"title-m"}></Icon>
                    </div>

                </div>
            </div>

        </>
    );
};

export default ElementMetaData;
import React from 'react';
import {Forest} from "../../../ThreeD/ForestPage";
import Work from "../../../menu-item/Work";

function CustomContext({context}) {
    return (
        <div>
            {context.component==="Forest"? <Forest/> :null}
            {context.component==="Work"? <Work/> :null}
        </div>
    );
}

export default CustomContext;
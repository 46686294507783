import React, {useEffect, useRef, useState} from 'react';
import {handelGetImage, handleDeleteImage} from "../../../Api/Auth/Services/Image/Image";
import DragDropFile from "./DragDropFile";
import {CopyToClipboard, Icon} from "../../../Duck/DuckDesignSystem/Source/DuckIcon";
import Tabs, {TabItem} from "../../../Duck/DuckReact/Tabs";
import {useSelector} from "react-redux";
import {useToast} from "../../../Duck/DuckReact/Toast/useToast";
import Modal from "../../../Duck/DuckReact/Modal";
import CategorySection from "../News/AdminSection/CategorySection";

function ImageDashBoard(props) {
    const [copy, setCopy] = useState(false)
    const [modal, setModal] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [deleteImg, setDeleteImg] = useState('')
    const tabs = [{
        tab: "Folder 1"

    }, {
        tab: "Folder 1"

    }, {
        tab: "Folder 1"

    },
        {
            tab: "Folder 1"

        },]
    const openModal = () => {
        setModal(true);
        if (modal) {
            document.body.style.overflow = 'hidden'
        }
    };

    const closeModal = () => {
        setModal(false);

    };

    const openModalDelete = (img) => {
        setModalDelete(true);
        setDeleteImg(img)
        if (modalDelete) {
            document.body.style.overflow = 'hidden'
        }
    };

    const closeModalDelete = () => {
        setModalDelete(false)
        setDeleteImg('')
    }
    const handleClick = () => {

    }
    const toast = useToast();
    const onDelete = (uuid) => {
        let resMessage = ''
        handleDeleteImage(uuid).then(res => {
            toast.success(JSON.stringify(res['data']['message']))
        })
        setModalDelete(false)
        setDeleteImg('')
    }
    const [images, setImages] = useState([])
    useEffect(() => {
        handelGetImage().then((response) => setImages(response["images"]))
    }, [deleteImg])

    return (
        <>
            <Modal size={"s"} isOpen={modalDelete}>
                <div className="modal-header text-center ">
                    <h3>Xác nhận</h3>
                </div>
                <div className="modal-body p-l text-center">
                    Bạn có muốn xóa ảnh này không ?
                </div>
                <div className="modal-bottom is-flex jt-end  p-m">
                    <div onClick={() => onDelete(deleteImg)} className="btn btn-s ">Xác nhận</div>
                    <div onClick={() => setModalDelete(false)} className="btn btn-outline btn-s ">Hủy</div>
                </div>
            </Modal>
            <div className="container-xxl    is-grow  columns layout-1-3 l-vertical  ">
                <div className="col  is-flex vertical  is-grow is-relative">
                    <div className="is-sticky" style={{top: "10%"}}>
                        <DragDropFile></DragDropFile>
                    </div>
                </div>
                <div className="col is-grow border-l">
                    <div className="p-m grid-4 grid-l-3 grid-xs-2">
                        {images.length === 0 ? (
                            <div className="empty-state">Không có hình ảnh nào.</div>
                        ) : images.map((image, index) => {
                            return <div key={index} className="card vertical border rounded-s">
                                <div className="card-img size-x-xl-x2">
                                    <img className="ratio-10-9 is-fill-y" src={image.url} alt=""/>
                                </div>
                                <div className="card-content p-m bg-light">
                                    <div className=" is-flex gapx-xs ">
                                        <div onClick={() => openModalDelete(image.uuid)}
                                             className="btn btn-secondary btn-outline "><Icon icon="trash-can"
                                                                                           className="text-secondary text-l"></Icon>
                                        </div>
                                    <div className=" is-flex jt-end gapx-xs ">
                                        {copy === index ?
                                            <div className="btn btn-primary   is-fill-x ">Đã sao
                                                chép</div> : <div onClick={async (e) => {
                                                e.stopPropagation()
                                                var delayInMilliseconds = 1000; //1 second
                                                setCopy(index)
                                                CopyToClipboard(image.URL)
                                                setTimeout(function () {
                                                    setCopy(false)
                                                    console.log(copy)
                                                }, delayInMilliseconds);
                                            }} className="btn btn-primary ">Copy Link</div>}
                                        <div
                                            className="btn btn-danger disable btn-outline ">Chỉnh sửa
                                        </div>
                                        <div onClick={() => openModalDelete(image.uuid)}
                                             className="btn btn-danger btn-outline "><Icon icon="trash-can"
                                                                                           className="text-danger text-l"></Icon>
                                        </div>


                                    </div>

                                </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </>)
}


export default ImageDashBoard;
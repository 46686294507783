import React from 'react';
import {SlideElement} from "../Duck/DuckDesignSystem/animate/AnimateElement";

function DuckPlugin(props) {
    return (
        <div>

            <div className="container-xl ">
                <div className=" hero has-background is-flex jt-center align-center is-half   ">
                    <img src={"https://i.imgur.com/fKfrWXx.png"} alt=""/>
                    <div className=" container-xl align-center jt-center ">
                        <div className="text-center is-sticky">
                            <SlideElement>
                                <div className=" hi-4xl text-bold font-text text-semi">   Duck Plugin
                                </div>
                                <div className="text-l font-text">Build your design faster with
                                    <br/>  Real content  </div>
                            </SlideElement>
                        </div>
                    </div>
                </div>
                <div className="columns gap-x">
                    <img src={"https://i.imgur.com/cZMh9Mc.png"} alt=""/>
                    <img src={"https://i.imgur.com/uYL2Gnm.png"} alt=""/>
                    <img src={"https://i.imgur.com/cvfgkFM.png"} alt=""/>
                    <img src={"https://i.imgur.com/RIH9MKe.png"} alt=""/>
                    <img src={"https://i.imgur.com/sNPEaiQ.png"} alt=""/>
                    <img src={"https://i.imgur.com/7J4HkAA.png"} alt=""/>
                    <img src={"https://i.imgur.com/E7oeriT.png"} alt=""/>
                    <img src={"https://i.imgur.com/NOkNimx.png"} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default DuckPlugin;
import React, {useState} from 'react';
import Editor from "../../DuckEditor/Editor";
import RenderEditor from "../../DuckEditor/RenderEditor";
import {Highlight, themes} from "prism-react-renderer";
import FormatHTMLString from "./Controler/JSXtoString";
import {Icon} from "./DuckIcon";
import {Segment} from "./Section/Input/DuckSelect";

function Tool(props) {
    const [direction, setDirection] = useState(0)
    const code = <div class="segment">
        <div class="segment-sub" style="opacity: 0;"></div>
        <div class="btn  gapx-xs active">
            <div class="text-center icon icon-device-mobile text-l"></div>
        </div>
        <div class="btn  gapx-xs ">
            <div class="text-center icon icon-device-tablet text-l"></div>
        </div>
        <div class="btn  gapx-xs ">
            <div class="text-center icon icon-device-pc-tower text-l"></div>
        </div>
    </div>
    return (
        <div className="Main  ">
            <div className="container-xl is-flex jt-center pt-xxl ">
                <Highlight
                    theme={themes.oneDark}
                    code={FormatHTMLString(code)}
                    language="jsx"
                >
                    {({className, style, tokens, getLineProps, getTokenProps}) => (
                        <pre style={{flexGrow: "1", whiteSpace: "pre-wrap", borderRadius: "0", ...style}}>
                                    {tokens.map((line, i) => (
                                        <div key={i} {...getLineProps({line})}>
                                            {line.map((token, key) => (
                                                <span className={"text-l"} key={key} {...getTokenProps({token})} />
                                            ))}
                                        </div>
                                    ))}
                                </pre>
                    )}
                </Highlight>
            </div>
        </div>
    );
}

export default Tool;
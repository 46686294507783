export class NewsModel {
    uuid:string
    Title: string
    Content: string
    CustomUrl:string
    Data : string
    Thumbnail: string
    CategoryStr: string
    Viewer: string
    Like: string
    UnLike: string
    CreateByID: string
    IsUpdate: string
    CreateAt: string


    constructor(item) {
        const date = new Date(Date.parse(item.CreateAt));
        this.uuid = item.uuid;
        this.Title = item.title;
        this.Content = item.content;
        this.Data = item.data;
        this.CustomUrl = item.custom_url;
        this.Thumbnail = item.thumbnail;
        this.CategoryStr = item.category_str;
        this.Viewer = item.viewer;
        this.Like = item.like;
        this.UnLike = item.unLike;
        this.CreateByID = item.create_by_id;
        this.IsUpdate = item.is_update;
        this.IsActive = item.is_active;
        this.CreateAt = item.create_at
    }

}
export const transFormNews = (news)=>{
    const keyMapping = {
        Title: 'title',
        Content: 'content',
        customUrl :"custom_url",
        categoryStr :"category_str",
        Thumbnail:"thumbnail",
        Tags:"tags",
        Data:"data",
        isUpdate:"is_update",
        metaData:"meta_data"
    };
    return Object.entries(news).reduce((acc, [key, value]) => {
        const newKey = keyMapping[key] || key;
        acc[newKey] = value;
        return acc;
    }, {});
}
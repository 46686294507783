import React from 'react';
import {Link, useLocation} from "react-router-dom";

function Footer(props) {
    const { pathname } = useLocation();

    // Danh sách các đường dẫn không muốn hiển thị Footer

    // Kiểm tra xem `pathname` có trong danh sách không

    return (
        <div className="footer  border-t " >
            <div className="border-b py-s">
                <div className="container-xl is-center">
                    <div className="text-xs">
                        Trang web đang được phát triển, 1 vài tính năng có thể gây khó chịu, sử dụng màn hình lớn để đạt được trải nghiệm tốt nhất
                    </div>
                </div>
            </div>
            <div className="container-xl     ">
                <div className=" s-vertical columns py-m">
                    <div className="logo col is-flex vertical align-start">
                        <Link to={"https://hine.studio/"}>
                            <h2  className=" text-bold ">Hine.studio</h2>

                        </Link>
                        <div className="      ">
                            <div className="columns ">
                                <Link className="title-xs text-black" to="https://hine.studio/">    &copy; 2020
                                    - {new Date().getFullYear()}. Powered by Hine Studio <span
                                        className="text-primary title-s">x</span> DuckUI</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col columns">

                    </div>
                    <div className="col columns text-right   align-end">
                        <Link to="https://www.facebook.com/hotuhi12/" target="_blank" rel="noopener noreferrer"
                           className="col    title-s">Facebook</Link>
                        <Link to="https://www.linkedin.com/in/hinestudio" target="_blank" rel="noopener noreferrer"
                           className="col    title-s">LinkedIn</Link>
                        <Link to="https://www.behance.net/hotuhi" target="_blank" rel="noopener noreferrer"
                           className="col     title-s">Behance</Link>

                    </div>

                </div>


            </div>


        </div>
    );
}

export default Footer;
import React, {useRef, useState} from 'react';
import {Player} from "@lottiefiles/react-lottie-player";
import lotF from "../static/lottie/nhan.json";
import CustomHoverTooltip from "../../Duck/DuckDesignSystem/Source/Support/CustomHoverTooltip";
import {SelectedElementCt} from "../Item";
import {Icon} from "../../Duck/DuckDesignSystem/Source/DuckIcon"
import {InputField} from "../../Duck/DuckReact/Input";
import Modal from "../../Duck/DuckReact/Modal";
import DuckEditor from "../../Element/DuckEditor";
import DuckPlugin from "../../Element/DuckPlugin";
import {useOutsideClick} from "../checker";

function SelectedElement(props) {
    const mouseRef = useRef(null);
    const [data, setData] = useState(SelectedElementCt[0])

    const [isVisible, setIsVisible] = useState(false);

    const onMouseEnter = (index) => {
        setIsVisible(true)
        setData(SelectedElementCt[index])
    }
    const onMouseOut = () => {
        setIsVisible(false)
    }

    const [modal, setModal] = useState(false)
    const [element, setElement] = useState(false)


    return (
        <div ref={mouseRef} >
            <CustomHoverTooltip visible={isVisible}>
                <div className=" border  p-m bg-white">
                    <div className="is-flex jt-between unwrap align-center">
                        <div className="title-l text-primary unwrap">{data.title}</div>
                        <Icon className={"title-xl text-primary"} icon={"open-link "}/>
                    </div>
                    <div className="py-s">
                        <div className="title-s">{data.description}</div>
                        <div className="text-xs">{data.content}</div>
                    </div>

                    <div className="grid-2">

                    </div>
                </div>
            </CustomHoverTooltip>
            <Modal size={"pre"} isOpen={modal}>

                <div className="modal-body  is-center">
                    {element==="de"?<DuckEditor></DuckEditor>:element==="dp"?<DuckPlugin></DuckPlugin>:""}
                </div>

                <div className="modal-bottom  ">

                    <div onClick={() => setModal(false)} className="btn ">
                      Close
                    </div>
                </div>
            </Modal>
            <div className=" columns layout-2-1 s-vertical">
                <div onMouseEnter={() => onMouseEnter(0)} onMouseLeave={onMouseOut}
                     className="col border bg-blur  align-center">
                    <div className="is-center">
                        <div onClick={(e) => {
                            setModal(true)
                            setElement("de")
                        }} className="card">
                            <div className="card-image ">
                                <img className={"rounded-m"}
                                     src="https://64.media.tumblr.com/d76b6e959e66834fd29f02ced4e818e9/a86532753ed5d490-01/s2048x3072/2c44b4432f56012cd7d4178a4acc866af4ffdb82.pnj"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col columns layout-1-1 vertical">

                    {/*<div onMouseEnter={() => onMouseEnter(2)} onMouseLeave={onMouseOut}*/}
                    {/*     className="col  border bg-blur align-center jt-center">*/}
                    {/*    <div className="p-m">*/}
                    {/*        <a href={"/element"}>*/}
                    {/*            <img src="https://i.imgur.com/GvgtxqT.png" alt=""/>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}

                    {/*</div>*/}

                    <div onMouseEnter={() => onMouseEnter(4)} onMouseLeave={onMouseOut}
                         className="col border  bg-blur align-center jt-center is-grow">
                        <div className="p-m">
                            <div onClick={(e) => {
                                setModal(true)
                                setElement("dp")
                            }}>
                                <img className={"rounded-s"}
                                     src="https://64.media.tumblr.com/94f9fad4354ef9f51cf01ad0bcd2070a/010c578293051718-eb/s2048x3072/598cdc3a918d53139061e022d6fd01e2eb974176.pnj"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                    <div onMouseEnter={() => onMouseEnter(3)} onMouseLeave={onMouseOut}
                         className="col border bg-blur align-center jt-center is-grow">
                        <div className="p-m">
                            <a href={"/elements"}>
                                <img src="https://i.imgur.com/jYjooVh.png" alt=""/>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default SelectedElement;
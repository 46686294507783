import React from 'react';
import {SlideElement} from "../Duck/DuckDesignSystem/animate/AnimateElement";

function DuckEditor(props) {
    return (
        <div>

            <div className="container-xl ">
                <div className=" hero has-background is-flex jt-center align-center is-half   ">
                    <img src={"https://i.imgur.com/fKfrWXx.png"} alt=""/>
                    <div className=" container-xl align-center jt-center ">
                        <div className="text-center is-sticky">
                            <SlideElement>
                                <div className=" hi-4xl text-bold font-text text-semi">   Duck Editor
                                </div>

                            </SlideElement>
                        </div>
                    </div>
                </div>
                <div className="columns gap-x">
                    <img src={"https://i.imgur.com/hmL13oz.png"} alt=""/>
                    <img src={"https://i.imgur.com/bdudGOC.png"} alt=""/>
                    <video loop autoPlay muted >
                        <source
                            src="https://i.imgur.com/e56BGtT.mp4"
                            type="video/mp4"
                        />
                    </video>
                    <img src={"https://i.imgur.com/oGgCrCh.png"} alt=""/>
                    <img src={"https://i.imgur.com/zwlMVQO.png"} alt=""/>
                    <img src={"https://i.imgur.com/JG7uPod.png"} alt=""/>
                    <img src={"https://i.imgur.com/uIgja20.png"} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default DuckEditor;
import {PreviewRender} from "../../../Duck/DuckEditor/RenderEditor";
import {SlideElement} from "../../../Duck/DuckDesignSystem/animate";
import React, {useEffect} from "react";
import {NewsModel} from "../../../Model/NewsModel";

const Viewer = (props) => {
    const news = new NewsModel(props.news)
    useEffect(()=>{
        document.title = news.Title;
    })
    console.log(news.Thumbnail)
    return (
        <div className=" ">
            <div className=" hero has-background is-flex jt-center align-center is-half   ">
                <img src={news.Thumbnail} alt=""/>
                <div className=" container-xl align-center jt-center ">
                    <div className="text-center is-sticky">
                        <SlideElement>
                            <div className=" hi-4xl text-bold font-text text-semi">     {news.Title}
                            </div>
                            <div className="text-l font-text">   {news.Content}</div>
                        </SlideElement>
                    </div>
                </div>
            </div>
            <div className="container-l is-flex vertical jt-center gapy-m">
                <div className="breadcrumb px-5" aria-label="breadcrumbs">

                    <div className={"breadcrumb-item"}><a href="/">news</a></div>
                    <div className={"breadcrumb-item"}><a href={"/category/".concat(news.CategoryStr)}>{news.CategoryStr}</a></div>
                    <div  className="breadcrumb-item is-active">{news.Title}</div>

                </div>
                <div className="section">
                    <div className="content article-body">
                        <PreviewRender data={news.Data}></PreviewRender>
                        {/*<div className="line-broken" dangerouslySetInnerHTML={{__html: news.Data}}></div>*/}
                    </div>
                </div>
            </div>



        </div>
    );
}
export default Viewer
import {WithRouter} from "../../withRouter";
import {useEffect, useState} from "react";
import {getNews} from "../../../Api/Auth/Services/News/NewsApi";
import {NewsModel} from "../../../Model/NewsModel";
import Viewer from "./Viewer";

function ViewNewsById({uuid}) {
    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (uuid) {
            getNews(uuid).then((data) => {
                setNews(data["News"])
                setLoading(true)
            });
        }
        document.title = item.Title;

    }, [uuid])
    var item = new NewsModel(news)
    return <div className="container">
        {loading ? <Viewer news={item}/> : <div>Loading...</div>}
    </div>

}

export default WithRouter(ViewNewsById)
import React, {useEffect, useState} from 'react';
import Editor from "../../../../Duck/DuckEditor/Editor";
import {useDispatch, useSelector} from "react-redux";
import {getNews, postNews, updateNews} from "../../../../Api/Auth/Services/News/NewsApi";
import {useToast} from "../../../../Duck/DuckReact/Toast/useToast";
import {WithRouter} from "../../../withRouter";
import {initializeData} from "../../../../redux/Editor/editorServices";
import {useParams} from "react-router-dom";
import ElementMetaData from "./ElementMetaData";
import HomeMetadata from "./HomeMetadata";
import TagsInput from "./TagInput";

const testItem = {
    "uuid": " ",
    "title": "Tiêu đề bài viết",
    "content": "Mô tả bài viết",
    "thumbnail": "",
    "categoryStr": "test",
    "customUrl": "",
    "data": "{\"activeId\":\"4d650cbb-3337-40b9-80c1-d1bc746fb4d1\",\"listContext\":[{\"id\":\"d1a07437-b538-4327-9071-da2c7d85de17\",\"defaultState\":{\"header\":\"h-xxl\",\"color\":\"text-mute\",\"textBold\":false,\"textItalic\":false,\"textUnderline\":false,\"textUppercase\":false,\"textAlign\":\"text-left\",\"list\":\"\",\"textIndent\":\"\",\"attachment\":\"\",\"linkEmbed\":\"\",\"image\":false,\"icon\":\"\",\"quote\":\"\"},\"content\":\"Create Your Note\"},{\"id\":\"4d650cbb-3337-40b9-80c1-d1bc746fb4d1\",\"defaultState\":{\"header\":\"p\",\"color\":\"text-black\",\"textBold\":false,\"textItalic\":false,\"textUnderline\":false,\"textUppercase\":false,\"textAlign\":\"text-left\",\"list\":\"\",\"textIndent\":\"\",\"attachment\":\"\",\"linkEmbed\":\"\",\"image\":false,\"icon\":\"\",\"quote\":\"\"},\"content\":\"💖💖💖 Hine\"}]}",
    "isUpdate": false,
}

function NewNews(props) {
    const [metaData, setMetaData] = useState(null);
    const [tags, setTags] = useState(null);
    const {categories} = useParams();
    const [news, setNews] = useState(testItem)
    const [data, setData] = useState("")
    const [updateFlag, setUpdateFlag] = useState(false)
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)
    // const [categories, setCategories] = useState([]);

    const context = useSelector(state => state.editor)
    const [postLoading, setPostLoading] = useState(false)
    const toast = useToast()
    const handlePostNews = () => {
        setNews(prevState => ({
            ...prevState,
            metaData: metaData,
            tags: tags
        }))
        console.log(news)
        setPostLoading(true)
        if (props.news) {
            updateNews(news).then(async function (message) {
                if (message.status === 201) {
                    console.log(message.status)
                    await setLoading(false)
                    await toast.success("Cập nhật thành công!")
                } else {
                    toast.error("Cập nhật thất bại!")
                    setLoading(false)
                }
            }).catch(async err => {
                await setLoading(false)
                toast.error(err.message)
            })
        } else {
            postNews(news).then(async function (message) {
                if (message.status === 201) {
                    await setLoading(false)
                    await toast.success("Tải lên thành công!")
                } else {
                    toast.error("Tải lên thất bại!")
                    setLoading(false)
                }
            }).catch(async err => {
                await setLoading(false)
                toast.error(err.message)
            })
        }

    }
    // Hàm callback để nhận metaData từ component con
    const handleMetadataChange = (updatedMetaData) => {
        setMetaData(updatedMetaData); // Cập nhật state trong component cha
    };
    const onTagsChange = (updatedTags) => {
        setTags(updatedTags); // Cập nhật state trong component cha
    };
    useEffect(() => {
        document.title = props.news ? "Cập nhật bài viết" : "Đăng bài viết mới";
        const handleBeforeUnload = (e) => {
            const confirmationMessage = 'Bạn có chắc chắn muốn rời khỏi trang này?';
            e.returnValue = confirmationMessage; // Standard
            dispatch(initializeData({"News": testItem}));
            return confirmationMessage; // IE/Edge
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            if (props.news) {
                setLoading(true)
                const data = await getNews(props.news.uuid);
                setNews(data.News);
                dispatch(initializeData(data));
                setUpdateFlag(true);
            } else {
                setLoading(true)
                setNews(testItem)
                dispatch(initializeData({"News": testItem}));
            }

        };
        fetchData().then(r => r);
        setLoading(false)
    }, [props.news]);
    useEffect(() => {
        setNews(prevState => ({
            ...prevState,
            data: JSON.stringify(context),
        }))
    }, [context])
    useEffect(() => {
        setNews(prevState => ({
            ...prevState,
            metaData: metaData,
            tags: tags
        }))
    }, [metaData, tags])
    useEffect(() => {
        setNews((prevState) => ({
            ...prevState,
            categoryStr: categories
        }));
    }, [categories]);
    const [cusURL, setCusURL] = useState('');
    const handleChangeURL = (e) => {
        const newValue = e.target.value;
        const regex = /^[a-zA-Z\-]+$/;
        if (regex.test(newValue) || newValue === '') {
            setCusURL(newValue);
            news.customUrl = newValue
        }
    };


    if (loading) return <div>loading</div>
    return (<div className={"is-overflow-y"} style={{height: "calc(100vh - 20px)"}}>

            <div className="columns gap-x bg-white s-vertical rounded-x ">

                <div className="col-2   border-r " style={{height: "calc(100vh - 24px)"}}>
                    <div className="is-flex jt-between border-t  bg-white  is-sticky p-m  border-b"
                         style={{top: 0, zIndex: "999"}}>

                        <div className=" p-xs ">
                            <div className="is-flex align-center ">
                                <h3> Nội Dung</h3>
                            </div>
                        </div>
                    </div>
                    <Editor  width={"100%"}></Editor>
                </div>
                <div className="col    " style={{height: "calc(100vh - 156px)"}}>
                    <div className="is-flex jt-between border-t  bg-white  is-sticky p-m  border-b"
                         style={{top: 0, zIndex: "999"}}>

                        <div className=" p-xs ">
                            <div className="is-flex align-center ">
                                <h3> Thông tin</h3>
                            </div>
                        </div>
                    </div>
                    <form className="bg-white column is-scroll-y is-fill-y   ">

                        <div className="gapy-m p-s">
                            <div className="columns vertical">
                                <div className="col field is-required">
                                    <div className="label">Tiêu đề</div>
                                    <input required type="text" onChange={(e) => {
                                        setNews(prevState => ({
                                            ...prevState,
                                            title: e.target.value
                                        }))
                                    }} placeholder="title" defaultValue={news.title}/>
                                </div>
                                <div className="field col is-required">
                                    <div className="label">Custom Link</div>
                                    <input required type="text"
                                           value={cusURL} onChange={handleChangeURL}
                                           className="file-input" name="resume"
                                           defaultValue={news.customUrl}/>
                                </div>
                                <div className="field col is-required">
                                    <div className="label">Thumbnail</div>
                                    <input required onChange={(e) => setNews(prevState => ({
                                        ...prevState,
                                        thumbnail: e.target.value
                                    }))}
                                           className="file-input" type="url" name="resume"
                                           defaultValue={news.thumbnail}/>
                                </div>
                                <TagsInput onTagsChange={onTagsChange}></TagsInput>
                                <div className="field has-info  is-required">
                                    <div className="label">Mô tả</div>
                                    <textarea required className="is-fill-y" title="input"
                                              onChange={(e) => setNews(prevState => ({
                                                  ...prevState,
                                                  content: e.target.value
                                              }))}
                                              placeholder="Type something..." defaultValue={news.content}></textarea>
                                </div>
                            </div>


                            {categories === "Technology" && <div>{categories}</div>}
                            {categories === "site of the day" &&
                                <ElementMetaData onMetadataChange={handleMetadataChange}/>}
                            {categories === "home of the day" &&
                                <HomeMetadata onMetadataChange={handleMetadataChange}/>}


                            {/*<div className="field">*/}
                            {/*    <AddComponent/>*/}
                            {/*</div>*/}
                        </div>
                    </form>
                    <div className="columns jt-between border-t  bg-white  is-sticky p-m "
                         style={{bottom: 0, zIndex: "999"}}>
                        <div
                             className="btn col btn-fill "> Hủy</div>
                        <div onClick={handlePostNews}
                             className="btn col btn-primary ">{props.news ? "Cập nhật" : "Đăng"}</div>
                    </div>
                </div>
            </div>


        </div>


    );
}

export default WithRouter(NewNews);
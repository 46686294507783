import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useOutsideClick} from "../../slide/checker";

const Modal = ({size, isOpen, children }) => {
    const [disDialog, setDisDialog] = useState(false);
    const [disModal, setDisModal] = useState(false);
    useEffect(() => {
        let timerId;

        if (isOpen) {
            document.body.classList.add('modal-open'); // Thêm lớp khi modal mở
            setDisModal(true);
            timerId = setTimeout(() => {
                setDisDialog(true);
            }, 100);
        } else {
            document.body.classList.remove('modal-open'); // Loại bỏ lớp khi modal đóng
            setDisDialog(false);
            timerId = setTimeout(() => {
                setDisModal(false);
            }, 400);
        }

        return () => clearTimeout(timerId);
    }, [isOpen]);

    return ReactDOM.createPortal(
        <div className="Duck">
            <div className={` modal ${disModal ? "is-open" : ""}`}>
                <div className={`modal-dialog overflow-auto  border  ${disDialog?"open":"close"} ${size === 's' ? "modal-s": size === 'pre'? "modal-preview":"" }`}>
                    {children}
                </div>
            </div>
        </div>,
        document.body
    );
};

export default Modal;
import {base} from "../../../url";
import {v4 as uuidv4} from "uuid";
import {transFormNews} from "../../../../Model/NewsModel";
import axios from "axios";


async function postNews(news){

    const params = JSON.stringify(transFormNews(news));
    console.log(transFormNews(news))
    var requestOptions = {
        method: 'POST',
        data: params,
        url:`${base}/news/`,
        withCredentials: true
    };
    return await axios.request(requestOptions)  .then((response) => response)
        .catch(error => console.log('error', error));
}
async function updateNews(news, thumb, isThumbnails) {
    var formdata = new FormData();
    formdata.append("Uuid", news.uuid);
    formdata.append("Title", news.title);
    formdata.append("CustomUrl",news.customUrl)
    formdata.append("Content", news.content);
    formdata.append("Thumbnail", news.thumbnail);
    formdata.append("Category", news.categoryStr);
    formdata.append("Data", news.data);

    var requestOptions = {
        method: 'PUT',
        body: formdata,
        credentials: "include",
        redirect: 'follow',
    };

    return await fetch(`${base}/news/${news.uuid}`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}

async function getNews(customUrl) {
    var requestOptions = {
        method: 'GET',
        url: `${base}/news/view/${customUrl}/`
    };
    return await axios.request(requestOptions)
        .then((response) => response.data)
        .catch(error => console.log('error', error));
};
async function getSuggestTags(tag) {
    var requestOptions = {
        method: 'GET',
        url: `${base}/tag/suggest?query=${tag}/`
    };
    return await axios.request(requestOptions)
        .then((response) => response.data)
        .catch(error => console.log('error', error));
};
async function deleteNews(uuid) {
    var requestOptions = {
        method: 'DELETE',
        redirectCount:1,
        credentials: "include",
        redirect: 'follow',
    };
    return await fetch(`${base}/news/${uuid}`,requestOptions)
};

export { updateNews, getNews,deleteNews,postNews,getSuggestTags};

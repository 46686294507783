import React, {useEffect, useState} from 'react';

function LoadingScreen({time}) {
    const [timer, setTimer] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    // Define the effect that runs every second and updates the timer
    useEffect(() => {
        // Check if the timer is not zero
        if (timer < 100) {
            // Set a timeout to decrement the timer by one after one second
            const timeout = setTimeout(() => {
                setTimer(timer + 1);
            }, (time-2)/100);
            // Return a cleanup function to clear the timeout
            return () => {
                setIsVisible(false)
                clearTimeout(timeout)
            };
        }
    }, [timer]); // Pass the timer as a dependency to the effect


    return (
        <div className="is-full-y    is-absolute ">
            <div className="container-xl ">
                <div className="columns layout-5-1-1 vertical is-full-y">
                    <div className="col is-flex vertical align-center jt-center">
                        <div className="hi-4xl text-italic text-bold">HINE</div>
                        <div className="text-xs   is-flex  jt-center">
                            Using a large screen device gives you a better experience.
                        </div>
                    </div>
                    <div className="col is-flex  jt-center">
                        <div className="hi-l">{timer}%</div>

                    </div>

                </div>

            </div>

        </div>
    );
}

export default LoadingScreen;
import React, {useEffect, useState} from "react";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {Icon} from "../Duck/DuckDesignSystem/Source/DuckIcon";


const Navbar = () => {
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [navbarOpen, setNavbar] = useState(false)
    const [searchOpen, setSearch] = useState(false)
    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
                setShow(false);
                setNavbar(false)
                // setSearch(false)
            } else { // if scroll up show the navbar
                setShow(true);
            }

            // remember current page location to use in the next move
            setLastScrollY(window.scrollY);
        }
    };
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);

            // cleanup function
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    }, [lastScrollY]);
    const [toggle, setToggle] = useState(false);
    const [menuExpand, setMenuExpand] = useState(false);
    const handleJump = id => {
        setShow(false);
        setNavbar(false)
        const section = document.querySelector(`#${id}`);
        if (section) {
            section.scrollIntoView({behavior: 'smooth'});

        }
    }

    return <div onMouseLeave={() => setToggle(false)} className={`container-full is-flex vertical jt-center `}
                id="bottomMenu">
        <div
            className={`container-m  p-m rounded-l is-relative gapy-m ${navbarOpen ? "bg-blur-dark-2 " : "border-white"}`}>
            <div
                className={`navbar-2  rounded-m p-xs  ${navbarOpen ? "bg-white container-m" : "bg-blur-dark container-xs "} ${!show && !searchOpen ? ' hidden' : ' '}`}>
                <div className="nav-brand px-m">
                    <h4 className="h-m  logo">
                        <Link to={`/`} className="text-bold  text-mute  text-italic">
                            Hi.
                        </Link>
                    </h4>
                </div>
                <div className={`nav-main   jt-end rounded-m`}>
                    <div className={`text-m nav-items  mx-m     ${menuExpand ? "expand" : ""}`}>
                        <div onClick={() => {
                            setNavbar(!navbarOpen)
                            setSearch(false)
                        }}
                             className={`text-semi nav-item ${navbarOpen ? "bg-light " : ""}`}>
                            <Icon icon={navbarOpen ? "close " : "menu"} className={"text-l"}></Icon>
                        </div>
                    </div>

                </div>


            </div>
            <div className={`bg-white rounded-m ${navbarOpen ? "open " : ""}`} id={"navbar-open"}>
                <div className=" rounded-m p-xl columns l-vertical gap-m ">

                    <div className="col gapy-s">
                        <p className={"title-s"}>Home</p>
                        <div className="columns vertical gap-m">
                            <a href={"/"} onClick={() => handleJump("triggerElement")}
                               className="py-s border-b ">About</a>
                            <a href={"/"} onClick={() => handleJump("workflow")} className="py-s border-b ">WorkFlow</a>
                            <a href={"/"} onClick={() => handleJump("Experience")}
                               className="py-s border-b ">Experience</a>
                            <a href={"/"} onClick={() => handleJump("triggerElement")} className="py-s border-b ">Personal
                                Projects</a>
                            <a href={"/"} onClick={() => handleJump("triggerElement")} className="py-s  ">Elements</a>
                        </div>
                    </div>

                    <div className="col is-flex vertical gapy-s">
                        <p className={"title-s"}>Contact</p>
                        <Link to={"/cv"} target={"_blank"} onClick={() => {
                            setShow(false);
                            setNavbar(false)
                        }} className={"col bg-light p-m  align-center is-flex jt-between  rounded-m"}>
                            <h4 className="">Download CV</h4>
                            <Icon icon={"open-link "} className={"text-l"}/>
                        </Link>
                        <Link to={"/contact"} target={"_blank"} onClick={() => {
                            setShow(false);
                            setNavbar(false)
                        }} className={"col p-m bg-light align-center is-flex jt-between rounded-m"}>
                            <h4 className="">Contact</h4>
                            <Icon icon={"open-link "} className={"text-l"}/>
                        </Link>

                        <div className="col is-flex jt-start px-m gapx-s">
                            <Link to="https://www.facebook.com/hotuhi12/">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1572_31)">
                                        <rect width="28" height="28" rx="5" fill="#0A0A0A"/>
                                        <path
                                            d="M19.2137 19.2816L19.8356 15.3301H15.9452V12.767C15.9452 11.6857 16.4877 10.6311 18.2302 10.6311H20V7.26699C20 7.26699 18.3945 7 16.8603 7C13.6548 7 11.5617 8.89294 11.5617 12.3184V15.3301H8V19.2816H11.5617V28.8345C12.2767 28.944 13.0082 29 13.7534 29C14.4986 29 15.2302 28.944 15.9452 28.8345V19.2816H19.2137Z"
                                            fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1572_31">
                                            <rect width="28" height="28" rx="4" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Link>
                            <Link to="https://www.linkedin.com/in/hinestudio" target="_blank">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="28" height="28" rx="6" fill="#0A0A0A"/>
                                    <path d="M10.6186 8.69215C10.6186 9.6267 9.80852 10.3843 8.80928 10.3843C7.81004 10.3843 7 9.6267 7 8.69215C7 7.7576 7.81004 7 8.80928 7C9.80852 7 10.6186 7.7576 10.6186 8.69215Z" fill="white"/>
                                    <path d="M7.24742 11.6281H10.3402V21H7.24742V11.6281Z" fill="white"/>
                                    <path d="M15.3196 11.6281H12.2268V21H15.3196C15.3196 21 15.3196 18.0496 15.3196 16.2049C15.3196 15.0976 15.6977 13.9855 17.2062 13.9855C18.911 13.9855 18.9008 15.4345 18.8928 16.5571C18.8824 18.0244 18.9072 19.5219 18.9072 21H22V16.0537C21.9738 12.8954 21.1508 11.4401 18.4433 11.4401C16.8354 11.4401 15.8387 12.1701 15.3196 12.8305V11.6281Z" fill="white"/>
                                </svg>

                            </Link>
                            <Link to="https://www.behance.net/hotuhi" target="_blank">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect width="28" height="28" rx="6" fill="#0A0A0A"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M5 20.216V8H10.6473C12.4612 8 13.9316 9.47047 13.9316 11.2844C13.9316 12.437 13.5772 13.151 12.2776 13.8835C13.8328 14.5921 14.2647 15.5742 14.2647 16.944C14.2647 18.7971 12.6185 20.216 10.7654 20.216H5ZM7.38968 10.0222V12.9597H10.1866C10.1866 12.9597 11.5459 12.9597 11.5459 11.4909C11.5459 10.0222 10.1866 10.0222 10.1866 10.0222H7.38968ZM7.38968 18.1472V14.9753H10.3897C10.8741 14.9753 11.8897 15.2253 11.8897 16.7253C11.8897 17.8378 10.8897 18.1367 10.3897 18.1472H7.38968Z"
                                          fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M19.3741 11.3659C17.6553 11.3659 15.1084 12.6003 15.1084 15.8815C15.1084 17.8795 16.2022 20.444 19.4834 20.444C22.1084 20.444 23.3168 18.5169 23.5928 17.5534H21.1241C20.9991 17.9909 20.5147 18.4753 19.4834 18.4753C17.9834 18.4753 17.4522 17.194 17.3741 16.5534H23.5928V15.8815C23.5928 12.6003 21.0928 11.3659 19.3741 11.3659ZM19.3741 13.2409C17.9741 13.2409 17.4574 14.3972 17.3741 14.9753H21.1241C21.1241 14.3972 20.7741 13.2409 19.3741 13.2409Z"
                                          fill="white"/>
                                    <path d="M16.3897 8.69403V10.2097H22.2803V8.69403H16.3897Z" fill="white"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="col is-flex vertical gapy-s ">
                        <p className={"title-s"}>Work</p>
                        <Link onClick={() => {
                            setShow(false);
                            setNavbar(false)
                        }} to={"/work"} className="border   is-flex vertical jt-end gap-m rounded-m  is-grow">
                            <div className=" is-flex  jt-center align-end">
                                <Icon icon={"license"} className={"hi-3xl hi-text-light"}></Icon>
                            </div>
                            <div className="gapy-xs is-flex vertical  is-grow">

                                <p className={"text-xs is-grow"}>Explore meticulously designed and developed
                                    projects.</p>
                                <h4 className={" is-flex gapx-l "}>See all work <Icon
                                    icon={"arrow-right"} className={"r-xxl"}></Icon></h4>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>


    </div>

}

// const Navbar = () => {
//     const [show, setShow] = useState(true);
//     const [lastScrollY, setLastScrollY] = useState(0);
//     const controlNavbar = () => {
//         if (typeof window !== 'undefined') {
//             if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
//                 setShow(false);
//             } else { // if scroll up show the navbar
//                 setShow(true);
//             }
//
//             // remember current page location to use in the next move
//             setLastScrollY(window.scrollY);
//         }
//     };
//     useEffect(() => {
//         if (typeof window !== 'undefined') {
//             window.addEventListener('scroll', controlNavbar);
//
//             // cleanup function
//             return () => {
//                 window.removeEventListener('scroll', controlNavbar);
//             };
//         }
//     }, [lastScrollY]);
//     const [toggle, setToggle] = useState(false);
//     const [navBar, setNavBar] = useState(false);
//     const [menuExpand, setMenuExpand] = useState(false);
//     const { pathname } = useLocation();
//
//     // Danh sách các đường dẫn không muốn hiển thị Footer
//     // Kiểm tra xem `pathname` có trong danh sách không
//
//
//     return <div onMouseLeave={() => setToggle(false)} className={`container-full mt-l is-flex  jt-center `}
//                 id="bottomMenu">
//
//         <div className={`navbar-2  container-xl bg-blur rounded-m p-xs  ${!show && 'hidden'}`}>
//             <div className="nav-brand">
//                 <h4 className="h-m p-s logo">
//                     <Link to={`/`} className="hi-text-bold  text-italic">
//                         Hi.
//                     </Link>
//                 </h4>
//             </div>
//             <div className="nav-main jt-end">
//                 <div className={`text-m nav-items  ${menuExpand ? "expand" : ""}`}>
//                     {/*<a href="uiux" className=" hi-text-semi nav-item ">Wor</a>*/}
//                     <NavLink to={`/work`} onClick={() => setMenuExpand(false)} className={({isActive, isPending}) =>
//                         isPending ? "pending" : isActive ? "text-semi nav-item is-active" : "text-semi nav-item"
//                     }>Project</NavLink>
//                     <NavLink to={`/elements`} onClick={() => setMenuExpand(false)} className={({isActive, isPending}) =>
//                         isPending ? "pending" : isActive ? "text-semi nav-item is-active" : "text-semi nav-item"
//                     }>About</NavLink>
//                     <NavLink to={"/Contact"} onClick={() => setMenuExpand(false)}  className={({isActive, isPending}) =>
//                         isPending ? "pending" : isActive ? "text-semi nav-item is-active" : "text-semi nav-item"
//                     }>Contact </NavLink>
//                     {/*<a href="Other" className=" hi-text-semi nav-item ">OTHER </a>*/}
//
//                     {/*<div className="nav-item btn btn-outline">Contact</div>*/}
//                 </div>
//             </div>
//             <div className="nav-mobile align-end">
//
//                 <div onClick={() => setMenuExpand(!menuExpand)} className="nav-item-collapse text-xl mr-m">
//                     {menuExpand ? <div className=" icon icon-close"></div> :
//                         <div className=" icon icon-menu"></div>}
//
//                 </div>
//             </div>
//
//
//         </div>
//
//
//     </div>
//
// }

export default Navbar
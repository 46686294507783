import React from 'react';
import {Layout42Main} from "../../Support/Layout";
import {Icon} from "../../DuckIcon"

function IconSection(props) {
    const icon = {
        "listIcon": [
            {
                "icon": "add "
            },
            {
                "icon": "al-sign "
            },
            {
                "icon": "attachments "
            },
            {
                "icon": "background "
            },
            {
                "icon": "circle-1-4 "
            },
            {
                "icon": "circle-2-4 "
            },
            {
                "icon": "circle-3-4 "
            },
            {
                "icon": "circle "
            },
            {
                "icon": "clipboard-fail "
            },
            {
                "icon": "clipboard-success "
            },
            {
                "icon": "component "
            },
            {
                "icon": "copy-success "
            },
            {
                "icon": "copy "
            },
            {
                "icon": "divide "
            },
            {
                "icon": "eye-droper-1 "
            },
            {
                "icon": "eye-droper "
            },
            {
                "icon": "fill-horizontal "
            },
            {
                "icon": "fill-vertical "
            },
            {
                "icon": "home "
            },
            {
                "icon": "hug-horizontal "
            },
            {
                "icon": "hug-vertical "
            },
            {
                "icon": "link "
            },
            {
                "icon": "mirror "
            },
            {
                "icon": "multiply "
            },
            {
                "icon": "notification-1 "
            },
            {
                "icon": "notification-new "
            },
            {
                "icon": "notification "
            },
            {
                "icon": "pen "
            },
            {
                "icon": "scale "
            },
            {
                "icon": "setting-2 "
            },
            {
                "icon": "setting "
            },
            {
                "icon": "split "
            },
            {
                "icon": "subtract "
            },
            {
                "icon": "trash-can-blank "
            },
            {
                "icon": "trash-can "
            },
            {
                "icon": "wand "
            },
            {
                "icon": "zoom-in "
            },
            {
                "icon": "zoom-out "
            },
            {
                "icon": "zoom "
            }
        ]
    }
    return (
        <div>
            <Layout42Main>
                <div className="p-xxl is-center  bg-light rounded-m">
                    <div className="is-flex wrap gap-s ">
                        {icon.listIcon.map((item, index) => {
                            return <Icon className={` text-xl`} icon={item.icon}></Icon>
                        })}
                    </div>
                </div>
                <>
                    <div className="mb-xl">
                        <div className=" text-m">
                           Duck System sử dụng <a className={"text-primary"} href="https://duck.hine.studio/duck-icon" target="_blank">Duck Icon</a> làm bộ biểu tượng
                        </div>
                    </div>


                </>

            </Layout42Main>
        </div>
    );
}

export default IconSection;
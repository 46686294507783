import React from "react";
import { renderToString } from 'react-dom/server';
const FormatHTMLString = (inputString) => {
    const formattedString = jsxElementToString(inputString, 0);
    return formattedString;
};

const jsxElementToString = (element, depth) => {
    const indentation = ' '.repeat(depth * 2);

    if (React.isValidElement(element)) {
        const tagName = element.type;
        const { children, ...otherProps } = element.props;

        // Build props string
        const propsString = Object.keys(otherProps).map(key => {
            const propValue = typeof otherProps[key] === 'function' ? `{${otherProps[key].toString()}}` : `"${otherProps[key]}"`;
            return `${key}=${propValue}`;
        }).join(' ');

        const childrenArray = React.Children.toArray(children);
        const childrenString = childrenArray.map(child =>
            jsxElementToString(child, depth + 1)
        ).join('\n');

        // Add props to the opening tag
        return `${indentation}<${tagName} ${propsString}>\n${childrenString}\n${indentation}</${tagName}>`;
    } else {
        return `${indentation}${element}`;
    }
};
export default FormatHTMLString
import * as THREE from 'three'
import React, {useRef} from 'react'
import {useFrame} from '@react-three/fiber'
import {easing} from 'maath'
import {
	useGLTF
} from '@react-three/drei'
import modelPath from './Model/skull.glb'
export function Model(props) {
	const {nodes, materials} = useGLTF(modelPath)
	const ref = useRef(null)
	useFrame((state, delta) => {
		const animate=()=>{
			ref.current.rotation.set(0, -Math.PI / 2, 0)
			const x = (-Math.sin(state.pointer.x / 4) * 9)
			const y = -(0.25 + 1.25 * state.pointer.y)
			const z = (Math.cos(state.pointer.x / 4) * 9 - 6)
			if (!props.perfSucks) {
				easing.damp3(ref.current.position, [0, 0, 0], 1, 0.04)
				easing.damp3(ref.current.rotation, [(Math.PI / 2), 0, (1 / 5 + state.pointer.x)], 1, 0.03)
				easing.damp3(state.camera.position, [x, y, z], 1, 0.03)
				state.camera.lookAt(ref.current.position.x, ref.current.position.y, ref.current.position.z)

			} else {
				easing.damp3(ref.current.rotation, [0, 0, 0], 3.9, 0.04)
				easing.damp3(ref.current.position, [0, -2,-1], 0.9, 0.04)
				easing.damp3(state.camera.position, [0, -1.5, 3], 1.9, 0.04)
				state.camera.lookAt(ref.current.position.x, ref.current.position.y, ref.current.position.z-1)
			}
		}
		
		requestAnimationFrame(animate)

	})
	return (
		<group ref={ref} {...props} dispose={null} scale={1}>
			<mesh geometry={nodes.BezierCurve002.geometry} material={materials['ong dan']}/>
			<mesh geometry={nodes.Cylinder002.geometry} material={materials['black plastic']}/>
			<mesh geometry={nodes.Cube003.geometry} material={materials.giap}/>
			<mesh geometry={nodes.Cube004.geometry} material={materials['xuyen.001']}/>
			<mesh geometry={nodes.Cube005.geometry} material={materials.giap}/>
			<mesh geometry={nodes.Cube006.geometry} material={materials.giap}/>
			<mesh geometry={nodes.Cylinder001.geometry} material={materials['black plastic']}/>
			<mesh geometry={nodes.Cylinder003.geometry} material={materials['black plastic']}/>
			<mesh geometry={nodes.Cube007.geometry} material={materials.giap}/>
			<mesh geometry={nodes.BezierCurve001.geometry} material={materials['ong dan']}/>
			<mesh geometry={nodes.BezierCurve003.geometry} material={materials['ong dan']}/>
			<mesh geometry={nodes.Cylinder.geometry} material={materials['black plastic']}/>
			<mesh geometry={nodes.Cylinder006.geometry} material={materials.metal}/>
			<mesh geometry={nodes.Cylinder004.geometry} material={materials.metal}/>
			<mesh geometry={nodes.Cylinder005.geometry} material={materials.metal}/>
			<mesh geometry={nodes.Cylinder007.geometry} material={materials.xuyen}/>
			<mesh geometry={nodes.Cube.geometry} material={materials.giap}/>
			<mesh geometry={nodes.BezierCurve004.geometry} material={materials['ong mat']}/>
			<mesh geometry={nodes.BezierCurve005.geometry} material={materials['ong mat']}/>
			<mesh geometry={nodes.BezierCurve006.geometry} material={materials['ong mat']}/>
			<mesh geometry={nodes.Sphere002.geometry} material={materials['Material.001']}/>
			<mesh geometry={nodes.Cube003_1.geometry} material={materials['su thanh hoa']}/>
			<mesh geometry={nodes.Cube003_2.geometry} material={materials['giap.001']}/>
			<mesh geometry={nodes.Cube003_3.geometry} material={materials['Material.002']}/>
		</group>
	)
}

useGLTF.preload('/skull.glb')


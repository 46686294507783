import React from 'react';

function DesignPack(props) {
    return (
        <>
            <svg width="514" height="237" viewBox="0 0 514 237" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <g clip-path="url(#clip0_14881_3006)">
                    <path stroke="#F2F2F2" stroke-width="2.55095" d="M301.721,-9.68018L301.721,237.19" className="IhpBYHaz_1"></path>
                    <path stroke="#F2F2F2" stroke-width="2.55095" d="M263.801,-9.68018L263.801,237.19" className="IhpBYHaz_2"></path>
                    <path stroke="#F2F2F2" stroke-width="2.55095" d="M-55.0767,49.4501L626.223,49.4501" className="IhpBYHaz_3"></path>
                    <path stroke="#F2F2F2" stroke-width="2.55095" d="M-55.0767,115.286L626.223,115.286" className="IhpBYHaz_4"></path>
                    <path stroke="#F2F2F2" stroke-width="2.55095" d="M-55.0767,182.725L626.223,182.725" className="IhpBYHaz_5"></path>
                    <path opacity="0.4" stroke="url(#paint0_linear_14881_3006)" stroke-width="2.55095" stroke-linecap="round" d="M263.92870000000005,68.1459A18.5653,18.5653 0,1,1 301.0593,68.1459A18.5653,18.5653 0,1,1 263.92870000000005,68.1459" className="IhpBYHaz_6"></path>
                    <path opacity="0.4" transform="rotate(-90 275 156)" stroke="url(#paint1_linear_14881_3006)" stroke-width="2.55095" stroke-linecap="round" d="M248.2755,156A26.7245,26.7245 0,1,1 301.7245,156A26.7245,26.7245 0,1,1 248.2755,156" className="IhpBYHaz_7"></path>
                    <path opacity="0.4" stroke="url(#paint2_linear_14881_3006)" stroke-width="2.55095" stroke-linecap="round" d="M198.4712,159.448A23.1908,23.1908 0,1,1 244.8528,159.448A23.1908,23.1908 0,1,1 198.4712,159.448" className="IhpBYHaz_8"></path>
                    <path opacity="0.4" transform="rotate(90 317.429 81.1522)" stroke="url(#paint3_linear_14881_3006)" stroke-width="2.55095" stroke-linecap="round" d="M314.73632999999995,81.1522A2.69267,2.69267 0,1,1 320.12167,81.1522A2.69267,2.69267 0,1,1 314.73632999999995,81.1522" className="IhpBYHaz_9"></path>
                    <path opacity="0.4" stroke="url(#paint4_linear_14881_3006)" stroke-width="2.55095" stroke-linecap="round" d="M167.2759,114.607A43.9331,43.9331 0,1,1 255.1421,114.607A43.9331,43.9331 0,1,1 167.2759,114.607" className="IhpBYHaz_10"></path>
                    <path d="M318.397 78.4335L301.248 70.8477V95.9791L313.605 89.7595C314.292 89.4138 314.341 88.45 313.699 88.0267C313.061 87.6055 313.105 86.6478 313.785 86.2979L318.556 83.845C320.798 82.6917 320.704 79.4537 318.397 78.4335Z" stroke="#0A0A0A" stroke-width="2.55095" className="IhpBYHaz_11"></path>
                    <path d="M301.324 115.198H266.453H255.5V115.198C255.5 139.113 236.113 158.5 212.198 158.5H199V160.696C199 172.741 208.747 182.509 220.792 182.517C236.031 182.528 256.945 182.54 275.765 182.54" stroke="#0A0A0A" stroke-width="2.55095" className="IhpBYHaz_12"></path>
                    <path d="M300.956 68.042L300.956 68.0531L301.725 157C301.725 157.002 301.725 157.003 301.725 157.005C301.722 171.062 289.567 182.54 275.416 182.54C269.001 182.54 263.801 177.339 263.801 170.925V68.042C263.801 57.7822 272.119 49.4649 282.378 49.4649C292.638 49.4649 300.956 57.7822 300.956 68.042Z" stroke="#0A0A0A" stroke-width="2.55095" className="IhpBYHaz_13"></path>
                    <mask id="path-14-inside-1_14881_3006" fill="white">
                        <path d="M257 114C257 126.2 252.154 137.9 243.527 146.527C234.9 155.154 223.2 160 211 160C198.8 160 187.1 155.154 178.473 146.527C169.846 137.9 165 126.2 165 114L211 114H257Z" className="IhpBYHaz_14"></path>
                    </mask>
                    <path d="M257 114C257 126.2 252.154 137.9 243.527 146.527C234.9 155.154 223.2 160 211 160C198.8 160 187.1 155.154 178.473 146.527C169.846 137.9 165 126.2 165 114L211 114H257Z" stroke="#0A0A0A" stroke-width="5.10191" mask="url(#path-14-inside-1_14881_3006)" className="IhpBYHaz_15"></path>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_14881_3006" x1="302.335" y1="45.0753" x2="275.757" y2="100.353" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#737373"></stop>
                        <stop offset="1" stop-color="#737373" stop-opacity="0"></stop>
                    </linearGradient>
                    <linearGradient id="paint1_linear_14881_3006" x1="243.534" y1="212.183" x2="292.968" y2="128" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#737373"></stop>
                        <stop offset="1" stop-color="#737373" stop-opacity="0"></stop>
                    </linearGradient>
                    <linearGradient id="paint2_linear_14881_3006" x1="194.168" y1="208.54" x2="237.362" y2="134.981" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#737373"></stop>
                        <stop offset="1" stop-color="#737373" stop-opacity="0"></stop>
                    </linearGradient>
                    <linearGradient id="paint3_linear_14881_3006" x1="319.951" y1="86.0344" x2="316.515" y2="76.567" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#737373"></stop>
                        <stop offset="1" stop-color="#737373" stop-opacity="0"></stop>
                    </linearGradient>
                    <linearGradient id="paint4_linear_14881_3006" x1="239.936" y1="170.228" x2="200.796" y2="62.3675" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#737373"></stop>
                        <stop offset="1" stop-color="#737373" stop-opacity="0"></stop>
                    </linearGradient>
                    <clipPath id="clip0_14881_3006">
                        <path width="514" height="237" fill="white" d="M0 0 L514 0 L514 237 L0 237 Z" className="IhpBYHaz_16"></path>
                    </clipPath>
                </defs>
            </svg>

        </>

    );
}

export default DesignPack;
import {Layout42Main} from "../../Support/Layout";

const TextSection = () => {
	const Text = [
		{name: "text-xs", value: "12px" , description: "Extra Extra Large"},
		{name: "text-s", value: "14px", description: "Extra Large"},
		{name: "text-m", value: "16px", description: "Large"},
		{name: "text-l", value: "20px", description: "Medium"},
		{name: "text-xl", value: "24px", description: "Small"},
		{name: "text-xxl", value: "32px", description: "Tiny"}
	]
	const title = [
		{name: "title-xs", value: "12px"},
		{name: "title-s", value: "14px"},
		{name: "title-m", value: "16px"},
		{name: "title-l", value: "20px"},
		{name: "title-xl", value: "24px"},
		{name: "title-xxl", value: "32px"}
	]
	const Header = [
		{name: "h1", value: "48px", description: "Extra Extra Large"},
		{name: "h2", value: "40px", description: "Extra Large"},
		{name: "h3", value: "32px", description: "Large"},
		{name: "h4", value: "24px", description: "Medium"},
		{name: "h5", value: "20px", description: "Small"},
		{name: "h6", value: "16px", description: "Tiny"}
	]
	return <div className="mb-xxl">
		<div className="section" id="gridSection">
			<div className="section">
				<h4 className="h-m">Introduction</h4>
				<div className="text-l">A simple way to build <strong>responsive columns </strong></div>
			</div>
			<div className="section">
				<Layout42Main>
					<div className="text-m">
						The way we place type on a baseline grid in Figma (or any other design tool) is mainly for
						increased efficiency. This is done the same way developers position typography, this way you can
						be sure the build version of your design is just like you intended it to be.
						<br/>
						We place everything, including icons and images, on a 4px grid. If you zoom in you will notice
						the text is not actually on the baseline grid, but the bounding box of elements (such as an icon
						or some text) is placed on the 4px grid system.
						<br/>
						No worries though, the main thing we’re trying to achieve here is rhythm, which is achieved by
						this method as well.
					</div>
					<div className="is-block bg-example rounded-m">
						<div className="p-xxl">
							<h1 className="border rounded-m h-xxl ">
								A Heading one on multiple lines
							</h1>
							<div className="size-xxl">
								<div className="text-disable">
									48px
								</div>
							</div>
							<h4 className="border rounded-m h-m ">
								Repeating T2’s seems a bit much here
							</h4>
							<div className="size-m">
								<div className="text-disable">
									16px
								</div>
							</div>
							<div className="border rounded-m text-m mb-l">
								Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
								laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
								architecto beatae vitae dicta sunt explicabo.
							</div>
							<div className="size-l">
								<div className="text-disable">
									32px
								</div>
							</div>
							<h4 className="border rounded-m h-m">
								Repeating T2’s seems a bit much here
							</h4>
							<div className="size-m">
								<div className="text-disable">
									16px
								</div>
							</div>
							<div className="border rounded-m text-m mb-l">
								Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
								laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
								architecto beatae vitae dicta sunt explicabo.
							</div>
						</div>

					</div>

				</Layout42Main>
				<div className="columns l-vertical s-vertical-revert gap-l">


				</div>
			</div>
			<div className="columns vertical gap-l mt-l">
				<div className="section">
					<h3 className="h-l   ">Header</h3>
					 
					<div className="columns">
						{Header.map((item, index) => (
							<div key={index} className="col col-s-6 px-m border-l columns vertical  ">
								<div className={` size-y-m-x2 ${item.name}`}>{item.value}</div>
								<div className="is-block text-primary">
									<div className="code">{item.name}</div>
									<div className="text-disable">
										{item.description}
									</div>
								</div>
							</div>
						))}
					</div>

				</div>

				<div className=" section ">
					<h3 className="h-l   ">Text</h3>
					<div className="columns ">
						{Text.reverse().map((item, index) => (
							<div key={index} className="col col-s-6 px-m border-l columns vertical  ">
								<div className={` size-y-m-x2 ${item.name}`}>{item.value}</div>
								<div className="is-block text-primary">
									<div className="code">{item.name}</div>
									<div className="text-disable">
										{item.description}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>

	</div>
}
export default TextSection
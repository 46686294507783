import {useEffect,useRef, useMemo, useState} from "react";

const useOutsideClick = (callback) => {
	const ref = useRef();

	useEffect(() => {
		const handleClick = (event) => {
			callback();
		};

		document.addEventListener('click', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, []);

	return ref;
};

function useIsTopOfViewport(ref) {
	const [isIntersecting, setIsIntersecting] = useState(false);

	const observer = useMemo(
		() =>
			new IntersectionObserver(([entry]) =>
				setIsIntersecting(entry.isIntersecting),
			),
		[],
	);

	useEffect(() => {
		observer.observe(ref.current);

		return () => {
			observer.disconnect();
		};
	}, [ref, observer]);

	return isIntersecting;
}

function useIsInViewport(ref) {

	const [isOnScreen, setIsOnScreen] = useState(false);
	const observerRef = useRef(null);

	useEffect(() => {
		observerRef.current = new IntersectionObserver(([entry]) =>
			setIsOnScreen(entry.isIntersecting)
		);
	}, []);

	useEffect(() => {
		observerRef.current.observe(ref.current);

		return () => {
			observerRef.current.disconnect();
		};
	}, [ref]);
	return isOnScreen;
}

function useIsFromBottomViewport(ref) {
	const [isIntersecting, setIsIntersecting] = useState(false);
	const observer = useMemo(
		() =>
			new IntersectionObserver(function ([entry]) {
					const bcr = entry.boundingClientRect;
					const isBottomVisible = (bcr.bottom < window.innerHeight) && bcr.bottom;
					setIsIntersecting(() => entry.isIntersecting && !isBottomVisible)
				}
			),
		[],
	);

	useEffect(() => {
		if(ref.current){
			observer.observe(ref.current);
			return () => {
				observer.disconnect();
			};
		}

	}, [ref, observer]);
	return isIntersecting;
}


export {useIsFromBottomViewport, useIsInViewport, useIsTopOfViewport,useOutsideClick};
